import React from 'react'
import { Heading, Box, Flex, CircularProgress, Text } from '@chakra-ui/react'

function ApplicationStage({isloading, lrounds, applicants, buildCard}) {
  return (
    <Box as="section" className="card" py="5" px="5">
            <Flex
              justifyContent="space-between"
              align="center"
              color="#000"
              mb={5}
              borderRadius={10}
            >
              <Heading as="h2" fontSize={30} textAlign="center">
                {lrounds}
              </Heading>
            </Flex>
            {isloading ? (
              <Flex
                width={"100%"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CircularProgress isIndeterminate color="#00A3C4" size={100} />
              </Flex>
            ) : (
              <Flex flexWrap="wrap" gap={10}>
                {applicants?.length !== 0 ? (
                  buildCard(applicants)
                ) : (
                  <Text fontSize={"25px"}>No Applicant Yet...</Text>
                )}
              </Flex>
            )}
          </Box>
  )
}

export default ApplicationStage