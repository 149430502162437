import { Flex, Text, Heading, Divider, Grid, Box } from '@chakra-ui/react'
import React from 'react'
import { UserAuth } from '../../context/AuthContext'
import { plan, companySize,  } from '../../data/Constant'
import { industries } from '../../data/Industry'


function CompanyProfile() {
    const {companyInfo} = UserAuth()
    const localPlan = companyInfo?.plan !== null ? companyInfo?.plan : "Loading..."
    
  return (
    
    <Flex className='card' flexDir={'column'} w={'70vw'} minH={'400px'} mx={'auto'} my={10} py={5}>
            <Heading as={'h3'} fontSize={{sm:'20px', md:'28px'}} ml={5} mb={3}>Company Details</Heading>
            <Divider border={'1px solid #00000055'} />
            <Flex flexDir={'column'} my={10} px={20} >
            <Flex justifyContent={'center'} gap={20} flexWrap={'wrap'}>
                <Flex gap={{base:1,md:5}} flexDir={{base:"column", md: "row"}} alignItems={"center"}>
                    <Text>Company</Text>
                    <Box>
                        <Text>{companyInfo?.name !== null ? companyInfo?.name : ""}</Text>
                    </Box>
                </Flex>
                <Divider orientation='vertical' height={"20px"} borderColor={"#000"} opacity={1}/>
                <Flex gap={{base:1,md:5}} flexDir={{base:"column", md: "row"}} alignItems={"center"}>
                    <Text>Domain</Text>
                    <Box>
                        <Text>{companyInfo?.domain !== null ? companyInfo?.domain : ""}</Text>
                    </Box>
                </Flex>
                <Divider orientation='vertical' height={"20px"} borderColor={"#000"} opacity={1}/>

                <Flex gap={{base:1,md:5}} flexDir={{base:"column", md: "row"}} alignItems={"center"}>
                    <Text>Industry</Text>
                    <Box>
                        <Text>{companyInfo?.industry !== null ? industries[companyInfo?.industry-1]?.industry : ""}</Text>
                    </Box>
                </Flex>
                <Divider orientation='vertical' height={"20px"} borderColor={"#000"} opacity={1}/>
            </Flex>
            <Flex>

            </Flex>
            <Divider my={10} border={'1px solid #00000055'} />
            <Flex flexDir={'column'} gap={5} flex={'wrap'}>
                <Grid gap={'5'} templateColumns={{base: "1fr", md:'.5fr 1fr'}}  alignItems={'center'}>
                    <Text> Contact email</Text>
                    <Box>
                        <Text wordBreak={{base:'break-all', md: 'unset'}}>{companyInfo?.email !== null ? companyInfo?.email : ""}</Text>
                    </Box>
                </Grid>
                <Divider orientation='horizontal' width={"20px"} borderColor={"#000"} opacity={1} m={"auto"}/>
                <Grid templateColumns='.5fr 1fr' gap={'5'} alignItems={'center'}>
                    <Text>Plan</Text>
                    <Box>
                        <Text>{plan[localPlan]}</Text>
                    </Box>
                </Grid>
                <Divider orientation='horizontal' width={"20px"} borderColor={"#000"} opacity={1} m={"auto"}/>
                <Grid templateColumns='.5fr 1fr' gap={'5'} alignItems={'center'}>
                    <Text>Size</Text>
                    <Box>
                        <Text>{companyInfo?.size !==null ?companySize[companyInfo?.size] : 0}</Text>
                    </Box>
                </Grid>
            </Flex>
            </Flex>
        </Flex>
  )
}

export default CompanyProfile