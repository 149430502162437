import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Divider,
  CircularProgress,
} from "@chakra-ui/react";
import { UserAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";
import Metrics from "./Metrics";

function Dashboard() {
  const { userInfo, companyInfo, apiURL } = UserAuth();
  const [jobList, setJobList] = useState([]);
  const [compSize, setCompSize] = useState(0);
  const [aPost, setAPost] = useState(0);
  const [openPosition, setOpenPosition] = useState(0);
  const [hired, sethired] = useState(0);
  const [myAPost, setMyAPost] = useState(0);
  const [myOpenPosition, setMyOpenPosition] = useState(0);
  const [myHired, setMyhired] = useState(0);
  const [pieData, setPieData] = useState([]);
  const [pieNoData, setPieNoData] = useState(
    <CircularProgress isIndeterminate size={"60px"} />
  );
  const [statsData, setStatsData] = useState([]);
  const [statsNoData, setStatsNoData] = useState(
    <CircularProgress isIndeterminate size={"60px"} />
  );
  const [myPieData, setMyPieData] = useState([]);
  const [myStatsData, setMyStatsData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [activeTab, setActiveTab] = useState(0);
  const highRole = ["aS39y22vD3", "eXjCFqipRq", "qsq2ZTXvqB"];
  let myDash;
  if (highRole.includes(userInfo?.role)) {
    myDash = true;
  } else {
    myDash = false;
  }
  useEffect(() => {
    try {
      axios.get(`${apiURL}/jobs?company=${userInfo?.company}`, {withCredentials:true}).then((res) => {
        setJobList((prev) => [...prev, ...res.data]);
        const list = res.data;
        const filterActive = list.filter((job) => job.active === 1);
        const filterMyList = list.filter(
          (job) => job.recruiter === userInfo.id || job.hManager === userInfo.id
        );
        const filterMyActive = filterActive.filter(
          (job) => job.recruiter === userInfo.id || job.hManager === userInfo.id
        );
        const start = 0;
        const filterOpenPos = filterActive.reduce(
          (a, b) => a + b.hTarget,
          start
        );
        const filterMyOpenPos = filterMyActive.reduce(
          (a, b) => a + b.hTarget,
          start
        );
        const filterHired = list.reduce((a, b) => a + b.hired, start);
        const filterMyHired = filterMyList.reduce(
          (a, b) => a + b.hired,
          start
        );

        setCompSize(companyInfo?.size);
        setAPost(() => filterActive.length);
        setOpenPosition(filterOpenPos);
        sethired(filterHired);
        setMyAPost(() => filterMyActive.length);
        setMyOpenPosition(filterMyOpenPos);
        setMyhired(filterMyHired);
      });

      axios.get(`${apiURL}/companies/${userInfo.company}`, {withCredentials:true}).then((res) => {
        axios
          .get(`${apiURL}/activeJobs?company=${userInfo?.company}`, {withCredentials:true})
          .then((res) => {
            const jobs = res.data.slice(-5);
            const mappingCompData = jobs.map((jobs) => ({
              data: jobs.title,
              applicants: jobs.aReceived,
              interviewing: jobs.interviewing,
              rejected: jobs.rejected,
              hired: jobs.hired,
            }));
            setStatsData((prev) => [...prev, ...mappingCompData]);
            const filteredData = jobs.filter(
              (item) => item.recruiter === userInfo.id
            );
            const mappingMyData = filteredData.map((jobs) => ({
              data: jobs.title,
              applicants: jobs.aReceived,
              interviewing: jobs.interviewing,
              rejected: jobs.rejected,
              hired: jobs.hired,
            }));
            setMyStatsData((prev) => [...prev, ...mappingMyData]);

            setPieData(
              res.data.map((jobs) => ({
                type: jobs.title,
                count: jobs.rBudget,
              }))
            );
            setMyPieData(
              filteredData.map((jobs) => ({
                type: jobs.title,
                count: jobs.rBudget,
              }))
            );

            if (userInfo !== undefined && res.data?.length === 0) {
              setStatsNoData(<Text>No data available</Text>);
              setPieNoData(<Text>No data available</Text>);
            }
          })
          .catch((error) => {
            if (error) setPieNoData(<Text>Server Error, Try Again Later</Text>);
            if (error)
              setStatsNoData(<Text>Server Error, Try Again Later</Text>);
          });
      });
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo]);
  useEffect(() => {}, [jobList]);

  return (
    <Container
      as={"main"}
      position={"relative"}
      margin={0}
      width={"100%"}
      maxW={"100%"}
      h={"100%"}
    >
      <Heading as={"h2"}>Dashboard</Heading>
      <Box as="section" mt={10}>
        <Tabs
          variant="soft-rounded"
          colorScheme="green"
          mt={10}
          onChange={(index) => setActiveTab(index)}
        >
          <TabList justifyContent={"center"}>
            <Tab>Account Dashboard</Tab>
            {!myDash ? <Tab>My Dashboard</Tab> : null}
          </TabList>
          <Divider my={5} border={"1px solid #000"} />
          <TabPanels>
            <TabPanel>
              <Metrics
                list={jobList}
                myDashboard={false}
                compSize={compSize}
                aPost={aPost}
                openPosition={openPosition}
                hired={hired}
                pieData={pieData}
                pieNoData={pieNoData}
                setPieData={setPieData}
                setPieNoData={setPieNoData}
                statsData={statsData}
                statsNoData={statsNoData}
                setStatsData={setStatsData}
                setStatsNoData={setStatsNoData}
              />
            </TabPanel>
            {!myDash ? (
              <TabPanel>
                <Metrics
                  list={jobList}
                  myDashboard={true}
                  compSize={compSize}
                  aPost={myAPost}
                  openPosition={myOpenPosition}
                  hired={myHired}
                  pieData={myPieData}
                  pieNoData={pieNoData}
                  setPieData={setMyPieData}
                  setPieNoData={setPieNoData}
                  statsData={myStatsData}
                  statsNoData={statsNoData}
                  setStatsData={setMyStatsData}
                  setStatsNoData={setStatsNoData}
                />
              </TabPanel>
            ) : null}
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
}

export default Dashboard;
