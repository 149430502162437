import React, { useEffect } from "react";
import { CircularProgress, Flex } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";

function Analyse() {
  const {apiURL} = UserAuth()
  const navigate = useNavigate();
  const location = useLocation();
  const result = location.state?.result;
  const summarize = () => {
    axios.post(`${apiURL}/summarize`, {
        filename: result[0],
        parsed: result[1],
      }, {withCredentials: true})
      .then((res) => {
        navigate("/screened", {
          state: {
            result: res.data,
            description: location.state?.jobdesc,
            pdfFile: location.state?.pdfFile,
          },
          replace: true,
        });
      })
      .catch((error) => {
        navigate("/screen", {
          replace: true,
        });
      });
  };
  useEffect(() => {
    if (sessionStorage.getItem(location.state?.pdfFile.name)) {
      sessionStorage.removeItem(location.state?.pdfFile.name);
    }
    if (result) {
      summarize();
    } else {
      navigate("/screen", {
        replace: true,
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Flex
      width={"100dvw"}
      height={"100dvh"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <CircularProgress isIndeterminate color="#00A3C4" size={100} />
    </Flex>
  );
}

export default Analyse;
