import {
  Container,
  Flex,
  Box,
  Stack,
  Heading,
  useColorModeValue,
  Image,
  RadioGroup,
  Radio,
  AlertDialog
} from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
import { industries } from "../../data/Industry";
import { companySize, roles } from "../../data/Constant";
import AccountCreation from "./AccountCreation";
import AccountLinking from "./AccountLinking";
import {
  isCompanyNameValid,
  verifyEmail,
} from "../../functions/verificationsRegex";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import OneButtonDialog from "../Dialog/OneButtonDialog";

function CompanyAccountSetup() {
  const { apiURL, logout } = UserAuth();
  const {onOpen, onClose, isOpen} = useDisclosure()
  const location = useLocation()
  const email = location.state.email?location.state.email:sessionStorage.getItem("signupEmail")
  const navigate = useNavigate()
  const cancelRef = useRef(null)
  const [radio, setRadio] = useState("createCompany");
  const [companyName, setCompanyName] = useState("");
  const [companyDomain, setCompanyDomain] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [compIndustry, setCompIndustry] = useState(0);
  const [compSize, setCompSize] = useState(0);
  const [companyNameError, setCompanyNameError] = useState("");
  const [companyEmailError, setCompanyEmailError] = useState("");
  const [compIndustryError, setCompIndustryError] = useState(0);
  const [compSizeError, setCompSizeError] = useState(0);
  const [companyKey, setCompanyKey] = useState("");
  const [companyKeyError, setCompanyKeyError] = useState("");
  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState("");
  const [isChecking, setIsChecking] = useState(false)
  const [popText, setPopText] = useState("")
  const [alertHeader, setAlertHeader] = useState("")

  useEffect(()=>{
    if(!email){
      setAlertHeader("Warning")
      setPopText("Something went wrong, please sign in again.")
      onOpen()
    }
    axios.get(`${apiURL}/users/findOne?email=${email}`).then((res)=>{
      if(res.data.company !== null && res.data.role !== null){
          navigate('/activate', {replace: true})
        }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


  const closePopUp = () =>{
    onClose()
    setAlertHeader("")
    setPopText("")
    logout()
  }

  const sortedIndustry = industries.sort((a, b) =>
    a.industry.localeCompare(b.industry)
  );
  const industriesArr = sortedIndustry.map((industries) => {
    return (
      <option key={industries.id} value={industries.id}>
        {industries.industry}
      </option>
    );
  });

  const savedRoles =  Object.keys(roles).map((x) => {
    return (
        x
    );
  });

  const sizeArr = Object.keys(companySize).map((x) => {
    return (
      <option key={x} value={x}>
        {companySize[x]}
      </option>
    );
  });

  const handleAccountCreation = () => {
    setIsChecking(true)
    let verified = true;
    setCompanyNameError();
    setCompanyEmailError();
    setCompIndustryError();
    setCompSizeError();

    if (!companyName || !isCompanyNameValid(companyName)) {
      verified = false;
      setCompanyNameError(
        "Company names must be between 2-50 characters, and only contain, A-Z, a-z, -,_"
      );
    }

    if (!companyEmail || !verifyEmail(companyEmail)) {
      verified = false;
      setCompanyEmailError("Please provide a correct email");
    }

    if (!compIndustry || compIndustry === 0) {
      verified = false;
      setCompIndustryError("Please provide the industry you operate in");
    }
    if (!compSize || compSize === 0) {
      verified = false;
      setCompSizeError("Please provide the size of your company");
    }

    if (verified) {
      async function fetchData() {
        axios
          .post(`${apiURL}/companies`, {
            name: companyName,
            size: parseInt(compSize),
            email: companyEmail,
            domain: companyDomain,
            industry: parseInt(compIndustry),
            isExternal: false,
          })
          .then((res) => {
            if (res.data === false) {
              setIsChecking(false)
              setCompanyNameError("This company name is already registered");
            }else if(res.data){
              axios.patch(`${apiURL}/users/${location.state.userId}`,{
                "company": res.data.id,
                "role": "aS39y22vD3"
              }).then((res)=>{
                setAlertHeader("You are all set !")
                setPopText("Your account has created you can now add a license and start working")
                onOpen()
                setIsChecking(false)
              })
            }
          })
          .catch(function (error) {
            setIsChecking(false)

          });
      }
      fetchData();
    }else{
      setIsChecking(false)
    }
  };

  const handleAccountLinking = () =>{
    setIsChecking(true)
    let verified = true
    setCompanyKeyError("")
    setRoleError("")

    if(companyKey < 16){
      verified = false
      setCompanyKeyError("Please Provide the account Key provided by your company")
    }
    if(!role){
      verified = false
      setRoleError("Please Provide the account Key provided by your company")
    }
    if(role.length > 0 && !savedRoles.includes(role)){
      verified = false
      setRoleError("This key is not valid")
    }

    
    if (verified){
      try{
        axios.get(`${apiURL}/companies/${companyKey}`).then(res=>{
          if(res.data.id === companyKey){
            axios.patch(`${apiURL}/users/${location.state.userId}`,{
              "company": companyKey,
              "role": role
            }).then((res)=>{
              setPopText("Your account has properly been linked, login to start working")
              setAlertHeader('You are all set!')
              onOpen()
              setIsChecking(false)
            })
          }
          }).catch((err)=>{
            setCompanyKeyError("The company key provided is not valide, please verify your key")
            setIsChecking(false)
          })
        } catch(error) {
          
        }
      }
      else{
        setIsChecking(false)
      } 
    }

  return (
    <Container as={"section"} margin={0} width={"100%"} maxW={"100%"}>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
        flexDir={"column"}
        rowGap={5}
      >
        <Stack align={"center"}>
          <Image
            src="https://uploads-ssl.webflow.com/64f372bf5cb2cbb8370c00fd/6500f8e0204206c9e8e4d598_minilogo.png"
            height={50}
          />
          <Heading fontSize={"4xl"} textAlign={"center"}>
            Link your company account
          </Heading>
        </Stack>
        <Stack spacing={8} mx={"auto"} maxW={"xl"}>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
            minW={{ base: "90dvw", md: "400px" }}
          >
            <Box>
              <RadioGroup onChange={setRadio} value={radio}>
                <Stack direction={"row"}>
                  <Radio value="joinCompany">Join my company account</Radio>
                  <Radio value="createCompany">Create my company account</Radio>
                </Stack>
              </RadioGroup>
            </Box>
            <Stack spacing={4} mt={5}>
              {radio === "joinCompany" ? (
                <AccountLinking
                  companyKey={companyKey}
                  companyKeyError={companyKeyError}
                  setCompanyKey={setCompanyKey}
                  role={role}
                  setRole={setRole}
                  roleError={roleError}
                  isChecking={isChecking}
                  handleAccountLinking={handleAccountLinking}
                  />
                  ) : (
                    <AccountCreation
                    companyNameError={companyNameError}
                    companyEmailError={companyEmailError}
                    compSizeError={compSizeError}
                    compIndustryError={compIndustryError}
                    companyName={companyName}
                    companyDomain={companyDomain}
                    companyEmail={companyEmail}
                    compIndustry={compIndustry}
                    compSize={compSize}
                    setCompanyName={setCompanyName}
                    setCompanyDomain={setCompanyDomain}
                    setCompanyEmail={setCompanyEmail}
                    setCompIndustry={setCompIndustry}
                    setCompSize={setCompSize}
                    sizeArr={sizeArr}
                    industriesArr={industriesArr}
                    handleAccountCreation={handleAccountCreation}
                    isChecking={isChecking}
                />
              )}
            </Stack>
          </Box>
        </Stack>
      </Flex>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        onCloseComplete={closePopUp}
      >
        <OneButtonDialog alertHeader={alertHeader} btnText={"Login"} mainText={popText} func1={closePopUp} />
      </AlertDialog>
    </Container>
  );
}

export default CompanyAccountSetup;
