import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  Stack,
  } from '@chakra-ui/react';
import { UserAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { Text } from '@chakra-ui/react';


function Header() {
  const { isOpen } = useDisclosure();
  // eslint-disable-next-line no-unused-vars
  const {userInfo,logout} = UserAuth()

  return (
      <Box width={'12rem'} bg={'white'} padding={'5px 15px'} borderRadius={50}
      boxShadow={'0px 1px 10px #7090b040'} position={'fixed'} right={'40px'} top={'40px'}
      zIndex={10}
      >
        <Flex alignItems={'center'} justifyContent={'end'} gap={2}>
          <Text maxW={"10ch"} overflow={'hidden'} whiteSpace={"nowrap"} textOverflow={'ellipsis'}>{userInfo?.fname}</Text>
        <Menu >
            <MenuButton
            as={Button}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
            minW={0}>
            <Avatar
                size={'md'}
                src={
                ""
                }
            />
            </MenuButton>
            <MenuList boxShadow={'0px 1px 10px #7090b099'}>
            <Link to={'/profile'} className="menuLink">
              <MenuItem as={'li'} >
                Profile
              </MenuItem>
            </Link> 
            <MenuItem as={'li'}>Settings</MenuItem>
            <MenuDivider />
            <MenuItem onClick={()=>logout()}>Log out</MenuItem>
            </MenuList>
        </Menu>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              
            </Stack>
          </Box>
        ) : null}
      </Box>

  );
}

export default Header;