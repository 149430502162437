import React, {useState} from 'react'
import SignIn from '../../components/Auth/SignIn'
import SignUp from '../../components/Auth/SignUp'
import { useLocation } from 'react-router-dom'


function Login() {

  const [ log, setLog ] = useState(0)
  const location = useLocation()

  if (location.pathname === "/signUp"){
      return (
          <SignUp location={location} log={log} setLog={setLog} />
          )
    }
  else{
    return(
        <SignIn location={location} log={log} setLog={setLog} />
    )
  }
}

export default Login