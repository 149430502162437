import {
  Container,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Button,
  Flex,
  CircularProgress,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Cards from "./Cards";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";
import { FiPlus } from "react-icons/fi";
import {
  AlertDialog,
  useDisclosure, // This is the correct import location for useDisclosure
} from "@chakra-ui/react";
import { useRef } from "react";
import { highRoles } from "../../data/Constant";
import TwoButtonDialog from "../Dialog/TwoButtonDialog";

function JobBoard(props) {
  const { userInfo, companyInfo, apiURL } = UserAuth();
  const [jobLoad, setJobLoad] = useState(false);
  const [jobs, setJobs] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [close, setClose] = useState(null);
  const cancelRef = useRef(null);
  const warningText = "Are you sure you want to close this post?";
  useEffect(() => {
    setJobLoad(true);
    try {
      axios.get(`${apiURL}/jobs?company=${userInfo?.company}`, {withCredentials:true}).then((res) => {
        setJobs(res.data);
        setJobLoad(false);
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);
  // eslint-disable-next-line no-unused-vars
  const [tabIndex, setTabIndex] = useState(0);

  const handleClose = (e) => {
    setClose(e);
    onOpen();
  };

  const handleCancelClose = (e) => {
    setClose(null);
    onClose();
  };

  const handleConfirmClose = async () => {
    await axios.patch(`${apiURL}/jobs/${close}`, {
      active: 0,
    }, {withCredentials:true});
    onClose();
    window.location.reload();
  };

  const responsive = {
    0: { items: 1 },
    990: { items: 2 },
    1200: { items: 2 },
    1375: { items: 3 },
  };
  const permittedActiveView = () => {
    if (highRoles.includes(userInfo?.role)) {
      return jobs?.filter((job) => job.active);
    }
    return jobs?.filter(
      (job) =>
        job.active &&
        (job.hManager === userInfo.id || job.recruiter === userInfo.id)
    );
  };
  const permittedClosedView = () => {
    if (highRoles.includes(userInfo?.role)) {
      return jobs?.filter((job) => !job.active);
    }
    return jobs?.filter(
      (job) =>
        !job.active &&
        (job.hManager === userInfo.id || job.recruiter === userInfo.id)
    );
  };

  const openJobs = permittedActiveView();
  const closedJobs = permittedClosedView();

  const classifyJobsPost = (filteredJobs, closed) => {
    const items = filteredJobs?.map((job) => (
      <Cards
        id={job.id}
        title={job.title}
        department={job.department}
        recruiter={job.recruiter}
        target={job.hTarget}
        ptd={job.addedDate}
        status={job.active ? "active" : "archive"}
        city={job.city}
        country={job.country}
        region={job.region}
        interviewing={job.interviewing}
        hired={job.hired}
        aReceived={job.aReceived}
        func={closed ? null : handleClose}
        isExternal={companyInfo?.isExternal ? job.isExternal : null}
      />
    ));
    return items;
  };
  const notFound = <Text fontSize={"20px"}>No Job Found...</Text>;

  return (
    <Container as={"section"} margin={0} width={"100%"} maxW={"100%"}>
      <Heading as={"h2"} fontSize={"32px"}>
        My Board
      </Heading>
      <Box mt={10}>
        <Flex>
          <Button
            as={"a"}
            // alignSelf={"end"}
            href="jobs/new_post"
            // display={"flex"}
            ml={"auto"}
            mr={"5"}
            mb={5}
            className="thirdBackground btnlink"
            gap={1}
          >
            New Job Post
            <FiPlus />
          </Button>
        </Flex>
        <Tabs
          w={"90%"}
          isLazy={true}
          mx={"auto"}
          colorScheme="twitter"
          position={"relative"}
          display={{ md: "block", lg: "flex" }}
          onChange={(index) => setTabIndex(index)}
          py={10}
          px={{ md: 5 }}
          maxW={{ md: "70dvw" }}
          minH={"400px"}
          className="card"
        >
          <TabList
            display={"flex"}
            flexDir={"column"}
            color={"black"}
            borderBottom={0}
            pr={2}
            justifyContent={"center"}
          >
            <Tab>Active</Tab>
            <Tab>Pause</Tab>
          </TabList>
          <TabPanels borderLeft={{ sm: "none", lg: "1px solid #999" }}>
            <TabPanel>
              <Box
                py={"5px"}
                overflow={"hidden"}
                width={{ md: "90%" }}
                display={"flex"}
                justifyContent={"center"}
              >
                {jobLoad ? (
                  <Flex
                    width={"100%"}
                    height={"400px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <CircularProgress
                      isIndeterminate
                      color="#00A3C4"
                      size={100}
                    />
                  </Flex>
                ) : (
                  <AliceCarousel
                    mouseTracking
                    items={classifyJobsPost(openJobs, false)}
                    responsive={responsive}
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel>
              <Box
                py={"5px"}
                overflow={"hidden"}
                width={{ md: "90%" }}
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                {closedJobs.length < 1 ? (
                  notFound
                ) : (
                  <AliceCarousel
                    mouseTracking
                    items={classifyJobsPost(closedJobs, true)}
                    responsive={responsive}
                    keyboardNavigation={true}
                  />
                )}
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <TwoButtonDialog
          btnText={"Close"}
          mainText={warningText}
          func1={handleCancelClose}
          func2={handleConfirmClose}
        />
      </AlertDialog>
    </Container>
  );
}

export default JobBoard;
