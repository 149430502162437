import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Heading } from "@chakra-ui/layout";
import { CircularProgress } from "@chakra-ui/react";
import { UserAuth } from "../../context/AuthContext";
import AppCard from "./ApplicationCard";
import ApplicationStage from "./ApplicationStage";
import { Link,  useLocation} from "react-router-dom";

function Applications(props) {
  const { compUsers } = UserAuth();
  const { setView, setCard, applicants, isloading, lrounds, rounds, job } =
    props;
  const [jobHManager, setJobHManager] = useState(null);
  const [jobRecruiter, setJobRecruiter] = useState(null);
  const [searchField] = useState("");
  const location = useLocation()
  useEffect(() => {
    const findemployee = () => {
      for (let x of compUsers) {
        if (jobHManager !== null && jobRecruiter !== null) return 0;
        if (x.id === job.recruiter)
          setJobRecruiter(
            x.fname + " " + (x.mname === null ? "" : x.mname) + " " + x.lname
          );
        if (x.id === job.hManager)
          setJobHManager(
            x.fname + " " + (x.mname === null ? "" : x.mname) + " " + x.lname
          );
      }
    };
    findemployee();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeView = (i) => {
    setCard(i);
    setView(1);
    window.scrollTo(0,0)
  };

  const buildCard = (arr) => {
    const files = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].status < rounds.length) {
        files.push(
          <AppCard
          key={arr[i].id}
            group={"interviewing"}
            arr={arr}
            i={i}
            changeView={changeView}
          />
        );
      }
    }
    return files;
  };
  const buildHiredCard = (arr) => {
    const files = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].status === rounds.length) {
        files.push(
          <AppCard key={arr[i].id} group={"hired"} arr={arr} i={i} changeView={changeView} />
        );
      }
    }
    return files;
  };
  const buildRejectCard = (arr) => {
    const files = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].status === 10 || (job?.isExternal && arr[i].status === 11)) {
        files.push(
          <AppCard key={arr[i].id} group={"rejected"} arr={arr} i={i} changeView={changeView} />
        );
      }
    }
    return files;
  };
  const buildRescindedCard = (arr) => {
    const files = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].status === 11) {
        files.push(
          <AppCard
            key={arr[i].id}
            group={"rescinded"}
            arr={arr}
            i={i}
            changeView={changeView}
          />
        );
      }
    }
    return files;
  };

  const filteredCandidate = applicants.filter((applicant) => {
    const name = `${applicant?.fname}${applicant?.mname === ""? "": ` ${applicant?.mname}`} ${applicant?.lname}`
    return name?.toLowerCase().includes(searchField?.toLowerCase());
  });
  return (
    <>
    <Link to={`${location.state.pastLocation}`}>Back to job details</Link>
      <Box as="section" className="card" py="5" px="5" my={5}>
        <Heading as="h2" fontSize={30} textAlign="center">
          Hiring details
        </Heading>
        <Flex flexWrap={"wrap"} justifyContent={"space-evenly"}>
          <Text>Position: {job.title}</Text>
          <Text>Hiring Target: {job.hTarget}</Text>
          <Text>
            Hiring left:{" "}
            {job.hTarget - job.hired < 0 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>
                Overhired({job.hired - job.hTarget})
              </span>
            ) : (
              job.hTarget - job.hired
            )}
          </Text>
          <Text>Hiring Manager: {jobHManager}</Text>
          <Text>Recruiter: {jobRecruiter}</Text>
        </Flex>
        <Flex
          border={"2px solid #ccc"}
          py={2}
          my={2}
          flexWrap={"wrap"}
          justifyContent={"space-evenly"}
        >
          {job?.rounds?.round.map((round, index) => {
            return (
              <Text fontWeight={"bold"} key={round[0]}>
                {index + 1}. {round[0].toUpperCase()} : {round[1].toUpperCase()}
              </Text>
            );
          })}
        </Flex>
        <Flex flexWrap={"wrap"} justifyContent={"space-evenly"}>
          <Text>Applicant count: {applicants.length}</Text>
        </Flex>

        {isloading ? (
          <Flex
            width={"100%"}
            height={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress isIndeterminate color="#00A3C4" size={100} />
          </Flex>
        ) : (
          <Flex flexWrap="wrap" gap={10}></Flex>
        )}
      </Box>

      {/* Fix issue with filtering reseting changing the index of the element in the array and messing up search*/}
      {/* <Flex alignItems={"center"} justifyContent={"flex-end"} my={5}>
        <Input
          background={"#fff"}
          width={"200px"}
          border={"1px solid #666"}
          placeholder="Search Candidate..."
          onChange={(e) => setSearchField(e.target.value)}
          aria-label="Search Candidate"
        />
      </Flex> */}

      {applicants?.length !== 0 ? (
        <Flex flexDir={"column"} gap={20}>
          <ApplicationStage
            isloading={isloading}
            lrounds={lrounds[0]}
            applicants={filteredCandidate}
            buildCard={buildHiredCard}
          />
          <ApplicationStage
            isloading={isloading}
            lrounds={"Interviewing"}
            applicants={filteredCandidate}
            buildCard={buildCard}
          />
          <ApplicationStage
            isloading={isloading}
            lrounds={"Rejected"}
            applicants={filteredCandidate}
            buildCard={buildRejectCard}
          />
          {job?.isExternal === true ? null : (
            <ApplicationStage
              isloading={isloading}
              lrounds={"Rescinded"}
              applicants={filteredCandidate}
              buildCard={buildRescindedCard}
            />
          )}
        </Flex>
      ) : isloading ? (
        <Flex
          width={"100%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress isIndeterminate color="#00A3C4" size={100} />
        </Flex>
      ) : (
        <Flex
          flexDir={"column"}
          gap={20}
          width={"100%"}
          textAlign={"center"}
          height={"50vh"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text fontSize={"25px"} fontWeight={"bold"}>
            No Applicant Yet...
          </Text>
        </Flex>
      )}
    </>
  );
}

export default Applications;
