import { Flex, Text } from '@chakra-ui/react';
import {React,useEffect} from 'react'

function Login404() {
    useEffect(() => {
        const timer = setTimeout(() => {
          window.location.href = '/'
        }, 1000);
        return () => clearTimeout(timer);
      }, []);
  return (
    <Flex width={'100svw'} height={'100svh'} justifyContent={'center'} alignItems={'center'}>
        <Text fontSize={'2rem'} textAlign={'center'}>Redirecting...</Text>
    </Flex>
  )
}

export default Login404