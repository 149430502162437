import React from "react";
import {
  Container,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Image,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import GoogleButton from "react-google-button";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  verifyEmail,
  verifyPassword,
} from "../../functions/verificationsRegex";
import { UserAuth } from "../../context/AuthContext";

function SignUp() {
  const {apiURL} = UserAuth()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  const emailPasswordSignUp = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        localStorage.setItem("plan", 4);
          axios.post(`${apiURL}/authorized-users`, {
            "email": res.user.email,
            "uid": res.user.uid,
          }).then(()=>{
            navigate("/verify", {state:{uid:res.user.uid, email: res.user.email}});
          })
      })
      .catch((error) => {
      });
  };

  const signUpWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider).then((res) => {
          localStorage.setItem("plan", 4);
          axios.post(`${apiURL}/authorized-users`, {
            "email": res.user.email,
            "uid": res.user.uid,
          }).then(()=>{
            navigate("/verify", {state:{uid:res.user.uid, email: res.user.email}});
          })
      });
    } catch (err) {}
  };
  const handleSubmit = () => {
    let verified = true;
    setEmailError();
    setPasswordError();

    if (!email || !verifyEmail(email)) {
      verified = false;
      setEmailError("Verify a correct email");
    }
    if (!password || !verifyPassword(password)) {
      verified = false;
      setPasswordError("Verify your password");
    }

    if (verified) {
      async function fetchData() {
        try {
          emailPasswordSignUp();
        } catch (error) {}
      }
      fetchData();
    }
  };

  return (
    <Container as={"section"} margin={0} width={"100%"} maxW={"100%"}>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
        flexDir={"column"}
        rowGap={5}
      >
        <Stack align={"center"}>
          <Image
            src="https://uploads-ssl.webflow.com/64f372bf5cb2cbb8370c00fd/6500f8e0204206c9e8e4d598_minilogo.png"
            height={50}
          />
          <Heading fontSize={"4xl"} textAlign={"center"}>
            Create a new account
          </Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy our tool
          </Text>
        </Stack>
        <Flex gap={2} flexDir={"row"} flexWrap={"wrap"} justifyContent={"center"}>
          <Text>Already have an account?</Text>
          <Button onClick={()=> navigate("/")} variant={'link'} className='thirdColor'>Sign in</Button>
        </Flex>
        <Box className="card">
          <Button
            onClick={() => signUpWithGoogle()}
            w={"max-content"}
            p={"1px"}
            background={"none"}
            height={"max-content"}
          >
            <GoogleButton label="Sign up With Google" />
          </Button>
        </Box>
        <Text>- OR -</Text>
        <Stack spacing={8} mx={"auto"} maxW={"xl"}>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
            minW={{ base: "90dvw", md: "400px" }}
          >
            <Stack spacing={4}>
              <FormControl id="email" isRequired isInvalid={emailError}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FormErrorMessage>{emailError}</FormErrorMessage>
              </FormControl>
              <FormControl id="password" isRequired isInvalid={passwordError}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FormErrorMessage>{passwordError}</FormErrorMessage>
              </FormControl>
              <Stack spacing={10} pt={2}>
                <Button
                  loadingText="Submitting"
                  size="lg"
                  className="thirdBackground"
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  onClick={() => handleSubmit()}
                >
                  Create my profile
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </Container>
  );
}

export default SignUp;
