import { Checkbox } from "@chakra-ui/checkbox";
import { Icon } from "@chakra-ui/icon";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { Box, Container, Flex, Heading, Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  CircularProgress,
  Input,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import AppDetails from "./AppDetails";
import NoteBook from "../notes/NoteBook";
import { UserAuth } from "../../context/AuthContext";
import ScoreCard from "./ScoreCard";


function AppProfile(props) {
  const { userInfo, apiURL } = UserAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [profile] = useState(props?.info);
  const [isGenerating, setIsGenerating] = useState(false);
  const { job } = useParams();
  const [score, setScore] = useState(profile?.score ? profile.score : 0);
  // eslint-disable-next-line no-unused-vars
  const [jobFit, setJobFit] = useState(
    profile?.jobFit ? profile.jobFit : "No job fit yet"
  );
  const [status, setStatus] = useState(profile?.status);
  const [salary, setSalary] = useState(0);
  const [offer, setOffer] = useState({});
  const [preScreen, setPreScreen] = useState(profile?.preScreen);
  const [interviewRounds] = useState(props.rounds);
  const tcolor = "#00A3C4";
  const bcolor = "#00A3C4";
  const cardCss = {
    background: "#fff",
    width: "100%",
    py: 5,
    borderRadius: 5,
    boxShadow: "0 2px 2px #000",
  };
  const customScrollbarStyles = {
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#008099",
      borderRadius: "5px",
    },
  };
  useEffect(() => {
    if (profile?.status === interviewRounds.length || profile?.status === 11) {
      axios
        .get(`${apiURL}/offers/${profile.jobId}/${profile.id}`, {withCredentials:true})
        .then((res) => {
          setOffer(res.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const changeRound = async (math) => {
    await axios
      .patch(`${apiURL}/applicant_status/${profile.id}`, {
        status: math === 1 ? profile.status + 1 : profile.status - 1,
      }, {withCredentials:true})
      .then(() => window.location.reload());
  };

  const reject = async () => {
    await axios.patch(`${apiURL}/applicant_status/${profile.id}`, {
      status: 10,
      interviewing: false,
    }, {withCredentials:true});
    window.location.reload();
  };

  const Rescind = async () => {
    await axios.patch(`${apiURL}/applicant_status/${profile.id}`, {
      status: 11,
    }, {withCredentials:true});

    window.location.reload();
  };

  const hired = async () => {
    if (salary > 1 && !props.job?.isExternal) {
      axios.post(`${apiURL}/offers`, {
        jobId: profile.jobId,
        applicantId: `${profile.id}`,
        salary: salary,
      }, {withCredentials:true});
    }
    await axios
      .patch(`${apiURL}/applicant_status/${profile.id}`, {
        status: profile.status + 1,
        interviewing: false,
        passed: true,
      }, {withCredentials:true})
      .then(() => window.location.reload());
  };

  const generateJobFit = async (change) => {
    setIsGenerating(true);
    await axios
      .get(`${apiURL}/jobs/${job}/jobDescSum`, {withCredentials:true})
      .then((res) => {
        axios
          .post(`${apiURL}/jobfit`, {
            summary: profile.summary,
            description: res.data.jobDescSum,
          }, {withCredentials:true})
          .then(async (response) => {
            setIsGenerating(false);

            const messageData = JSON.parse(response.data.message);
            setScore(messageData.nScore);
            setJobFit(messageData.Reason);
            await axios
              .patch(`${apiURL}/applicant_fit/${profile.id}`, {
                score: parseInt(messageData.nScore),
                jobFit: messageData.Reason,
                status: 1,
              }, {withCredentials:true})
              .then(() => {
                if (change) {
                  setStatus(1);
                  changeRound(1);
                }
                if (profile.interviewing === false) {
                  axios.patch(`${apiURL}/applicant_fit/${profile.id}`, {
                    interviewing: true,
                  }, {withCredentials:true});
                }
              })
              .then(() => window.location.reload());
          });
      })
      .catch((error) => {});
  };

  const checkHire = () => {
    if (props.job.isExternal) {
      hired();
    } else {
      onOpen();
    }
  };

  const exportD = () => {
    axios
      .post(`${apiURL}/genpdf`, {
        applicant: props.info,
        job: props.job,
      }, {withCredentials:true})
      .then((res) => {
        try {
          const blob = new Blob([res.data], { type: "text/html" });

          // Generating a URL for the blob object
          const url = URL.createObjectURL(blob);

          // Creating a virtual anchor tag to programmatically trigger a download
          const a = document.createElement("a");
          a.href = url;
          a.download = "myfile.html";
          a.click();

          // Releasing the reference to the file by revoking the Object URL
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error generating PDF:", error);
        }
      });
  };

  const handleSalaryChange = (event) => {
    setSalary(event.target.value);
  };
  const handleSalaryModalClose = (event) => {
    setSalary(0);
    onClose();
  };
  return (
    <Flex flexDir="column" gap={10} >
      <Box __css={cardCss} position="relative" alignContent={"center"}>
        <Flex position={{base:"relative"}} justifyContent={"end"} mr={5}>
          <Menu>
            <MenuButton
              as={Button}
              _hover={{ background: "#4cbed5" }}
              _active={{ background: "#007289" }}
              background={bcolor}
              color="#fff"
            >
              <Icon as={HiOutlineDotsHorizontal} fontSize="30px" />
            </MenuButton>
            <MenuList p={2}>
              {profile?.passed === true ||
              profile.status >= interviewRounds.length ? null : (
                <MenuGroup title="Move To">
                  {profile?.status < interviewRounds.length - 1 &&
                  profile?.status > 0 ? (
                    <MenuItem onClick={() => changeRound(1)}>
                      Next Round
                    </MenuItem>
                  ) : null}
                  {profile?.status > 1 && profile?.passed === false ? (
                    <MenuItem onClick={() => changeRound(0)}>
                      Previous Round
                    </MenuItem>
                  ) : null}
                  {profile?.status < 1 ? (
                    <Text ml={5}>Not screened yet</Text>
                  ) : null}
                  <MenuDivider />
                </MenuGroup>
              )}
              <MenuGroup title="Status">
                {profile?.status === interviewRounds.length - 1 &&
                profile?.passed === false ? (
                  <MenuItem fontWeight={"bold"} onClick={() => checkHire()}>
                    {props.job?.isExternal ? "Validate" : "Hire"}
                  </MenuItem>
                ) : null}
                {profile?.passed === true &&
                profile.status === interviewRounds.length ? (
                  props.job.isExternal ? (
                    <Text wordBreak={"break-all"}>
                      This candidate has been validated
                    </Text>
                  ) : (
                    <MenuItem onClick={() => Rescind()} fontWeight={"bold"}>
                      Rescind Offer
                    </MenuItem>
                  )
                ) : null}
                {profile?.status === 11 ? (
                  <Text ml={5} fontWeight={"bold"}>
                    Offer Rescinded
                  </Text>
                ) : null}
                {profile?.status === 10 ? (
                  <Text ml={5} fontWeight={"bold"}>
                    Rejected
                  </Text>
                ) : null}
                {profile?.passed === false &&
                profile.status !== 10 &&
                profile?.status < interviewRounds.length ? (
                  <MenuItem onClick={() => reject()} fontWeight={"bold"}>
                    Reject
                  </MenuItem>
                ) : null}
              </MenuGroup>
            </MenuList>
          </Menu>
        </Flex>
        <Heading as="h2" mb={3} textAlign="center" color={tcolor}>
          {profile.fname} {profile?.mname} {profile.lname}
        </Heading>
        <Flex
          my={3}
          justifyContent="space-evenly"
          fontSize={18}
          fontWeight="bold"
          flexWrap={"wrap"}
          gap={5}
        >
          <Text>Phone Number: {profile.pNumber}</Text>
          <Text>{profile.email}</Text>
          <Checkbox
            disabled
            defaultChecked={profile.visa || profile.sponsorship ? true : false}
            colorScheme={"pink"}
            borderColor="#000"
          >
            Visa Needed
          </Checkbox>
        </Flex>
        <Flex
          my={3}
          justifyContent="space-evenly"
          fontSize={18}
          fontWeight="bold"
          flexWrap={"wrap"}
        >
          <Text>
            Location: {profile.spr}, {profile.country}
          </Text>
          <Text>
            Resume Score:{" "}
            {score !== 0 ? (
              isGenerating ? (
                <CircularProgress isIndeterminate size={"15px"} />
              ) : (
                score
              )
            ) : isGenerating ? (
              <CircularProgress isIndeterminate size={"12px"} />
            ) : (
              "--"
            )}
            /20
          </Text>
          <Text textAlign={"center"}>Applying for: {props?.job.title} Position</Text>
        </Flex>
        <Flex justifyContent={"center"}>
        {profile?.status === interviewRounds.length &&
          props.job.isExternal ? (
            <Button
              className={"thirdBackground"}
              fontWeight={"bold"}
              mt={5}
              onClick={() => exportD()}
            >
              Export
            </Button>
          ) : null}
        </Flex>
      </Box>

      <Flex
        columnGap={5}
        rowGap={10}
        flexWrap={"wrap"}
        justifyContent={"center"}
      >
        <Box
          __css={cardCss}
          maxWidth={"900px"}
          height={"-webkit-fit-content"}
          minH={"300px"}
        >
          <Tabs>
            <TabList color={tcolor}>
              <Tab>Resume</Tab>
              <Tab>Summary</Tab>
              <Tab isDisabled={preScreen ? false : true}>Job Fit</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Link
                  to={`https://applicantresume.s3.amazonaws.com/${profile.resPath}`}
                >
                  Download Resume in PDF format
                </Link>
              </TabPanel>
              <TabPanel>{profile.summary}</TabPanel>
              <TabPanel display={"flex"} flexDir={"column"}>
                {isGenerating ? null : jobFit}
                {jobFit !== "No job fit yet" ? null : isGenerating ? (
                  <CircularProgress isIndeterminate size={"15px"} />
                ) : (
                  <Button
                    className="thirdBackground"
                    mx={"auto"}
                    marginTop={5}
                    onClick={() => generateJobFit(true)}
                  >
                    Score this applicant
                  </Button>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>

        <ScoreCard cardCss={cardCss} score={score} avgScore={props.avgScore} status={profile.status}/>
      </Flex>
      <Flex
        columnGap={5}
        rowGap={10}
        flexWrap={"wrap"}
        justifyContent={"center"}
      >
        <Box
          __css={cardCss}
          maxWidth={"900px"}
          height={"-webkit-fit-content"}
          minH={"300px"}
        >
          <Tabs>
            <TabList color={tcolor}>
              <Tab>My notes</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Container padding={0} m={0} minW={"100%"} minH={"150px"}>
                  <Container
                    className="noteContBlur"
                    margin={0}
                    padding={0}
                    minW={"100%"}
                    position={"relative"}
                  >
                    <Box
                      overflowY={"scroll"}
                      position={"relative"}
                      py={5}
                      maxH={"500px"}
                      __css={customScrollbarStyles}
                    >
                      <NoteBook
                        isIframe={true}
                        info={{ me: userInfo.id, applicant: profile.id }}
                      />
                    </Box>
                  </Container>
                </Container>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>

        <Box
          __css={cardCss}
          px={5}
          maxWidth={"500px"}
          height={"-webkit-fit-content"}
          minH={"300px"}
        >
          {profile?.status !== 10 ? (
            <Tabs>
              <TabList color={tcolor}>
                {props.rounds.map((round, index) => {
                  return <Tab key={index}>{round[0]}</Tab>;
                })}
              </TabList>
              <TabPanels>
                {props.rounds.map((round, i) => {
                  return (
                    <TabPanel key={i}>
                      {
                        <TableContainer key={i}>
                          <Table
                            variant="simple"
                            display={"flex"}
                            flexDir={"column"}
                            flexWrap={"wrap"}
                          >
                            <TableCaption>
                              Candidate Interview Status
                            </TableCaption>
                            <Thead>
                              <Tr>
                                <Th>Screener</Th>
                                <Th>Status</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                <Td>
                                  <Text>{round[1]}</Text>
                                </Td>
                                <Td>
                                  <Text>{status > i ? "Done" : "Waiting"}</Text>
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </TableContainer>
                      }
                    </TabPanel>
                  );
                })}
                <TabPanel>
                  {profile?.status === 5 || profile?.status === 6 ? (
                    <TableContainer>
                      <Table
                        variant="simple"
                        display={"flex"}
                        flexDir={"column"}
                        flexWrap={"wrap"}
                      >
                        <TableCaption>Candidate offer detail</TableCaption>
                        <Thead>
                          <Tr>
                            <Th>Salary</Th>
                            <Th>Added Date</Th>
                            <Th>Status</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td>
                              <Text>{offer?.salary}$</Text>
                            </Td>
                            <Td>
                              <Text>
                                {new Date(offer?.addedDate).toLocaleDateString(
                                  "en-US"
                                )}
                              </Text>
                            </Td>
                            <Td>
                              <Text>
                                {profile?.status === 5
                                  ? "Accepted"
                                  : "Rescinded"}
                              </Text>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Text>No offer yet</Text>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            <Tabs>
              <TabList color={tcolor}>
                <Tab>Status</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Text>This candidate was rejected</Text>
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </Box>
      </Flex>

      <AppDetails
        jobJson={props.jobJson}
        info={props.info}
        reload={props.reload}
        disqualify={reject}
        setPs={setPreScreen}
        jobFit={generateJobFit}
      />

      <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Offer Information</ModalHeader>
          <ModalCloseButton onClick={handleSalaryModalClose} />
          <ModalBody>
            <Text fontWeight="bold" mb="1rem">
              Please enter the salary offered to this employee.
            </Text>
            <Flex gap={5} alignItems={"center"} mt={10}>
              <label>Salary</label>
              <Input
                type="number"
                placeholder="75000$"
                width={"12ch"}
                onChange={handleSalaryChange}
                value={salary !== 0 ? salary : ""}
              ></Input>
              $
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleSalaryModalClose}>
              Cancel
            </Button>
            <Button colorScheme="green" onClick={() => hired()}>
              Hire
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default AppProfile;
