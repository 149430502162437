import {Flex, Image, AlertDialog} from "@chakra-ui/react";
import { FiChevronLeft } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { useEffect, useRef, useState } from "react";
import OneButtonDialog from "../Dialog/OneButtonDialog";
import { useDisclosure } from "@chakra-ui/react";
import axios from "axios";

function PricingPage() {
  const {userInfo, logout, apiURL} = UserAuth()
  const cancelRef = useRef()
  const [errorText, setErrorText] = useState()
  const {isOpen, onOpen, onClose}= useDisclosure()
  const user = !userInfo? JSON.parse(localStorage.getItem("userInfo")): userInfo
  const plan = parseInt(localStorage.getItem('plan'))
  const navigate = useNavigate()

  const closePopUp = () =>{
    onClose()
    logout()
  }

  useEffect(()=>{
    axios.get(`${apiURL}/companies/${user.company}`).then((res)=>{
      if (plan !== res.data.plan){
          localStorage.setItem("plan", res.data.plan)
          navigate('/')
          logout()
      }
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
   

  useEffect(()=>{
    if(!user){
      setErrorText("Something went wrong please login and try again. If this issue persist, please contact our support team")
      onOpen()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userInfo])
  // Paste the stripe-pricing-table snippet in your React component
  return (


  <>
    <Flex justifyContent={'center'} alignItems={'center'} py={2} background={'#fff'} width={'100dvw'} height={'10dvh'} >
      <Image src='https://uploads-ssl.webflow.com/64f372bf5cb2cbb8370c00fd/6500f8e0204206c9e8e4d598_minilogo.png' height={50}/>
    </Flex>
    <Flex my={5} alignItems={'center'}>
      <FiChevronLeft /><Link to={'/activate'}>Back to main page</Link>
    </Flex>
    <Flex flexDir={'column'} minH={'500px'} justifyContent={'center'}>
      <stripe-pricing-table 
        pricing-table-id="prctbl_1Oagq2H9XhtRb48YqTz5uQhM"
        publishable-key="pk_live_51NMNEdH9XhtRb48YgoyR3qXulaDZZmTSwDPQUp7mM1hx5OFmhP2PgGzAJTYoz567xsHP0ld4gBJefY9vRPjZMK6j00uAwKSusS"
        client-reference-id={`${user?.company}`}
      >
      </stripe-pricing-table>
      </Flex>
      <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered
            onCloseComplete={closePopUp}
        >
            <OneButtonDialog alertHeader={"Warning"} mainText={errorText} btnText={"Try again"} func1={onClose}/>
        </AlertDialog>
  </>
  );
}

export default PricingPage;