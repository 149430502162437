import React, { useEffect, useState } from "react";
import { Heading, Container, Box, Text, Link, Flex } from "@chakra-ui/react";
import { CircularProgress } from "@chakra-ui/react";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function ExternalPost() {
  const { companyInfo, apiURL } = UserAuth();
  const param = useParams();
  const jobId = param?.post;
  const navigate = useNavigate();
  const [valid, setValid] = useState(false);
  useEffect(() => {
    if (companyInfo?.id) verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo]);

  const verify = async () => {
    await axios
      .get(`${apiURL}/jobs/${jobId}/${companyInfo?.id}`, {withCredentials:true})
      .then((res) => {
        if (companyInfo?.id !== res.data?.company) {
          navigate("/jobs");
          return;
        } else {
          setValid(true);
        }
      });
  };
  return (
    <Container
      as={"section"}
      position={"relative"}
      margin={0}
      width={"100%"}
      maxW={"100%"}
      h={"100%"}
    >
      <Heading as={"h2"}>Track Your Job Post </Heading>
      {valid ? (
        <Box as="section" mt={10}>
          <Box className="card" minH={"100px"} p={5} my={10}>
            <Heading as={"h3"} fontSize={"24px"} pt={5} textAlign={"center"}>
              Application link
            </Heading>
            <Box
              display={"flex"}
              flexDir={"column"}
              alignItems={"center"}
              mt={5}
            >
              <Text>
                Application Form:{" "}
                <Link
                  href={`https://ariaapply.netlify.app/?apply=${jobId}&c=${companyInfo?.id}`}
                  target="_blank"
                >{`https://ariaapply.netlify.app/?apply=${jobId}&c=${companyInfo?.id}`}</Link>
              </Text>
              <Text>
                Application Post:{" "}
                <Link
                  href={`https://e-jobpage.netlify.app/details.html?${companyInfo?.id}&${jobId}`}
                  target="_blank"
                >{`https://e-jobpage.netlify.app/details.html?${companyInfo?.id}&${jobId}`}</Link>
              </Text>
            </Box>
          </Box>
          <Container
            as={"section"}
            display={"flex"}
            width={"100%"}
            maxW={"100%"}
            justifyContent={"space-evenly"}
            flexWrap={"wrap"}
            rowGap={5}
          >
            <Box
              className="card"
              minW={{base:"90dvw",md: "400px"}}
              minH={"500px"}
              maxW={"40vw"}
              width={"70%"}
            >
              <Heading as={"h3"} fontSize={"24px"} pt={5} textAlign={"center"}>
                Preview your post
              </Heading>
              <iframe
                title="Job post preview"
                src={`https://e-jobpage.netlify.app/details.html?${companyInfo?.id}&${jobId}`}
                width={"100%"}
                height={"500px"}
              />
            </Box>
            <Box
              className="card"
              minW={{base:"90dvw",md: "400px"}}
              minH={"500px"}
              maxW={"40vw"}
              w={"30%"}
            >
              <Heading as={"h3"} fontSize={"24px"} pt={5} textAlign={"center"}>
                Control external job post
              </Heading>
              <Box
                display={"flex"}
                flexDir={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={5}
                height={"200px"}
              >
                <Heading as={"h4"} fontSize={"18"}>
                  Track your job post across hiring platform
                </Heading>
                <Text>Coming Soon...</Text>
              </Box>
            </Box>
          </Container>
        </Box>
      ) : (
        <Flex width={"100%"} justifyContent={"center"}>
          <CircularProgress isIndeterminate color="#00A3C4" size={100} />
        </Flex>
      )}
    </Container>
  );
}

export default ExternalPost;
