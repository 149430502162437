import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { FiEdit3 } from "react-icons/fi";
import { Link } from "react-router-dom";

function DropDownMenu({ item1, item2 }) {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<FiEdit3 size={25} />}
        variant="solid"
        background="#00778F"
        color={"#fff"}
        borderRadius={"50%"}
        w={"50px"}
        h={"50px"}
        _hover={{ background: "#005E70" }}
        _active={{ background: "#0095B3" }}
      />
      <MenuList>
        <Link
          key={item1.title}
          to={item1.url}
          style={{ textDecoration: "none" }}
          _focus={{ boxShadow: "none" }}
          className={"menuLink"}    
        >
          <MenuItem>{item1.title}</MenuItem>
        </Link>
        <MenuItem onClick={()=>item2.action()}>{item2.title}</MenuItem>
      </MenuList>
    </Menu>
  );
}

export default DropDownMenu;
