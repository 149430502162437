import React from "react";
import {
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { useRef } from "react";

function TwoButtonDialog({ btnText, mainText, func1, func2 }) {
  const cancelRef = useRef(null);
  // const { onClose, onOpen, isOpen } = useDisclosure();
  return (
    <>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader className="thirdColor">Warning</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{mainText}.</AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={() => func1()}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={() => func2()} ml={3}>
            {btnText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </>

  );
}

export default TwoButtonDialog;
