import React from "react";
import { Box, Flex, Text, Heading } from "@chakra-ui/layout";
import {
  CircularProgress,
  CircularProgressLabel,
  Progress,
} from "@chakra-ui/react";
import ScoreGuide from "./ScoreGuide";
import PopOver from "./PopOver";

function ScoreCard({ cardCss, score, avgScore, status }) {
  return (
    <Box
      __css={cardCss}
      px={5}
      maxWidth={"500px"}
      height={"-webkit-fit-content"}
      minH={"300px"}
    >
      <Flex
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={3}
      >
        <Heading as={"h3"} fontSize={"20px"}>
          Applicant Score
        </Heading>
        <CircularProgress
          thickness={"10px"}
          size={"100px"}
          color="#00A3C4"
          value={score === 0 ? 0 : score}
          max={20}
        >
          <CircularProgressLabel>
            {status === 0 ? "--" : score}
          </CircularProgressLabel>
        </CircularProgress>
        <Text textAlign={"center"} fontSize={"14px"} color={"#888"}>
          This is the average score by combining every applicants score for this
          position
        </Text>
      </Flex>
      <Flex
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={3}
      >
        <Progress
          backgroundColor={"#bbb"}
          size="md"
          width={"100%"}
          value={avgScore.averageScore}
          max={20}
        />
        <Flex justifyContent={"space-between"} width={"100%"}>
          <Text fontSize={"12px"}>Lowest Score: {avgScore.lowScore}</Text>
          <Text fontSize={"12px"}>
            This applicant Score: {avgScore.averageScore}
          </Text>
          <Text fontSize={"12px"}>Highest Score: {avgScore.highScore}</Text>
        </Flex>
        <Text fontSize={"14px"}>
          This applicant score was compared to the {avgScore.applicantCount}{" "}
          scores available
        </Text>
      </Flex>
      <Flex mt={2} justifyContent={"center"}>
        <PopOver title={"Score guide"}>
          <ScoreGuide />
        </PopOver>
      </Flex>
    </Box>
  );
}

export default ScoreCard;
