export function isNameValid(name) {
    // Minimum and maximum length constraints
    const minLength = 2;
    const maxLength = 50;

    // Regular expression to disallow special characters
    const specialCharactersRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\d]+/;

    // Check if the name is within the length constraints
    if (name.length < minLength || name.length > maxLength) {
      return false;
    }

    // Check if the name contains any special characters
    if (specialCharactersRegex.test(name)) {
      return false;
    }

    return true;
}
export function isCompanyNameValid(name) {
    // Minimum and maximum length constraints
    const minLength = 2;
    const maxLength = 50;

    // Regular expression to disallow special characters
    const specialCharactersRegex = /[!@#$%^&*()+=[\]{};':"\\|,.<>/?]+/;

    // Check if the name is within the length constraints
    if (name.length < minLength || name.length > maxLength) {
      return false;
    }

    // Check if the name contains any special characters
    if (specialCharactersRegex.test(name)) {
      return false;
    }

    return true;
}

export function verifyEmail(email) {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
    if(!emailRegex.test(email)) return false
    return true
  } 

export function verifyPassword(password){
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/
    if(!passwordRegex.test(password)) return false
    return true
  } 