import React from 'react'
import { Flex, Text } from '@chakra-ui/react'

function ScoreGuide() {
  return (
    <Flex fontWeight={'bold'} flexWrap={"wrap"} gap={5} justifyContent={"space-evenly"}>
            <Flex flexDir={"column"} alignItems={"center"}>
              <Text> {"<"}8</Text>
              <Text>Not Suitable</Text>
            </Flex>
            <Flex flexDir={"column"} alignItems={"center"}>
              <Text>8-10 </Text>
              <Text>Underqualified </Text>
            </Flex>
            <Flex flexDir={"column"} alignItems={"center"}>
              <Text>11-12</Text>
              <Text>Qualified</Text>
            </Flex>
            <Flex flexDir={"column"} alignItems={"center"}>
              <Text>13-15</Text>
              <Text>Good Match</Text>
            </Flex>
            <Flex flexDir={"column"} alignItems={"center"}>
              <Text>16-20</Text>
              <Text>Excellent Prospect </Text>
            </Flex>
          </Flex>
  )
}

export default ScoreGuide