import { Icon, Container, Divider, Flex, Text, VStack, Image } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom';
import {
    FiBook,
    FiCpu,
    FiGrid,
    FiBarChart2,
    FiFolder,
  } from 'react-icons/fi';
import { UserAuth } from '../../context/AuthContext';

  function SideBar() {
    const {companyInfo} = UserAuth()
    const LinkItems= [
      { name: 'Dashboard', icon: FiGrid, path: '/', isActive: true, isIncluded:[2,3] },
      { name: 'Screener', icon: FiCpu, path: '/screen', isActive: true, isIncluded:[1,2,3] },
      { name: 'Skill Check', icon: FiBarChart2, path: '/skill', isActive: true, isIncluded:[1,2,3] },
      { name: 'Notes', icon: FiBook, path: '/notes', isActive: true, isIncluded:[1,2,3]},
      { name: 'Job Board', icon: FiFolder, path: '/jobs', isActive: true, isIncluded:[2,3]}
    ];
    const linkbuttons = (elements) =>  {
        return elements.map((item)=>{
            return(
                item.isIncluded.includes(companyInfo?.plan)?
                <Link key={item.name} to={item.isActive?item.path:'/'} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
                    <Flex align="center" px="4" py={'2'} borderRadius="lg" role="group"
                        cursor="pointer" 
                        _hover={{ bg: '#008099', color: 'white', fontWeight: 'bold'}}
                        gap={4}
                        >
                        <Icon fontSize="16" _groupHover={{ color: 'white',}} as={item.icon}/>
                        <Text minW={"80px"} display={{sm:'none', md:'block'}}>{item.name}</Text>
                    </Flex>
                </Link>
                :
                null
                
            )
        })
    }
  return (
    <>
        <Container position={'fixed'} background={'white'} height={'100%'} w={{sm:'100px',md:'180px'}} pt={10} margin={0} className='sidebar'>
            <Flex flexDir={'column'} gap={5} alignItems={'center'}>
                <Link to={'/'}>
                    <Image src='https://uploads-ssl.webflow.com/64f372bf5cb2cbb8370c00fd/6500f8e0204206c9e8e4d598_minilogo.png' height={50}/>
                </Link>
                <Divider></Divider>
            </Flex>
            <VStack m={2} spacing={5}>
                <Flex flexDir={'column'} gap={5}>
                    {linkbuttons(LinkItems)}
                </Flex>          
            </VStack>

        </Container>
    </>
  )
}

export default SideBar