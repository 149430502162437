import React, { useState } from "react";
import { Flex, Box, AlertDialog } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Header from "../navigation/Header";
import SideBar from "../navigation/SideBar";
import { useEffect, useRef } from "react";
import { UserAuth } from "../../context/AuthContext";
import axios from "axios";
import MobileMenu from "../navigation/MobileMenu";
import useScreenSize from "../../hooks/useScreenSize";
import OneButtonDialog from "../Dialog/OneButtonDialog";
import { useDisclosure } from "@chakra-ui/react";


function Home() {
  const { user, setUserInfo, apiURL, logout } = UserAuth();
  const {onOpen, onClose, isOpen} = useDisclosure()
  const [errorText, setErrorText] = useState()
  const cancelRef = useRef(null)
  const localUserInfo = localStorage.getItem("userInfo")? JSON.parse(localStorage.getItem("userInfo")): null
  const myUser = !user? localUserInfo: user

  const closePopUp = () =>{
    setErrorText("")
    onClose()
  }


  const screenSize = useScreenSize();
  useEffect(() => {
    if(!myUser){
      setErrorText("Something went wrong please login again. If this issue persist, contact our support team")
      onOpen()
      logout()
    }
    try {
      axios.get(`${apiURL}/users/findOne?email=${myUser.email}`, {withCredentials:true}).then((res) => {
        setUserInfo(res.data);
      });
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex display={screenSize.width>=1024? "flex": "block"}>
      {screenSize.width >= 1024 ?
      <Box position={"relative"} minW={"180px"}>
        <SideBar />
      </Box>:
      <MobileMenu />
      }
      <Box flex={1} px={{ sm: 4, md: 10 }} pt={"40px"} position={"relative"}>
      {screenSize.width >= 1024 ?
        <Header />:null
      }
        <Outlet />
      </Box>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        onCloseComplete={closePopUp}
      >
        <OneButtonDialog btnText={"Try Again"} mainText={errorText} func1={closePopUp} />
      </AlertDialog>
    </Flex>
  ) 
}

export default Home;
