import '../App.css';
import { UserAuth } from '../context/AuthContext';
import { Flex, CircularProgress } from '@chakra-ui/react';
import { Links } from './navigation/Links';
import { useEffect } from 'react';
import axios from 'axios';

function App() {
  const { isLoading, logout, logged } = UserAuth();
  const userSession = localStorage.getItem("u_SID");
  const plan = localStorage.getItem("plan")
  useEffect(() => {
    if (!userSession) {
      logout();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout, userSession, plan]);

  axios.defaults.headers.common['Authorization'] = localStorage.getItem("u_SID")
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      console.error(error)
      if (error.response) {
        if (error.response.status === 401) logout();
      }
      return Promise.reject(error); // Forward error to be handled by catch block
    }
  );

  if (isLoading) {
    return (
      <Flex width={'100dvw'} height={'100dvh'} justifyContent={'center'} alignItems={'center'}>
        <CircularProgress isIndeterminate color='#00A3C4' size={100} />
      </Flex>
    );
  }

  const myRoutes = Links[plan ? plan : 0];
  const log = Links[0];


  return (
    <div className="App">
      {logged ? myRoutes : log}
    </div>
  );
}

export default App;
