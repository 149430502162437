import React from 'react'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
  } from '@chakra-ui/react'
  import { Button } from '@chakra-ui/react'

function PopOver({title, children}) {
  return (
    <Popover>
        <PopoverTrigger>
            <Button>{title}</Button>
        </PopoverTrigger>
        <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Meaning behind the score</PopoverHeader>
            <PopoverBody>
                {children}
            </PopoverBody>
        </PopoverContent>
    </Popover>
  )
}

export default PopOver