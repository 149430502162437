import React, { createContext, useContext, useState, useEffect } from 'react'
import { auth } from '../firebase'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import Cookies from 'js-cookie';
import axios from 'axios';


const UserContext = createContext()

export const AuthContextProvider = ({children}) =>{
    const [user, setUser] = useState()
    const [userInfo, setUserInfo] = useState()
    const userCookie = Cookies.get('user');
    const [logged, setLogged] = useState(userCookie !== 'null' ? true:false)
    const [authorized, setAuthorized] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [ companyInfo, setCompanyInfo ] = useState()
    const [ compUsers, setCompUsers ] = useState([])
    const apiURL = process.env.REACT_APP_API_NODE_URL

    const logout = async()=>{
        localStorage.removeItem("plan")
        localStorage.removeItem("compInfo")
        localStorage.removeItem("userInfo")
        localStorage.removeItem("u_SID")
        sessionStorage.clear()
        setCompanyInfo(null)
        setLogged(false)
        await signOut(auth)
    }

    const fetchCompanyInfo = async () => {
        try {
          const response = await axios.get(`${apiURL}/companies/${userInfo.company}`, {withCredentials:true});
          setCompanyInfo(response.data);
          if(!localStorage.getItem('plan')||localStorage.getItem('plan')!==response.data.plan){
            localStorage.setItem("plan",response.data.plan)
        }
        
    } catch (error) {
          // Handle the error
        }
    };
    
    
    useEffect(() => {
        if (userInfo) {
            fetchCompanyInfo();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo]);
    
    
    useEffect(()=>{
        const unsubscribe = onAuthStateChanged(auth, (currentUser) =>{
            setUser(()=>currentUser)
            setIsLoading(false)
            if(!localStorage.getItem('plan')&&companyInfo)localStorage.setItem("plan",0)
            
        })
        return()=>{
            unsubscribe()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <UserContext.Provider value={{user, userInfo, setUserInfo, fetchCompanyInfo, logout, authorized, setAuthorized, logged, setLogged, isLoading, setIsLoading, setCompanyInfo, companyInfo, apiURL, compUsers, setCompUsers}}>
            {children}
        </UserContext.Provider>
    )
}

export const UserAuth = () =>{
    return useContext(UserContext)
}