import {
    Flex,Box,FormControl,FormLabel, Input,Text,FormErrorMessage,Select,Button, Stack
} from "@chakra-ui/react";

function AccountCreation({companyNameError, companyEmailError, compIndustryError, compSizeError,companyName,
    companyDomain,companyEmail,setCompanyName,setCompanyDomain,setCompanyEmail,sizeArr, compSize, compIndustry,
     setCompSize,industriesArr,setCompIndustry, handleAccountCreation}) {
  return (
    <Flex flexDir={"column"} gap={5} flexWrap={"wrap"} justifyContent={"center"}>
                <Box>
                  <FormControl id="companyName" isRequired isInvalid={companyNameError}>
                    <FormLabel>Company Name</FormLabel>
                    <Input
                      type="text"
                      placeholder="Company name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                    <FormErrorMessage>{companyNameError}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="companyDomain">
                    <FormLabel>Company Domain</FormLabel>
                    <Input
                      type="text"
                      placeholder="Company domain"
                      value={companyDomain}
                      onChange={(e) => setCompanyDomain(e.target.value)}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="companyEmail" isRequired isInvalid={companyEmailError}>
                    <FormLabel>Company Contact Email</FormLabel>
                    <Input
                      type="text"
                      placeholder="xxx@exemple.com"
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                    />
                    <FormErrorMessage>{companyEmailError}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Flex gap={5} flexWrap={"wrap"}>
                  <FormControl id="compIndustry" isRequired isInvalid={compIndustryError}>
                    <FormLabel>Industry</FormLabel>
                    <Select defaultValue={0}  onChange={(e)=>setCompIndustry(e.target.value)}>
                      <option hidden disabled value={0}>---</option>
                      {industriesArr}
                    </Select>
                    <FormErrorMessage>{compIndustryError}</FormErrorMessage>
                  </FormControl>
                  <FormControl id="compSize" isRequired isInvalid={compSizeError}>
                    <FormLabel>Size</FormLabel>
                    <Flex alignItems={"center"}>
                      <Select width={"80%"} mr={2} defaultValue={0} onChange={(e)=>setCompSize(e.target.value)}>
                        <option hidden disabled value={0}>---</option>
                        {sizeArr}
                      </Select>
                      <Text>Employees</Text>
                    </Flex>
                    <FormErrorMessage>{compSizeError}</FormErrorMessage>
                  </FormControl>
                </Flex>
                <Stack spacing={10} pt={2}>
                <Button loadingText="Submitting" size="lg" className="thirdBackground"
                  bg={"blue.400"} color={"white"} _hover={{bg: "blue.500"}}
                  onClick={()=>{handleAccountCreation()}}>
                  Create company account
                </Button>
              </Stack>
                
              </Flex>
  )
}

export default AccountCreation