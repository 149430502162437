import { Flex, Text, Heading, Divider, Box, Grid } from "@chakra-ui/react";
import React from "react";
import { UserAuth } from "../../context/AuthContext";
import { roles } from "../../data/Constant";

function UserProfile() {
  const { userInfo, companyInfo } = UserAuth();
  return (
    <Flex
      className="card"
      flexDir={"column"}
      w={"70vw"}
      minH={"400px"}
      mx={"auto"}
      my={10}
      py={5}
    >
      <Heading as={"h3"} fontSize={{ sm: "20px", md: "28px" }} ml={5} mb={3}>
        Profile Details
      </Heading>
      <Divider border={"1px solid #00000055"} />
      <Flex flexDir={"column"} my={10} px={20}>
        <Flex justifyContent={"center"} flexDir={{base:"column", md:"row"}} gap={{base:5, md:20}} flexWrap={"wrap"} alignItems={"center"}>
          <Flex gap={{base:5, md:20}} flexDir={{base:"column", md:"row"}} textAlign={'center'}>
            <Text>First Name</Text>
            <Box>
              <Text>{userInfo?.fname !== null ? userInfo?.fname : ""}</Text>
            </Box>
            {/* <Input width={"20ch"} disabled value={userInfo?.fname !== null ?userInfo?.fname : ""} /> */}
          </Flex>
          <Divider
            orientation="vertical"
            height={"20px"}
            borderColor={"#000"}
            opacity={1}
          />
            <Flex gap={{base:5, md:20}} flexDir={{base:"column", md:"row"}} textAlign={'center'}>
              <Text>Middle Name</Text>
              <Box>
                <Text>{userInfo?.mname !== null ? userInfo?.mname : "N/A"}</Text>
              </Box>
              {/* <Input width={"20ch"} disabled value={userInfo?.mname !== null ?userInfo?.mname : ""} /> */}
            </Flex>
          <Divider
            orientation="vertical"
            height={"20px"}
            borderColor={"#000"}
            opacity={1}
          />
          <Flex gap={{base:5, md:20}} flexDir={{base:"column", md:"row"}} textAlign={'center'}>
            <Text>Last Name</Text>
            <Box>
              <Text>{userInfo?.lname !== null ? userInfo?.lname : ""}</Text>
            </Box>
            {/* <Input width={"20ch"} disabled value={userInfo?.lname !== null ?userInfo?.lname : ""} /> */}
          </Flex>
          <Divider
            orientation="vertical"
            height={"20px"}
            borderColor={"#000"}
            opacity={1}
          />
        </Flex>
        <Flex></Flex>
        <Divider my={10} border={"1px solid #00000055"} />
        <Flex flexDir={"column"} gap={5} flex={"wrap"} textAlign={"center"}>
          <Grid
            justifyContent={""}
            gap={2}
            templateColumns={{base: "1fr", md:'.5fr 1fr'}}
            alignItems={"center"}
          >
            <Text>Email</Text>
            <Box>
              <Text wordBreak={{ base: "break-word", md: "unset" }}>
                {userInfo?.email !== null ? userInfo?.email : ""}
              </Text>
            </Box>
            {/* <Input width={"35ch"} disabled value={userInfo?.email !== null ?userInfo?.email : ""} /> */}
          </Grid>
          <Divider
            orientation="horizontal"
            width={"80px"}
            borderColor={"#000"}
            opacity={1}
            m={"auto"}
          />
          <Grid templateColumns={{base: "1fr", md:'.5fr 1fr'}} alignItems={"center"} gap={2}>
            <Text>Company</Text>
            <Box>
              <Text>{companyInfo?.name !== null ? companyInfo?.name : ""}</Text>
            </Box>
            {/* <Input width={"20ch"} disabled value={companyInfo?.name !== null ? companyInfo?.name : ""} /> */}
          </Grid>
          <Divider
            orientation="horizontal"
            width={"80px"}
            borderColor={"#000"}
            opacity={1}
            m={"auto"}
          />
          <Grid templateColumns={{base: "1fr", md:'.5fr 1fr'}} alignItems={"center"} gap={2}>
            <Text>Role</Text>
            <Box>
              <Text>
                {userInfo?.role !== null ? roles[userInfo?.role] : ""}
              </Text>
            </Box>
            {/* <Input width={"20ch"} disabled value={userInfo?.role !== null ? roles[userInfo?.role] : ""} /> */}
          </Grid>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default UserProfile;
