import Profile from '../Profile/Profile';
import CreateJob from '../jobboard/CreateJob.tsx';
import CreateJobDesc from '../jobboard/CreateJobDesc';
import Dashboard from '../dashboard/Dashboard';
import Verification from '../Auth/Verification';
import Screening from '../screener/Screening.tsx';
import Screened from '../screener/Screened';
import Analyse from '../middleware/Analyse';
import Job from '../pages/Job';
import JobBoard from '../jobboard/JobBoard';
import Applicants from '../../containers/jobboard/ApplicantsBoard';
import Notebook from '../notes/NoteBook';
import JobDetails from '../jobboard/JobDetails';
import ExternalPost from '../jobboard/ExternalPost';
import Rounds from '../jobboard/Rounds';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login404 from '../pages/Login404';
import Skill from '../screener/Skill.tsx';
import SignUp from '../Auth/SignUp.jsx';
import NewUser from '../pages/NewUser.jsx';
import Login from '../../containers/Auth/Login.jsx';
import ProfileCreation from '../Auth/ProfileCreation.jsx'
import CompanyAccountSetup from '../Auth/CompanyAccountSetup.jsx'
import Activate from '../pages/Activate.jsx';
import PricingPage from '../dashboard/PricingPage.jsx';
export const Links = { 

  0:[
    <Routes key={1}>
          <Route path='/' element={<Login />}/>
          <Route path='/r/:reason' element={<Login />}/>
          <Route path='/verify' element={<Verification />}/>
          <Route path='/signup' element={<SignUp />} />
          <Route path='/*' element={<Login404 />} />
    </Routes>
  ],
  1:[
    <Routes key={1}>
        <Route path='/' element={<Home />}>
          <Route index element={<Screening />} />
          <Route path='/screened' element={<Screened />} />
          <Route path='/skill' element={<Skill />}/>
          <Route path='/notes' element={<Notebook />} />
          <Route path='/profile' element={<Profile />} />
        </Route>
        <Route path='/analyse' element={<Analyse />}/>
        <Route path='/jobs' element={<Job />}>
          <Route index element={<JobBoard />} />
          <Route path='new_post' element={<CreateJob />} />
          <Route path='post_desc/:id' element={<CreateJobDesc />} />
          <Route path=':job/applicants' element={<Applicants />} />
          <Route path='details/:post' element={<JobDetails />} />
          <Route path='rounds/:job' element={<Rounds />} />
          <Route path='details/:post/external_post' element={<ExternalPost />} />
        </Route>
        <Route path='/screen' element={<Navigate to={'/'} />}/>
        {/* <Route path='/login' element={<Navigate to={'/'} />}/> */}
        <Route path='/*' element={<NotFound />}/>
  </Routes>
  ],
  2:[
    <Routes key={1}>
        <Route path='/' element={<Home />}>
          <Route index element={<Dashboard />} />
          <Route path='/screen' element={<Screening />} />
          <Route path='/screened' element={<Screened />} />
          <Route path='/skill' element={<Skill />}/>
          <Route path='/notes' element={<Notebook />} />
          <Route path='/profile' element={<Profile />} />
        </Route>
        <Route path='/analyse' element={<Analyse />}/>
        <Route path='/jobs' element={<Job />}>
          <Route index element={<JobBoard />} />
          <Route path='new_post' element={<CreateJob />} />
          <Route path='post_desc/:id' element={<CreateJobDesc />} />
          <Route path=':job/applicants' element={<Applicants />} />
          <Route path='details/:post' element={<JobDetails />} />
          <Route path='rounds/:job' element={<Rounds />} />
          <Route path='details/:post/external_post' element={<ExternalPost />} />
        </Route>
        {/* <Route path='/login' element={<Navigate to={'/'} />}/> */}
        <Route path='/*' element={<NotFound />}/>
  </Routes>
  ],
  3:[
    <Routes key={1}>
        <Route path='/' element={<Home />}>
          <Route index element={<Dashboard />} />
          <Route path='/screen' element={<Screening />} />
          <Route path='/screened' element={<Screened />} />
          <Route path='/skill' element={<Skill />}/>
          <Route path='/notes' element={<Notebook />} />
          <Route path='/profile' element={<Profile />} />
        </Route>
        <Route path='/analyse' element={<Analyse />}/>
        <Route path='/jobs' element={<Job />}>
          <Route index element={<JobBoard />} />
          <Route path='new_post' element={<CreateJob />} />
          <Route path='post_desc/:id' element={<CreateJobDesc />} />
          <Route path=':job/applicants' element={<Applicants />} />
          <Route path='details/:post' element={<JobDetails />} />
          <Route path='rounds/:job' element={<Rounds />} />
          <Route path='details/:post/external_post' element={<ExternalPost />} />
        </Route>
        {/* <Route path='/login' element={<Navigate to={'/'} />}/> */}
        <Route path='/*' element={<NotFound />}/>
  </Routes>
  ],
  4:[
    <Routes key={1}>
        <Route path='/' element={<NewUser />}>
          <Route index element={<ProfileCreation />} />
          <Route path='/setAccount' element={<CompanyAccountSetup />} />
        </Route>
        <Route path='/activate' element={<Activate />}/>
        <Route path='/purchase' element={<PricingPage />}/>
        <Route path='/*' element={<NotFound />}/>
  </Routes>
  ],
  
}