import React, { useState } from 'react'
import axios from 'axios'
import { UserAuth } from '../../context/AuthContext'
import { Outlet } from 'react-router-dom'

function NewUser() {
  const [uid, ] = useState(sessionStorage.getItem("signupUid"))
  const {apiURL} = UserAuth()

  if(uid === null){
    axios.get(`${apiURL}/authorized-users/findOne`, {
      headers:{
        'X-Google-Auth-Uuid': uid,
      },
      withCredentials: true
    })
  }

  return (
    <>
      <Outlet />
    </>
  )
}

export default NewUser