import React, { useState, useRef, ChangeEvent, MouseEvent, useEffect } from 'react';
import { Input, FormControl, Container, Box, FormErrorMessage, FormHelperText, Heading,Icon, Textarea, Text, CircularProgress, Flex, Tooltip } from '@chakra-ui/react';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { RiUpload2Fill } from 'react-icons/ri';
import {AiOutlineFilePdf} from 'react-icons/ai'
import { QuestionOutlineIcon } from '@chakra-ui/icons'
// @ts-ignore
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure // This is the correct import location for useDisclosure
} from '@chakra-ui/react'
import { UserAuth } from '../../context/AuthContext';

// The main component for uploading a file
function Screening () {
  // State for the selected file
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorText, setErrorText] = useState('no errors detected')
  const [jobdesc, setJobdesc] = useState('')
  const [dragActive, setDragActive] = useState(false);
  const [limit, setLimit] = useState(0)
  const [count, setCount] = useState(0)
  const { companyInfo, apiURL } = UserAuth()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLElement | null>(null)

  // Hook for navigating to other pages
  const navigate = useNavigate();

  // Ref for the input element
  const inref = useRef<HTMLInputElement | null>(null);

  // Constant for the red color used in the component
  const thirdColor = '#00A3C4';

  // Event handler for when the file input changes
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    
    // Define the maximum allowed file size (e.g., 5MB)
    const maxSize = 2 * 1024 * 1024;
  
    if (file && file.size > maxSize) {
      alert('File size exceeds the allowed limit (2MB).');
    } else {
      setSelectedFile(file);
    }
  }
  const handleDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
    setDragActive(true);
  };
  
  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };
  
  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    setDragActive(false);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setDragActive(false);
    const file = event.dataTransfer.files[0];
    const maxSize = 2 * 1024 * 1024;
  
    if (file && file.size > maxSize) {
      alert('File size exceeds the allowed limit (2MB).');
    } else {
      setSelectedFile(file);
    }
  };

  // Function for uploading the file to the server
  const uploadFile = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if(limit<=count) {
      setErrorText(`Sorry you have reached your monthly single screening limit of ${limit}`)
      onOpen()
      return 0
    }

    // If there is a selected file, proceed with the upload
    if (selectedFile) {
      if(jobdesc.length>100){
        const formData = new FormData();
        formData.append('file', selectedFile);
        
        try { 
          // Send the file to the server
          const response = await axios.post(`${apiURL}/analyse`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
        });

        // Navigate to the next page with the result
        navigate('/analyse', { 
          state: { 
            result: response.data, 
            jobdesc: jobdesc,
            pdfFile: selectedFile,
          } 
        });
      } catch (error) {
        setErrorText('A server error occured plese try again later')
        onOpen()
        
      }
      }
      else{
        setErrorText('You need to enter a job description between 100-700 characters')
        onOpen()
      }
    } else {
      setErrorText('You need to choose a file')
      onOpen()
    }
  };

  const dropZoneStyle = {
    border: dragActive ? `5px dashed ${thirdColor}` : '5px dashed #666',
  };
  const iconDropZoneStyle = {
    color: dragActive ? thirdColor :'',
  };

  // Event handler for when the upload area is clicked
  const uploadbtnMouse = (event: MouseEvent ) => {
    event.preventDefault();
    
    // Trigger the input element's click event
    if (inref.current) {
      inref.current.click();
    }
  };

  useEffect(()=>{
    if(companyInfo){
      const getLimit = async() =>{
        await axios.get(`${apiURL}/a-screen-limits/findOne?companyId=${companyInfo?.id}`, {withCredentials:true}).then((res)=>{
          setCount(res.data?.count)
          setLimit(res.data?.limit)
        })
      }
      getLimit()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[companyInfo])

  return (
    <>
    <Container as='section' margin='0' maxW='100%' display='flex' flexDir='column' justifyContent='center'>
      <Heading 
      as='h1'
      fontSize={{ base:'32' }} 
      mb='2rem'
      className='mainRed'
      >
        Analyse resume
      </Heading>
    <Box  className='sc-upload' 
    m='auto'
    mb={5}
    display='flex' 
    flexDir='column'
    justifyContent='center'
    alignItems='center'
    textAlign='center'
    height='auto'
    >
      <Flex gap={3} mb={10} alignSelf={'flex-end'} border={'2px solid'} padding={1} alignItems={'center'}>
        <Text fontWeight={'bold'}>Screening Count: 
        </Text>
        {limit===0?
          <CircularProgress isIndeterminate size={4} />
          :
          <Text color={limit*0.10>=limit-count?'red':'#000'}>{count}/{limit}</Text>
        }<Tooltip label="The amount of screening you have done and your current limit" aria-label='The amount of screening you have done and your current limit'>
        <QuestionOutlineIcon />
      </Tooltip>
      </Flex>
      <form onSubmit={uploadFile}>
        <FormControl>
          <Box 
          display='flex' 
          flexDir='column'
          justifyContent='center'
          alignItems='center' 
          w={{ base:'90dvw', md:'500px' }} 
          height={{ base:'200px', md:'400px' }} 
          border='5px dashed #666'
          borderRadius='20px'
          marginBottom='40px'
          onClick={uploadbtnMouse}
          as='button'
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          style={dropZoneStyle}
          outlineColor={'#ccc'}
          mx={'auto'}
          >
            <Icon
            as={selectedFile? AiOutlineFilePdf : RiUpload2Fill}
            fontSize= {{base:80, md:150}} 
            color={selectedFile? thirdColor : '#666'}
            style={iconDropZoneStyle}
            />
            <p>{selectedFile? `${selectedFile.name}`:'Click Here or Drag and Drop The File'}</p>
          </Box>

          <Input hidden
          ref={inref}
          type="file" 
          id="file-upload" 
          onChange={handleFileChange} 
          accept={'.pdf, .docx, .doc, .txt'}

          />
          <FormHelperText>Choose a resume to analyse (.pdf .docx .doc .txt files only)*</FormHelperText>
          <FormErrorMessage>You need to choose a file</FormErrorMessage>
        </FormControl>

        <Box mt={5}>
      <Heading as={'h3'} fontSize={'24px'}>Paste  Job description here</Heading>
      <FormControl>
        <Textarea w={{base:"90dvw",md:690}} resize={'none'} borderColor={'#000'} bgColor={'#fff'} fontWeight={'semibold'} height={200}
        value={jobdesc} onChange={(e)=>setJobdesc(e.target.value)}
        maxLength={1400}
        ></Textarea>
        <FormHelperText>Enter a job description between 100-1400 characters</FormHelperText>
      </FormControl>

    </Box>

        <Box mt={4}>
          <Button type="submit" 
          _hover={{background:'#fff', color:thirdColor, border:`1px solid ${thirdColor}`}} 
          _active={{background:'#ccc', color:thirdColor, border:`1px solid ${thirdColor}`}}
          backgroundColor={thirdColor} 
          color='#fff'
          isDisabled={limit<=count?true:false}
          >
            Screen
          </Button>
        </Box>
      </form>

    </Box>
    
    </Container>

    <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader color={thirdColor}>Error</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {errorText}.
          </AlertDialogBody>
          <AlertDialogFooter>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default Screening