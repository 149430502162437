import { useEffect, useRef, useState } from 'react'
import {
    Box,
    Button,
    Container,
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    Flex
  } from '@chakra-ui/react'
  import { signInWithPopup } from 'firebase/auth'
  import { auth, googleProvider } from '../../firebase'
  import GoogleButton from 'react-google-button'
  import { useNavigate } from 'react-router-dom'
import EpSignIn from './EpSignIn'
import Cookies from 'js-cookie'
import { useLocation, useParams } from 'react-router-dom'
import OneButtonDialog from '../Dialog/OneButtonDialog'
import { useDisclosure } from '@chakra-ui/react'
import { AlertDialog } from '@chakra-ui/react'


  function SignIn({log ,setLog}){ 
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [errorText, setErrorText] = useState("")
    const cancelRef = useRef(null)
    
    if (Cookies.get('user')) {
      Cookies.remove('user');
    }
    const location = useLocation()
    const {reason} = useParams()
    const closePopUp = () =>{
      onClose()
      navigate("/", {replace:true})
    }
    useEffect(()=>{
      if(location.state !== null && reason === 'No_USER'){
        if(location.state.profile === false){
          setErrorText(()=>"This profile does not exist, Please Sign up")
          onOpen()
        }
        else if(location.state.profile === true && location.state.account === false){
          setErrorText(()=>"You have not completed you account creation. Please go back to the sign up page and use the same credentials")
          onOpen()
        }
        location.state.profile = null
        location.state.account = null
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
      const navigate = useNavigate()
      const signInWithGoogle = async () => {
        try {
          await signInWithPopup(auth, googleProvider,).then((res)=>{
            navigate('/verify', {state:{uid:res.user.uid, email: res.user.email}})
        });
      } catch (err) {
      }
    };
    return (
    <Container
      maxW="lg"
      py={{
        base: '12',
        md: '24',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <Stack spacing="4">
        <Stack spacing="6">
          <Image alignSelf={'center'} height={'100px'} width ={'100px'} src='https://uploads-ssl.webflow.com/64f372bf5cb2cbb8370c00fd/6500f8e0204206c9e8e4d598_minilogo.png' />
          <Stack
            spacing={{
              base: '2',
              md: '3',
            }}
            textAlign="center"
          >
            <Heading
              size={{
                base: 'xs',
                md: 'sm',
              }}
            >
              Log in to your account
            </Heading>
            <HStack spacing="1" justify="center" flexDir={{base:"column", md:"row"}}>
              <Text color="muted">Don't have an account? email us at</Text>
              <Button as='a' href='mailto:support@emplihire.com' type='' variant="link" colorScheme="blue">
                info@emplihire.com
              </Button>
            </HStack>
          </Stack>
        </Stack>
        <Flex gap={2} flexDir={"row"} flexWrap={"wrap"} justifyContent={"center"}>
          <Text>No account yet?</Text>
          <Button onClick={()=> navigate("/signup")} variant={'link'} className='thirdColor'>Sign up here</Button>
        </Flex>
        <Box
          py={{base: '4',sm: '8',}}
          px={{base: '4',sm: '10',}}
          width={{base:"90dvw", md: "unset"}}
          m={"auto"}
          className='card'
        >
          <Stack spacing="6" alignItems={"center"}>
            <Text textAlign={'center'}>Authenticate using the following Methods</Text>
              <Button onClick={()=>signInWithGoogle()} w={'max-content'} p={'1px'} background={'none'} height={'max-content'}  >
                <GoogleButton label='Sign in With Google' />
              </Button>
          </Stack>
        </Box>
        <Text textAlign={"center"}>Or</Text>
        <Box
          py={{base: '4',sm: '8',}}
          px={{base: '4',sm: '10',}}
          minWidth={{base:"90dvw", md: "400px"}}
          m={"auto"}
          className='card'
        >
          <EpSignIn />
          </Box>
      </Stack>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        onCloseComplete={closePopUp}
      >
        <OneButtonDialog btnText={"Try Again"} mainText={errorText} func1={closePopUp} />
      </AlertDialog>
    </Container>
  )
}

export default SignIn
