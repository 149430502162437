import React from 'react';
import { AgChartsReact } from 'ag-charts-react';
import { Flex } from '@chakra-ui/react';

function PieChartCard(props) {
  const {pieData, pieNoData} = props
  const data = pieData
  

  const numFormatter = new Intl.NumberFormat('en-US');
  const total = data?.length > 0 ? data.reduce((sum, d) => sum + d.count, 0) : 0;

  const options = {
    autoSize: true,
    data,
    title: {
      text: 'Current Open Position',
      fontSize: 18,
      spacing: 25,
    },
    footnote: {
      text: 'Based on datas in your job post',
    },
    series: [
      {
        type: 'pie',
        calloutLabelKey: 'type',
        fillOpacity: 0.9,
        strokeWidth: 0,
        angleKey: 'count',
        sectorLabelKey: 'count',
        calloutLabel: {
          enabled: false,
        },
        sectorLabel: {
          color: 'white',
          fontWeight: 'bold',
          formatter: ({ datum, sectorLabelKey }) => {
            const value = datum[sectorLabelKey];
            return numFormatter.format(value);
          },
        },
        title: {
          text: 'Allocated Budget',
        },
        fills: [
          '#fb7451',
          '#f4b944',
          '#57cc8b',
          '#49afda',
          '#3988dc',
          '#72508c',
          '#b499b5',
          '#b7b5ba',
          '#000000',
          '#00ff7f',
          '#712620',
          '#a72570',
          '#c44601',
        ],
        innerRadiusRatio: 0.5,
        innerLabels: [
          {
            text: 'Total',
            fontSize: 16,
          },
          {
            text: numFormatter.format(total),
            fontSize: 14,
            fontWeight: 'bold',
          },
        ],
        highlightStyle: {
          item: {
            fillOpacity: 0,
            stroke: '#535455',
            strokeWidth: 1,
          },
        },
        tooltip: {
          renderer: ({ datum, calloutLabelKey, title, sectorLabelKey }) => {
            return {
              title,
              content: `${datum[calloutLabelKey]}: ${numFormatter.format(
                datum[sectorLabelKey]
              )}`,
            };
          },
        },
      },
    ],
  };

  return data?.length > 0 ? (
  <AgChartsReact className=".ag-theme-default" options={options} />
  ):(
    <Flex justify={'center'} align={'center'} height={'100%'}>
        {pieNoData}
    </Flex>
  )
}

export default PieChartCard;
