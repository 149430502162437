export const industries = [
	{
		"id" : 1,
		"industry" : "Healthcare"
	},
	{
		"id" : 2,
		"industry" : "Technology"
	},
	{
		"id" : 3,
		"industry" : "Finance"
	},
	{
		"id" : 4,
		"industry" : "Manufacturing"
	},
	{
		"id" : 5,
		"industry" : "Retail"
	},
	{
		"id" : 6,
		"industry" : "Education"
	},
	{
		"id" : 7,
		"industry" : "Real Estate"
	},
	{
		"id" : 8,
		"industry" : "Automotive"
	},
	{
		"id" : 9,
		"industry" : "Hospitality"
	},
	{
		"id" : 10,
		"industry" : "Construction"
	},
	{
		"id" : 11,
		"industry" : "Energy"
	},
	{
		"id" : 12,
		"industry" : "Telecommunications"
	},
	{
		"id" : 13,
		"industry" : "Entertainment"
	},
	{
		"id" : 14,
		"industry" : "Aerospace"
	},
	{
		"id" : 15,
		"industry" : "Pharmaceutical"
	},
	{
		"id" : 16,
		"industry" : "Transportation"
	},
	{
		"id" : 17,
		"industry" : "Insurance"
	},
	{
		"id" : 18,
		"industry" : "Food and Beverage"
	},
	{
		"id" : 19,
		"industry" : "Media"
	},
	{
		"id" : 20,
		"industry" : "Biotechnology"
	},
	{
		"id" : 21,
		"industry" : "E-commerce"
	},
	{
		"id" : 22,
		"industry" : "Consulting"
	},
	{
		"id" : 23,
		"industry" : "Banking"
	},
	{
		"id" : 24,
		"industry" : "Sports"
	},
	{
		"id" : 25,
		"industry" : "Defense"
	},
	{
		"id" : 26,
		"industry" : "Logistics"
	},
	{
		"id" : 27,
		"industry" : "Advertising"
	},
	{
		"id" : 28,
		"industry" : "Environmental"
	},
	{
		"id" : 29,
		"industry" : "Gaming"
	},
	{
		"id" : 30,
		"industry" : "Fashion"
	},
	{
		"id" : 31,
		"industry" : "Travel and Tourism"
	},
	{
		"id" : 32,
		"industry" : "Renewable Energy"
	},
	{
		"id" : 33,
		"industry" : "Architecture"
	},
	{
		"id" : 34,
		"industry" : "Health and Wellness"
	},
	{
		"id" : 35,
		"industry" : "Mining"
	},
	{
		"id" : 36,
		"industry" : "Music"
	},
	{
		"id" : 37,
		"industry" : "Chemical"
	},
	{
		"id" : 38,
		"industry" : "Publishing"
	},
	{
		"id" : 39,
		"industry" : "Fitness"
	},
	{
		"id" : 40,
		"industry" : "Agribusiness"
	},
	{
		"id" : 41,
		"industry" : "Law"
	},
	{
		"id" : 42,
		"industry" : "Consumer Goods"
	},
	{
		"id" : 43,
		"industry" : "Medical Devices"
	},
	{
		"id" : 44,
		"industry" : "Nonprofit"
	},
	{
		"id" : 45,
		"industry" : "Artificial Intelligence"
	},
	{
		"id" : 46,
		"industry" : "Venture Capital"
	},
	{
		"id" : 47,
		"industry" : "Aviation"
	},
	{
		"id" : 48,
		"industry" : "Robotics"
	},
	{
		"id" : 49,
		"industry" : "Social Media"
	},
	{
		"id" : 50,
		"industry" : "Film"
	},
	{
		"id" : 51,
		"industry" : "Space Exploration"
	},
	{
		"id" : 52,
		"industry" : "Information Technology"
	},
	{
		"id" : 53,
		"industry" : "Telecom"
	},
	{
		"id" : 54,
		"industry" : "Internet Services"
	},
	{
		"id" : 55,
		"industry" : "Software Development"
	},
	{
		"id" : 56,
		"industry" : "Financial Services"
	},
	{
		"id" : 57,
		"industry" : "Engineering"
	},
	{
		"id" : 58,
		"industry" : "Biomedical"
	},
	{
		"id" : 59,
		"industry" : "Retail and E-commerce"
	},
	{
		"id" : 60,
		"industry" : "Electronics"
	},
	{
		"id" : 61,
		"industry" : "Hospital and Healthcare Services"
	},
	{
		"id" : 62,
		"industry" : "Automotive Manufacturing"
	},
	{
		"id" : 63,
		"industry" : "Pharmaceuticals"
	},
	{
		"id" : 64,
		"industry" : "Logistics and Supply Chain"
	},
	{
		"id" : 65,
		"industry" : "Oil and Gas"
	},
	{
		"id" : 66,
		"industry" : "Construction and Infrastructure"
	},
	{
		"id" : 67,
		"industry" : "Consumer Electronics"
	},
	{
		"id" : 68,
		"industry" : "Education Services"
	},
	{
		"id" : 69,
		"industry" : "Insurance Services"
	},
	{
		"id" : 70,
		"industry" : "Food and Beverage Production"
	},
	{
		"id" : 71,
		"industry" : "Media and Broadcasting"
	},
	{
		"id" : 72,
		"industry" : "Aerospace and Defense"
	},
	{
		"id" : 73,
		"industry" : "Transportation Services"
	},
	{
		"id" : 74,
		"industry" : "Renewable Energy and Sustainability"
	},
	{
		"id" : 75,
		"industry" : "Architecture and Design"
	},
	{
		"id" : 76,
		"industry" : "Wellness and Fitness"
	},
	{
		"id" : 77,
		"industry" : "Mining and Metals"
	},
	{
		"id" : 78,
		"industry" : "Music and Entertainment"
	},
	{
		"id" : 79,
		"industry" : "Chemical and Materials"
	},
	{
		"id" : 80,
		"industry" : "Publishing and Printing"
	},
	{
		"id" : 81,
		"industry" : "Retail and Fashion"
	},
	{
		"id" : 82,
		"industry" : "Agribusiness and Agriculture"
	},
	{
		"id" : 83,
		"industry" : "Legal Services"
	},
	{
		"id" : 84,
		"industry" : "Healthcare Technology"
	},
	{
		"id" : 85,
		"industry" : "Medical Equipment"
	},
	{
		"id" : 86,
		"industry" : "Nonprofit Organizations"
	},
	{
		"id" : 87,
		"industry" : "Artificial Intelligence and Machine Learning"
	},
	{
		"id" : 88,
		"industry" : "Venture Capital and Investment"
	},
	{
		"id" : 89,
		"industry" : "Aviation and Airlines"
	},
	{
		"id" : 90,
		"industry" : "Robotics and Automation"
	},
	{
		"id" : 91,
		"industry" : "Social Media and Networking"
	},
	{
		"id" : 92,
		"industry" : "Film and Television Production"
	},
	{
		"id" : 93,
		"industry" : "Space Technology and Exploration"
	},
	{
		"id" : 94,
		"industry" : "Digital Marketing"
	},
	{
		"id" : 95,
		"industry" : "Fashion and Apparel"
	},
	{
		"id" : 96,
		"industry" : "Art and Design"
	},
	{
		"id" : 97,
		"industry" : "Government and Public Administration"
	},
	{
		"id" : 98,
		"industry" : "Architecture and Engineering"
	},
	{
		"id" : 99,
		"industry" : "Hospitality and Tourism"
	},
	{
		"id" : 100,
		"industry" : "Consulting Services"
	}
]
