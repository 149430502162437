import React from "react";
import {
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { useRef } from "react";

function OneButtonDialog({alertHeader, btnText, mainText, func1 }) {
  const cancelRef = useRef(null);
  return (
    <>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader className="thirdColor">{!alertHeader?"Warning":alertHeader}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{mainText}.</AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={() => func1()}>
            {btnText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </>

  );
}

export default OneButtonDialog;
