import {
  Box,
  Button,
  Divider,
  Flex,
  Container,
  Heading,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
} from "@chakra-ui/react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { useParams } from "react-router-dom";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";

function Rounds() {
  const [applicants, setApplicants] = useState([]);
  const { userInfo, apiURL } = UserAuth();
  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiURL}/applicants?jobId=${params.job}`, {withCredentials:true}
        );
        const data = response.data;
        setApplicants(data);
      } catch (error) {}
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const changeRound = async (math, key) => {
    await axios
      .patch(`${apiURL}/applicant_status/${applicants[key].id}`, {
        status:
          math === 1 ? applicants[key].status + 1 : applicants[key].status - 1,
      }, {withCredentials:true})
      .then(window.location.reload());
  };

  const Candidates = {
    rounds: [
      "Resume Screening",
      "Phone Interview",
      "Manager Interview",
      "Final Interview",
      "Offer",
    ],
    applicants: applicants,
  };
  const makeCandidates = (applicants, round) => {
    const addCandidate = [];
    for (let i = 0; i < applicants.length; i++) {
      if (applicants[i].status === round) {
        addCandidate.push(
          <Flex
            key={i}
            className="card"
            alignItems="center"
            justifyContent="space-between"
            height="fit-content"
            w="90%"
            my="5"
            mx="auto"
            py={5}
            px={5}
          >
            <Text
              to="../applicants/ok"
              width="50%"
              fontWeight="bold"
              fontSize={14}
            >
              {applicants[i]?.fname} {applicants[i]?.mname}{" "}
              {applicants[i]?.lname}
            </Text>
            <Text color="red" opacity="50%">
              Waiting
            </Text>
            <Box position="relative">
              {/* <Button background='none' height='fit-content' width='fit-content' _active={{background:'#ccc'}} _hover={{background:'#ccc'}}>
                        <Icon fontSize={25} color='#DF6557' as={HiOutlineDotsHorizontal} />
                    </Button> */}
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      background="none"
                      height="fit-content"
                      maxWidth="20px"
                      textAlign="center"
                      _active={{ background: "#ccc" }}
                      _hover={{ background: "#ccc" }}
                      isActive={isOpen}
                      as={Button}
                      rightIcon={
                        <HiOutlineDotsHorizontal
                          margin={0}
                          fontSize={25}
                          color="#DF6557"
                        />
                      }
                    />
                    <MenuList>
                      <MenuGroup title="Move to">
                        {applicants[i]?.status < 4 &&
                        applicants[i]?.status > 0 ? (
                          <MenuItem
                            onClick={(e) => {
                              changeRound(1, i);
                            }}
                          >
                            Next Round
                          </MenuItem>
                        ) : null}
                        {applicants[i].status > 1 ? (
                          <MenuItem
                            onClick={(e) => {
                              changeRound(0, i);
                            }}
                          >
                            Previous Round
                          </MenuItem>
                        ) : null}
                        {applicants[i]?.status < 1 ? (
                          <Text ml={5}>Not screened yet</Text>
                        ) : null}
                      </MenuGroup>
                    </MenuList>
                  </>
                )}
              </Menu>
            </Box>
          </Flex>
        );
      }
    }
    return addCandidate;
  };
  const makeRounds = (position) => {
    const rounds = [];
    for (let i = 0; i < position.rounds.length; i++) {
      const thisRound = i;
      rounds.push(
        <Flex key={i}>
          <Flex
            className="card"
            flexDir="column"
            height="fit-content"
            w="350px"
            py={5}
          >
            <Heading as="h2" fontSize="25px" textAlign="center">
              {position.rounds[i]}
            </Heading>
            <Divider borderColor="#999" width="80%" mx="auto" my="10px" />
            <Box my="10px">
              {makeCandidates(position.applicants, thisRound)}
            </Box>
          </Flex>
        </Flex>
      );
    }
    return rounds;
  };

  return (
    <Container
      as={"section"}
      position={"relative"}
      margin={0}
      width={"100%"}
      maxW={"100%"}
      h={"100%"}
    >
      <Heading className="mainRed" mb={10}>
        Current Interview Rounds
      </Heading>
      <Flex gap={10} flexWrap={"wrap"}>
        {makeRounds(Candidates)}
      </Flex>
    </Container>
  );
}

export default Rounds;
