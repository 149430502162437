import React, { useEffect } from "react";
import { UserAuth } from "../../context/AuthContext";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress, Flex } from "@chakra-ui/react";

function Verification() {
  const { apiURL, setUserInfo, setLogged,setCompanyInfo } = UserAuth();
  const location = useLocation()
  const uid = location.state.uid
  const email = location.state.email
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      try {
        await axios
        .get(`${apiURL}/authorized-users/findOne`, {
          headers:{
            'X-Google-Auth-Uuid': uid,
          },
          withCredentials: true
        })
        .then(async(res) => {
          localStorage.setItem('u_SID', res.data.token)
          try{
           await axios.get(`${apiURL}/users/findOne?email=${res.data.email}`, {
              headers:{
                'Authorization': res.data.token
              },withCredentials: true})
              .then(async (res) => {
                setUserInfo(()=>res.data);
                if (res.data !== null && res.data?.company !== null){
                setUserInfo(()=>res.data);
                setLogged(()=>true);
                const resp = res;
                localStorage.setItem(
                  "userInfo",
                  `{"id":${resp.data.id},"email":"${resp.data.email}","fName":"${resp.data.fname}", "lName":"${resp.data.lname}", "role":"${resp.data.role}", "company":"${resp.data.company}"}`
                );
                const response = await axios.get(
                  `${apiURL}/companies/${res.data.company}`, {headers:{
                    'Authorization': localStorage.getItem('u_SID')
                  } ,withCredentials:true}
                );
                if (
                  !localStorage.getItem("plan") ||
                  localStorage.getItem("plan") !== response.data.plan
                ) {
                  localStorage.setItem("plan", response.data.plan);
                  localStorage.setItem(
                    "compInfo",
                    `{"compId":"${response.data.id}","compEmail":"${response.data.email}","compName":"${response.data.name}"}`
                  )
                }
                if(response.data.plan === 4){
                  setUserInfo(()=>res.data)
                  localStorage.setItem("userEmail", res.data.email)
                }
                setCompanyInfo(()=>response.data);
                navigate("/", {replace: true});
              }
              else{
                localStorage.setItem("plan", 4)
                sessionStorage.setItem('signupEmail', email)
                sessionStorage.setItem('signupUid', uid)
                window.location.href = "/"
              }
          });
        
          } catch(error){
            navigate("/")
          }
        });
      } catch (error) {
        navigate("/r/No_USER", {state: {profile: false, account: false}, replace: true})
      }
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  return (
    <Flex
      width={"100dvw"}
      height={"100dvh"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <CircularProgress isIndeterminate color="#00A3C4" size={100} />
    </Flex>
  );
}

export default Verification;
