import React from "react";
import { Box, Flex, Text, CircularProgress } from "@chakra-ui/react";
import { FiClock, FiUsers } from "react-icons/fi";

import StatsCard from "./StatsCard";
import PieChartCard from "./PieChartCard";
import { UserAuth } from "../../context/AuthContext";

function Metrics(props) {
  const { isLoading } = UserAuth();
  const {
    aPost,
    openPosition,
    hired,
    pieData,
    pieNoData,
    setPieData,
    setPieNoData,
    statsData,
    statsNoData,
    setStatsData,
    setStatsNoData,
  } = props;

  return (
    <>
      <Flex
        justifyContent={"space-between"}
        flexWrap={{ md: "no-wrap", xs: "wrap" }}
        rowGap={10}
      >
        <Flex
          rowGap={5}
          columnGap={5}
          mb={10}
          flexWrap={"wrap"}
          justifyContent={"center"}
        >
          <Flex
            className="card"
            w={"250px"}
            height={"80px"}
            alignItems={"center"}
            gap={6}
            px={5}
          >
            <Flex
              background={"#ccecf3"}
              h={"60px"}
              w={"60px"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"100%"}
            >
              <FiClock size={"40px"} color="#00A3C4" />
            </Flex>
            <Flex flexDir={"column"}>
              <Text>Open Positions</Text>
              {openPosition === null ? (
                <CircularProgress isIndeterminate size={"12px"} />
              ) : (
                <Text fontWeight={"bold"} fontSize={"20px"}>
                  {openPosition}
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex
            className="card"
            w={"250px"}
            height={"80px"}
            alignItems={"center"}
            gap={6}
            px={5}
          >
            <Flex
              background={"#ccecf3"}
              h={"60px"}
              w={"60px"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"100%"}
            >
              <FiUsers size={"40px"} color="#00A3C4" />
            </Flex>
            <Flex flexDir={"column"}>
              <Text>Active Job Posts</Text>
              {aPost === null ? (
                <CircularProgress isIndeterminate size={"12px"} />
              ) : (
                <Text fontWeight={"bold"} fontSize={"20px"}>
                  {aPost}
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex
            className="card"
            w={"250px"}
            height={"80px"}
            alignItems={"center"}
            gap={6}
            px={5}
          >
            <Flex
              background={"#ccecf3"}
              h={"60px"}
              w={"60px"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"100%"}
            >
              <FiUsers size={"40px"} color="#00A3C4" />
            </Flex>
            <Flex flexDir={"column"}>
              <Text>Hired</Text>
              {hired === null ? (
                <CircularProgress isIndeterminate size={"12px"} />
              ) : (
                <Text fontWeight={"bold"} fontSize={"20px"}>
                  {hired}
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex mt={10} flexWrap={"wrap"} justifyContent={"center"} gap={10}>
        <Box className="card" w={{base: "95dvw", md:"700px"}} h={{base:"auto", md:"500px"}} p={5}>
          <StatsCard
            statsData={statsData}
            statsNoData={statsNoData}
            setStatsData={setStatsData}
            setStatsNodData={setStatsNoData}
          />
        </Box>
        <Box className="card" w={{base: "95dvw", md:"800px"}} h={{base:"auto", md:"500px"}} p={5}>
          {isLoading ? (
            <CircularProgress isIndeterminate size={"12px"} />
          ) : (
            <PieChartCard
              pieData={pieData}
              pieNoData={pieNoData}
              setPieData={setPieData}
              setPieNodData={setPieNoData}
            />
          )}
        </Box>
      </Flex>
    </>
  );
}

export default Metrics;
