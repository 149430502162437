import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
// import * as DOMPurify from 'dompurify';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Heading,
  Text,
} from "@chakra-ui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../style/editor.css";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";

//  this code help with previewing the text on the page as normal html elements
// function createMarkup(html) {
//   return {
//     __html: DOMPurify.sanitize(html)
//   }
// }

function CreateJobDesc() {
  const { apiURL } = UserAuth();
  // Job description related useStates
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSavingDesc, setIsSavingDesc] = useState(false);
  const [isSavingDescSum, setIsSavingDescSum] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const [saveJob, setSaveJob] = useState(false);
  const [saveSumJob, setSaveSumJob] = useState(false);
  const [saved, setSaved] = useState({ jobDes: false, desSum: false });

  // Job description related useStates
  const [sumEditorState, setSumEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [sumConvertedContent, setSumConvertedContent] = useState(null);
  const { id } = useParams();

  // Job description related functions
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiURL}/job_desc/${id}`, {withCredentials:true});
        const data = response.data;
        const blocksFromHTML = convertFromHTML(data.jobDescription);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      } catch (error) {}
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
    html = html.replace(/<\/?[^>]+(>|$)/g, "").replace(/\s+/g, "");
    if (html.length > 200) setSaveJob(true);
    else setSaveJob(false);

    if (editorState !== null && editorState !== <p></p>) {
      const beforeUnloading = (event) => {
        event.preventDefault();
      };
      window.addEventListener("beforeunload", beforeUnloading);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloading);
      };
    }
  }, [editorState]);

  const UploadToDb = () => {
    setIsSavingDesc(true);
    axios
      .patch(`${apiURL}/job_desc/${id}`, {
        jobDescription: convertedContent,
      }, {withCredentials:true})
      .then((res) => {
        if (res.status === 204) setIsSavingDesc(false);
        if (saved.jobDes === false)
          setSaved((prev) => ({ ...prev, jobDes: true }));
      })
      .catch((error) => {});
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiURL}/jobs/${id}/jobDescSum`, {withCredentials:true});
        const data = response.data;

        const blocksFromHTML = convertFromHTML(data.jobDescSum);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setSumEditorState(newEditorState);
      } catch (error) {}
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let html = convertToHTML(sumEditorState.getCurrentContent());
    setSumConvertedContent(html);
    html = html.replace(/<\/?[^>]+(>|$)/g, "").replace(/\s+/g, "");
    if (html.length > 200) setSaveSumJob(true);
    else setSaveSumJob(false);

    if (sumEditorState !== null && sumEditorState !== <p></p>) {
      const beforeUnloading = (event) => {
        event.preventDefault();
        event.returnValue = "";
      };
      window.addEventListener("beforeunload", beforeUnloading);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloading);
      };
    }
  }, [sumEditorState]);
  const UploadDescSumToDb = () => {
    setIsSavingDescSum(true);
    axios
      .patch(`${apiURL}/jobs/${id}/jobDescSum`, {
        jobDescSum: sumConvertedContent,
      }, {withCredentials:true})
      .then((res) => {
        setIsSavingDescSum(false);
        if (saved.desSum === false)
          setSaved((prev) => ({ ...prev, desSum: true }));
      })
      .catch((error) => {});
  };
  const generateDescSum = async () => {
    setIsGenerating(true);
    await axios
      .post(`${apiURL}/SumJobDesc`, {
        jobDescription: convertedContent,
      }, {withCredentials:true})
      .then((res) => {
        const data = res.data;
        const blocksFromHTML = convertFromHTML(data.message);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setIsGenerating(false);
        setSumEditorState(newEditorState);
      })
      .catch((error) => {});
  };

  return (
    <Container as={"section"} margin={0} width={"100%"} maxW={"100%"}>
      <Heading as={"h2"}>Create Job Description</Heading>
      <Container
        as={"section"}
        display={"flex"}
        flexDir={"column"}
        gap={5}
        my={10}
        className="editorCont"
      >
        <Text textAlign={"center"}>Add a description to this job</Text>
        <Text textAlign={"center"}>
          **Note: You need to save before leaving this page or any modification
          will be lost
        </Text>
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
        />
        <Text textAlign={"center"}>
          We recommend entering a minimum of 200 character, and above 850 for an
          optimum result form the Ai model
        </Text>
        <ButtonGroup>
          <Button
            className="thirdBackground"
            m={"auto"}
            isDisabled={saveJob ? false : true}
            onClick={() => UploadToDb()}
          >
            {isSavingDesc ? (
              <CircularProgress isIndeterminate size={"20px"} />
            ) : (
              "Save Description"
            )}
          </Button>
        </ButtonGroup>
      </Container>
      <Container
        as={"section"}
        display={"flex"}
        flexDir={"column"}
        gap={5}
        my={10}
        className="editorCont"
      >
        <Text textAlign={"center"}>
          Below is a summary of your job description to which the the candidate
          resume will be compare to
        </Text>
        <Text textAlign={"center"}>
          **Note: You need to save before leaving this page or any modification
          will be lost
        </Text>
        <Editor
          editorState={sumEditorState}
          onEditorStateChange={setSumEditorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
        />
        <Text textAlign={"center"}>
          You can manually modify the summary provided by the Ai
        </Text>
        <ButtonGroup
          flexDir={{ base: "column", md: "row" }}
          rowGap={{ base: 2, md: "unset" }}
          alignItems={"center"}
        >
          <Button
            colorScheme="orange"
            m={"auto"}
            onClick={() => generateDescSum()}
          >
            {isGenerating ? (
              <CircularProgress isIndeterminate size={"20px"} />
            ) : (
              "Generate Summary"
            )}
          </Button>
          <Button
            className="thirdBackground"
            m={"auto"}
            isDisabled={saveSumJob ? false : true}
            onClick={() => UploadDescSumToDb()}
          >
            {isSavingDescSum ? (
              <CircularProgress isIndeterminate size={"20px"} />
            ) : (
              "Save Summary"
            )}
          </Button>
          <Link to={`/jobs/details/${id}`}>
            <Button
              cursor={"pointer"}
              colorScheme="green"
              m={"auto"}
              isDisabled={
                saved.jobDes === true && saved.desSum === true ? false : true
              }
            >
              Done
            </Button>
          </Link>
        </ButtonGroup>
      </Container>
    </Container>
  );
}

export default CreateJobDesc;
