import React, { useEffect, useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import { Flex, Box } from "@chakra-ui/react";
import { CircularProgress, AlertDialog} from "@chakra-ui/react";
import SideBar from "../navigation/SideBar";
import Header from "../navigation/Header";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";
import MobileMenu from "../navigation/MobileMenu";
import useScreenSize from "../../hooks/useScreenSize";
import OneButtonDialog from "../Dialog/OneButtonDialog";
import { useDisclosure } from "@chakra-ui/react";

function Job() {
  const { user, setUserInfo, isLoading, apiURL, compUsers, setCompUsers, logout} = UserAuth();
  const {onOpen, onClose, isOpen} = useDisclosure()
  const [errorText, setErrorText] = useState()
  const cancelRef = useRef(null)
  const localUserInfo = localStorage.getItem("userInfo")? JSON.parse(localStorage.getItem("userInfo")): null
  const myUser = !user? localUserInfo: user
  const screenSize = useScreenSize();
  
  const closePopUp = () =>{
    setErrorText("")
    onClose()
    logout()
  }
  
  useEffect(() => {
    if(!myUser){
      setErrorText("Something went wrong please login again. If this issue persist, contact our support team")
      onOpen()
    }
    try {
      axios.get(`${apiURL}/users/findOne?email=${myUser.email}`, {withCredentials:true}).then((res) => {
        setUserInfo(res.data);
        if (compUsers.length === 0) {
          const fetchData = async () => {
            try {
              const getCompUsers = await axios.get(
                `${apiURL}/users/findAll?company=${res.data?.company}`, {withCredentials:true}
              );
              setCompUsers(getCompUsers.data);
            } catch (error) {}
          };

          fetchData();
        }
      });
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  window.addEventListener("load", (event) => {});
  return (
    <Flex display={screenSize.width>=1024? "flex": "block"}>
      {screenSize.width >= 1024 ?
      <Box position={"relative"} minW={"180px"}>
        <SideBar />
      </Box>:
      <MobileMenu />
      }
      <Box flex={1} px={{ sm: 4, md: 10 }} pt={"40px"} position={"relative"}>
      {screenSize.width >= 1024 ?
        <Header />:null
      }
        {isLoading ? (
          <CircularProgress isIndeterminate color="#00A3C4" size={100} />
        ) : (
          <Outlet />
        )}
      </Box>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        onCloseComplete={closePopUp}
      >
        <OneButtonDialog btnText={"Try Again"} mainText={errorText} func1={closePopUp} />
      </AlertDialog>
    </Flex>
  )
}

export default Job;
