import React from 'react'
import {Card, CardBody, Text, Stack, CardFooter, ButtonGroup, Button ,Heading, Divider, Box} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

function Cards(props) {
    const dateString = props.ptd;
    const date = new Date(dateString);
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  return (
    <Card 
    minW='2xs' 
    maxW= {{sm:'xs'}}
    minH={'325px'}
    className='card'
    my={'10px'}
    mx={'5px'}
    >
        <CardBody>
            <Heading size='md' textAlign={'center'} maxH={'2.5em'} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>{props.title}</Heading>
           
            <Stack mt='6' spacing='3'>
            <Text textAlign={'center'}>
                Location: {props.city}, {props.region}, {props.country}
            </Text>

            <Box  display={{md:'block',lg:'flex'}} textAlign={'center'} gap={3} flexWrap={'wrap'} justifyContent={'center'}>
            <Text>
                Target: {props.target}
            </Text>
            {/* <Text>
                Interviewing: {props.interviewing}
            </Text> */}
            </Box>
            {/* <Text textAlign={'center'}>
                Application: {props.aReceived}
            </Text> */}
            <Text textAlign={'center'}>
                Posted Date: {formattedDate}
            </Text>
            {props.isExternal===null?null:<Text textAlign={'center'}>
                Hiring for: {props?.isExternal?"External":"Internal"}
            </Text>}
            {/* {props.status ==='archive'? <Text>Closed Date: 05/20/2023</Text>:<></>} */}
            </Stack>
        </CardBody>
        <Divider maxW={'80%'} m={'auto'}/>
        <CardFooter>
            <ButtonGroup spacing='2' margin={'auto'} display={'flex'} flexDir={'column'} alignItems={'center'} gap={3}>
            <Link to={`./details/${props.id}`}>
                <Button variant='solid' className='thirdBackground'>
                    View
                </Button>
            </Link>

            {props.status ==='active'
            ?<Button variant='solid' colorScheme='red' onClick={()=> props.func(props.id)}>
             Close Post
            </Button>
            :null}
            </ButtonGroup>
        </CardFooter>
        </Card>
  )
}

export default Cards