import React, { useState, } from 'react';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Button, Input, FormControl,
  FormLabel, 
  // FormErrorMessage,
  // FormHelperText, 
} from '@chakra-ui/react';

function EpSignIn() {
  const navigate = useNavigate();
//
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password).then((res)=>{
        navigate('/verify', {state:{uid:res.user.uid, email: res.user.email}})
      });
      // You can get user information from userCredential.user
      // Set user information in context or state, e.g., setUserInfo(userCredential.user)
      // navigate('/verify', { state: { uid: userCredential.user.uid } });
    } catch (error) {
      // Handle error
      // test
    }
  };

  return (
    <form onSubmit={handleSignIn} className='signInForm'>
      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input  type="email" value={email} autoComplete='email' onChange={(e) => setEmail(e.target.value)} />
      </FormControl>
      <FormControl>
        <FormLabel>Password</FormLabel>
        <Input  type="password" value={password} autoComplete='current-password' onChange={(e) => setPassword(e.target.value)} />
      </FormControl>
        <Button colorScheme='blackAlpha' background={'#21808C'} type="submit">Sign In</Button>
    </form>
  );
}

export default EpSignIn;
