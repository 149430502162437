import { React, useEffect, useState } from "react";
import {
  Container,
  Text,
  Heading,
  Flex,
  HStack,
  CircularProgress,
  CircularProgressLabel,
  Grid,
} from "@chakra-ui/react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Divider,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../style/Screening.css";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";

function Screened() {
  const {apiURL, companyInfo} = UserAuth()
  const location = useLocation();
  const navigate = useNavigate()
  const result = location.state?.result.message;
  const jobDesc = location.state?.description;
  const pdfFile = location.state?.pdfFile;

  const [score, setScore] = useState();
  // eslint-disable-next-line no-unused-vars
  const [jobFit, setJobFit] = useState();

  const getJobFit = () => {
    axios
      .post(`${apiURL}/jobfit`, {
        summary: result,
        description: jobDesc,
      }, {withCredentials:true})
      .then((res) => {
        const messageData = JSON.parse(res.data.message);
        setScore(messageData.nScore);
        setJobFit(messageData.Reason);
        sessionStorage.setItem(
          `${pdfFile.name}`,
          `{"nScore":"${messageData.nScore}", "Reason":"${messageData.Reason}"}`
        );
      }).then(()=>{
        axios.patch(`${apiURL}/a-screen-limits/increment-count/${companyInfo?.id}`, {}, {withCredentials: true})
      })
  };
  useEffect(() => {
    // const handleJobFit = () =>{
    if(!pdfFile){
      navigate("/screen", {
        replace: true,
      });
    }else{

      const sessionSt = sessionStorage.getItem(`${pdfFile?.name}`);
      if (!sessionSt) getJobFit();
      else {
        const json = JSON.parse(sessionSt);
        setScore(json.nScore);
        setJobFit(json.Reason);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const data = [
    {
      label: "Summary",
      content: `${location.state?.result.message}`,
    },
    {
      label: "Job fit",
      content: jobFit,
    },
  ];

  // 3. Pass the props and chill!

  return (
    <>
      <Container as="section" className="sc-pageTitle" ml={0}>
        <Heading
          as="h1"
          fontSize={{ base: "32" }}
          mb="2rem"
          className="mainRed"
        >
          {" "}
          Screened
        </Heading>
      </Container>
      <HStack
        alignItems="flex-start"
        flexWrap={{ sm: "wrap", xl: "nowrap" }}
        justifyContent={"center"}
        gap={5}
        py={10}
      >
        <Grid
          as="section"
          className="sc-cont"
          minWidth="50%"
          maxWidth="50%"
          width="50%"
          gap={20}
        >
          <Flex direction="column" alignItems="center" className="Score">
            <Heading as="h2" className="mainRed">
              Compatibility Score
            </Heading>
            <Divider
              orientation="horizontal"
              h={2}
              w="60%"
              mt="5"
              mb="10"
              color="#fff"
            />
            <CircularProgress
              value={score}
              max="20"
              size={100}
              trackColor="#fff"
              color="#00A3C4"
              thickness="10"
            >
              <CircularProgressLabel color={"#000"} fontWeight={"bold"}>
                {score && score ? score : "--"}/20
              </CircularProgressLabel>
            </CircularProgress>
            <Text>
              Base on the resume and the Job details this candidate scores a{" "}
              {score && score ? score : "--"}/20
            </Text>
          </Flex>

          <Tabs isManual variant="enclosed">
            <TabList>
              {data.map((tab, index) => (
                <Tab
                  ml={5}
                  marginRight=""
                  _selected={{ background: "#00A3C4", color: "#fff" }}
                  background="#fff"
                  fontWeight="bold"
                  key={index}
                >
                  {tab.label}
                </Tab>
              ))}
            </TabList>
            <TabPanels
              className="card"
              background="#fff"
              borderRadius="0 10px 10px 10px"
              minH="100px"
            >
              {data.map((tab, index) => (
                <TabPanel p={10} key={index}>
                  <Text
                    fontSize="18px"
                    maxH="30ch"
                    overflowY="scroll"
                    css={{
                      "&::-webkit-scrollbar": {
                        width: "10px",
                      },
                      "&::-webkit-scrollbar-track": {
                        width: "14px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: "#00A3C4",
                        borderRadius: "24px",
                      },
                    }}
                  >
                    {tab.content}
                  </Text>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Grid>
        {/* <object
          data={URL.createObjectURL(pdfFile)}
          width="100%"
          height="1020px"
          type="application/pdf"
        >
          <p>
            Something went wrong... The PDF can not be displayed. Please refresh
            the page
          </p>
        </object> */}
      </HStack>
    </>
  );
}

export default Screened;
