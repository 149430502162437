import React, {useState , useRef, ChangeEvent, MouseEvent, useEffect} from 'react'
import { Container, Box, Heading, FormControl, FormLabel, FormHelperText, CircularProgress, Input, Icon, Button, Text, Flex, useDisclosure, Tooltip, } from '@chakra-ui/react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react'
import { RiUpload2Fill } from 'react-icons/ri';
import {AiOutlineFilePdf} from 'react-icons/ai'
import axios from 'axios'
import SkillDetails from './SkillDetails';
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { UserAuth } from '../../context/AuthContext';

function Skill() {
  const {companyInfo, apiURL} = UserAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [addSkillM, setAddSkillM] = useState('')
  const [addSkillI, setAddSkillI] = useState('')
  const [addSkillO, setAddSkillO] = useState('')
  const [skillsM, setSkillsM] = useState<string[]>([]);
  const [skillsI, setSkillsI] = useState<string[]>([]);
  const [skillsO, setSkillsO] = useState<string[]>([]);
  const [jobJson, setJobJson] = useState<object>()
  const [candidateJson, setCandidateJson] = useState<object>()
  const [screened, setScreened] = useState(false)
  const [limit, setLimit] = useState(0)
  const [count, setCount] = useState(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ json, setJson ] = useState<object>({ "must": skillsM.length>0?skillsM:[],
  
  "important": skillsI.length>0?skillsI:[],
  "nice": skillsO.length>0?skillsO:[],})

  const [errors, setErrors] = useState({
    skillCheck:'',
    skillsM:'',
    skillsI:'',
    file:'',
  });
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLElement | null>(null)

  useEffect(()=>{
    const getLimit = async() =>{
      await axios.get(`${apiURL}/a-screen-limits/findOne?companyId=${companyInfo?.id}`, {withCredentials:true}).then((res)=>{
        setCount(res.data?.kCount)
        setLimit(res.data?.kLimit)
      })
    }
    getLimit()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[companyInfo])

  const addnewSkill = (skill,array, setSkill) =>{
    if(skill.length>0){
      array.push(skill)
      setSkill('')
    }
    setJson({ "must": skillsM.length>0?skillsM:[], 
    "important": skillsI.length>0?skillsI:[],
    "nice": skillsO.length>0?skillsO:[],})
  }

  const removeSkill = (array, value, setSkill) => {
    const updatedArray = array.filter(skill => skill !== value);
    setSkill(updatedArray);
    setJson(prevalue =>({}))
  };


  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorText, setErrorText] = useState('no errors detected')
  const [dragActive, setDragActive] = useState(false);

  


  // Ref for the input element
  const inref = useRef<HTMLInputElement | null>(null);

  // Constant for the red color used in the component
  const thirdColor = '#00A3C4';

  // Event handler for when the file input changes
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    // Define the maximum allowed file size (e.g., 5MB)
    const maxSize = 2 * 1024 * 1024;

    if (file && file.size > maxSize) {
      alert('File size exceeds the allowed limit (2MB).');
    } else {
      setSelectedFile(file);
    }
  }
  const handleDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    setDragActive(false);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setDragActive(false);

    const file = event.dataTransfer.files[0];
    const maxSize = 2 * 1024 * 1024;

    if (file && file.size > maxSize) {
      alert('File size exceeds the allowed limit (2MB).');
    }else if(file.type !== "application/pdf"){
      alert('Please only select PDF files only')
    } else {
      setSelectedFile(file);
    }
  };
  const dropZoneStyle = {
    border: dragActive ? `5px dashed ${thirdColor}` : '5px dashed #666',
  };
  const iconDropZoneStyle = {
    color: dragActive ? thirdColor :'',
  };
  const uploadbtnMouse = (event: MouseEvent ) => {
    event.preventDefault();
    
    // Trigger the input element's click event
    if (inref.current) {
      inref.current.click();
    }
  };

  const startScreen = async () => {
    setIsLoading(true)
    if(limit<=count) {
      setIsLoading(false)
      setErrorText(`Sorry you have reached your monthly single screening limit of ${limit}`)
      onOpen()
      return 0
    }
    let isValid = true
    // If there is a selected file, proceed with the upload
      if (skillsM.length === 0) {
        setErrors((prevState) => ({ ...prevState, skillsM: 'You must enter at least 1 Must Have skills' }));
        isValid = false;
      }else{setErrors((prevState) => ({ ...prevState, skillsM: '' }))}
      if (skillsI.length === 0) {
        setErrors((prevState) => ({ ...prevState, skillsI: 'You must enter at least 1 Important Have skills' }));
        isValid = false;
      }else{setErrors((prevState) => ({ ...prevState, skillsI: '' }))}
      if(!selectedFile){
        setErrors((prevState) => ({ ...prevState, file: 'You must choose a file to screen' }));
        isValid = false;
      }else{setErrors((prevState) => ({ ...prevState, file: '' }))}
    if(isValid && selectedFile){
      const jsonSkill = {
        "must": skillsM, 
        "important": skillsI,
        "nice": skillsO.length>0?skillsO:[], 
      }
        const formData = new FormData();
        formData.append('file', selectedFile);
        
        try { 
          // Send the file to the server
          
          const res = await axios.post(`${apiURL}/analyse`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          });
          await axios.post(`${apiURL}/skillscheck`, {
            text: res.data[1],
            skills: jsonSkill,
          }, {withCredentials:true}).then((res)=>{
            setJobJson(jsonSkill)
            setCandidateJson(res.data)
            if(res.status === 200){
              setIsLoading(false)
              setScreened(true)
              setIsLoading(false)
              axios.patch(`${apiURL}/a-screen-kLimits/increment-count/${companyInfo?.id}`,{}, {withCredentials:true})
              setCount(prev => prev + 1)
              window.scroll(0,1000)
            }
            }).catch(()=>{
            setIsLoading(false)
            })
          

      } catch (error) {
        setIsLoading(false)
        setErrorText('A server error occured plese try again later')
        onOpen()
        
      }

    }else {
      setIsLoading(false)
      if(!selectedFile) setErrorText('You need to choose a file')
      else setErrorText('You are missing certain informations')
      onOpen()
    }
  };

    
    return (
      <>
      <Container as={"section"} margin={0} width={"100%"} maxW={"100%"}>
          <Heading as={"h2"} fontSize={{ sm: "25px", md: "32px" }}>
            Skills Search
          </Heading>
          <Box my={10}>
          <Heading as={'h3'} fontSize={'20px'}>Add Keyword resume filtering</Heading>
          <Flex gap={3} mb={10} alignSelf={'flex-end'} border={'2px solid'} padding={1} alignItems={'center'} width={'max-content'}>
          <Text fontWeight={'bold'}>Screening Count: 
          </Text>
          {limit===0?
            <CircularProgress isIndeterminate size={4} />
            :
            <Text color={limit*0.10>=limit-count?'red':'#000'}>{count}/{limit}</Text>
          }<Tooltip label="The amount of screening you have done and your current limit" aria-label='The amount of screening you have done and your current limit'>
          <QuestionOutlineIcon />
        </Tooltip>
        </Flex>
        <Flex maxW={'100%'} flexWrap={'wrap'} justifyContent={'center'} gap={5}>
          <FormControl mt="2%"  maxW={'500px'} className='card' py={5}>
            <FormLabel fontWeight={'normal'} textAlign={'center'}>
            Must Have Skills<span>*</span>
            </FormLabel>
            {errors.skillsM && <Text color="red" textAlign={'center'}>{errors.skillsM}</Text>}
            <Flex justifyContent={'center'}>
            <Input width={'20ch'} value={addSkillM} onChange={(e)=>setAddSkillM(e.target.value)} onKeyDown={(e)=>e.key==='Enter'?addnewSkill(addSkillM,skillsM,setAddSkillM):null}></Input>
            <Button className='thirdBackground' onClick={()=>addnewSkill(addSkillM,skillsM,setAddSkillM)}>Add</Button>
            </Flex>
            <Flex minH={'100px'} w={'70%'} justifyContent={'center'} mt={10} mb={5} mx={'auto'} borderRadius={10} flexWrap={'wrap'} gap={2} >
            {skillsM.map((skill)=>{
              return(
                <Text onClick={()=>removeSkill(skillsM,skill,setSkillsM)} key={skill} height={'4ch'} className='skillBubble mustSkill tooltip'>
                  {skill}
                  <span className="tooltiptext">remove</span>
                </Text>
              )
            })}
            </Flex>
          </FormControl>
          <FormControl mt="2%" maxW={'500px'} className='card' py={5}>
            <FormLabel fontWeight={'normal'} textAlign={'center'} >
            Important Skills<span>*</span>
            </FormLabel>
            {errors.skillsI && <Text color="red" textAlign={'center'}>{errors.skillsI}</Text>}
            <Flex justifyContent={'center'}>
            <Input width={'20ch'} value={addSkillI} onChange={(e)=>setAddSkillI(e.target.value)} onKeyDown={(e)=>e.key==='Enter'?addnewSkill(addSkillI,skillsI,setAddSkillI):null}></Input>
            <Button className='thirdBackground' onClick={()=>addnewSkill(addSkillI,skillsI,setAddSkillI)}>Add</Button>
            </Flex>
            <Flex minH={'100px'} w={'200px'}  mt={10} mb={5} mx={'auto'} borderRadius={10} flexWrap={'wrap'} gap={2} >
            {skillsI.map((skill)=>{
              return(
                <Text onClick={()=>removeSkill(skillsI,skill,setSkillsI)} key={skill} height={'4ch'} className='skillBubble mustSkill tooltip'>
                  {skill}
                  <span className="tooltiptext">remove</span>
                </Text>
              )
            })}
            </Flex>
          </FormControl>
          <FormControl mt="2%" maxW={'500px'} className='card' py={5}>
            <FormLabel fontWeight={'normal'} textAlign={'center'}>
            Nice To Have Skills
            </FormLabel>
            <Flex justifyContent={'center'}>
            <Input width={'20ch'} value={addSkillO} onChange={(e)=>setAddSkillO(e.target.value)} onKeyDown={(e)=>e.key==='Enter'?addnewSkill(addSkillO,skillsO,setAddSkillO):null}></Input>
            <Button className='thirdBackground' onClick={()=>addnewSkill(addSkillO,skillsO,setAddSkillO)}>Add</Button>
            </Flex>
            <Flex minH={'100px'} w={'200px'}  mt={10} mb={5} mx={'auto'} borderRadius={10} flexWrap={'wrap'} gap={2} >
            {skillsO.map((skill)=>{
              return(
                <Text onClick={()=>removeSkill(skillsO,skill,setSkillsO)} key={skill} height={'4ch'} className='skillBubble mustSkill tooltip'>
                  {skill}
                  <span className="tooltiptext">remove</span>
                </Text>
              )
            })}
            </Flex>
          </FormControl>
          <FormControl my={5}>
            <FormLabel fontSize={'20px'} color={'#888'} textAlign={'center'}>
              Upload the resume
            </FormLabel>
            {errors.file && <Text color="red" textAlign={'center'}>{errors.file}</Text>}
            <Box 
            display='flex' 
            flexDir='column'
            justifyContent='center'
            alignItems='center' 
            w={{ base:'300px', md:'500px' }} 
            height={{ base:'200px' }} 
            border='5px dashed #666'
            borderRadius='20px'
            marginBottom='40px'
            onClick={uploadbtnMouse}
            as='button'
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={dropZoneStyle}
            _hover={{border:'5px dashed #008099 !important'}}
            outlineColor={'#ccc'}
            mx={'auto'}
            >
              <Icon
              as={selectedFile? AiOutlineFilePdf : RiUpload2Fill}
              fontSize= {150} 
              color={selectedFile? thirdColor : '#666'}
              style={iconDropZoneStyle}
              />
              <p>{selectedFile? `${selectedFile.name}`:'Click Here or Drag and Drop The File'}</p>
            </Box>

            <Input hidden
            ref={inref}
            type="file" 
            id="file-upload" 
            onChange={handleFileChange} 
            accept=".pdf"
            />
            <FormHelperText textAlign={'center'}>Choose a resume to analyse (pdf files only)*</FormHelperText>
          </FormControl>
          <Button
            isDisabled={limit<=count?true:false} 
            onClick={()=>startScreen()}
            _hover={{background:'#fff', color:thirdColor, border:`1px solid ${thirdColor}`}} 
            _active={{background:'#ccc', color:thirdColor, border:`1px solid ${thirdColor}`}}
            backgroundColor={thirdColor} 
            color='#fff'

            >
            {isLoading?<CircularProgress isIndeterminate size={"15px"} />:screened?'Screen again':'Start Screening'}
          </Button>
        </Flex>
          </Box>
          {isLoading?
          <Box textAlign={'center'}>
            <CircularProgress isIndeterminate size={100} color='#008099'/>
          </Box>:
          null}
          {!screened||isLoading?null:<SkillDetails jobJson={jobJson} info={candidateJson} />}
      </Container>

      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader color={thirdColor}>Error</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {errorText}.
          </AlertDialogBody>
          <AlertDialogFooter>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      </>
    )
}

export default Skill