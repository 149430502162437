/* eslint-disable react-hooks/rules-of-hooks */
import React, {  useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  Text,
  CircularProgress,
  Checkbox,
  Icon,
  useDisclosure
} from '@chakra-ui/react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import axios from 'axios';
import { UserAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import {FiMinusCircle} from 'react-icons/fi'
import { AlertDialog } from '@chakra-ui/react';
import OneButtonDialog from '../Dialog/OneButtonDialog';




export default function CreateJob() {
  const {userInfo, companyInfo, apiURL} = UserAuth()
  const [ wait, setWait] = useState(false)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const cancelRef = useRef(null)
  const [warningText, setWarningText] = useState("")


  interface Department {
    id: number;
    depName: string;
  }
  interface Manager {
    id: number;
    fname: string;
    lname: string;
  }



  useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiURL}/departments`, {withCredentials:true});
        const compUsers = await axios.get(`${apiURL}/users/findAll?company=${userInfo?.company}`, {withCredentials:true});
        setDepData(response.data);
        setTeam(compUsers?.data)
        
      } catch (error) {
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ userInfo])


  const navigate = useNavigate()
  const [verifySkills, setVerifySkill] = useState([])
  const [addSkillM, setAddSkillM] = useState('')
  const [addSkillI, setAddSkillI] = useState('')
  const [addSkillO, setAddSkillO] = useState('')
  const [skillsM, setSkillsM] = useState<string[]>([]);
  const [skillsI, setSkillsI] = useState<string[]>([]);
  const [skillsO, setSkillsO] = useState<string[]>([]);
  const [depData, setDepData] = useState<Department[]>([])
  const [jtitle, setJtitle] = useState('')
  const [hTarget, setHTarget] = useState('')
  const [rBudget, setRBudget] = useState('')
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [ department, setDepartment] = useState('')
  const [ team, setTeam] = useState<Manager[]>([])
  const [ manager, setManager] = useState('')
  const [ recruiter, setRecruiter] = useState('')
  const [isExternal, setIsExternal] = useState(companyInfo?.isExternal)
  const [ inter, setInter] = useState('')
  const [ round, setRound] = useState('')
  const [ roundList, setRoundList] = useState({'round':[['Skill search','AI'],['Resume screening','AI']]})
  const [fbdate, setFbDate] = useState('');
  const[skillSearch] = useState<boolean>(true)
  const today = new Date()
  const [ json, setJson ] = useState<object>({})
  const month = today.getMonth() + 1; // getMonth() returns 0-indexed values, so add 1 to get the actual month number
  const day = today.getDate();
  const year = today.getFullYear();
  const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
  const [errors, setErrors] = useState({
    jtitle: '',
    hTarget: '',
    rBudget: '',
    country: '',
    region: '',
    city: '',
    department: '',
    manager: '',
    recruiter: '',
    fbdate: '',
    skillCheck:'',
    skillsM:'',
    skillsI:'',
  });
  useEffect(()=>{setIsExternal(companyInfo?.isExternal)},[companyInfo])
  const resetSkill = () =>{
      setJson({})
      setSkillsM([])
      setSkillsI([])
      setSkillsO([])
      setAddSkillM('')
      setAddSkillI('')
      setAddSkillO('')

  }
  const addRound = (roundName,roundInt, setRound, setRoundN) =>{
    if(roundName.length>3){
    if(roundInt.length>=3){
      const updatedRoundList = { ...roundList }
      updatedRoundList['round'].push([roundName, roundInt]);
      setRoundList(updatedRoundList)
      setRound('')
      setRoundN('')
    }
  }
}

  const removeRound = (roundName, setRoundList, index) =>{
    const updatedRoundList = { ...roundList };

    // Remove the key-value pair with the specified roundName
    updatedRoundList['round'].splice(index,1)

    // Update the state with the new object
    setRoundList(updatedRoundList);
  }
  
  const addnewSkill = (skill, array, setSkill, verifySkills) =>{
    if(verifySkills.includes(skill)){
      setWarningText("Do not enter duplicate skills")
       onOpen()
    }
    else{
      if(skill.length>0){
        array.push(skill)
        verifySkills.push(skill)
        setSkill('')
      }
      
      setJson({ "must": skillsM.length>0?skillsM:[], 
      "important": skillsI.length>0?skillsI:[],
      "nice": skillsO.length>0?skillsO:[],})
    }
  }

  const removeSkill = (array, value, setSkill, verifySkills) => {
    const updateVerif = verifySkills.filter(skill => skill !== value);
    const updatedArray = array.filter(skill => skill !== value);
    setVerifySkill(updateVerif)
    setSkill(updatedArray);
    setJson(prevalue =>({}))
  };


  useEffect(()=>{
    setJson({ "must": skillsM.length>0?skillsM:[], 
    "important": skillsI.length>0?skillsI:[],
    "nice": skillsO.length>0?skillsO:[],})
  },[skillsI,skillsM,skillsO])

  
  
  const handleSubmit = () => {
    let isValid = true;
    
    if (jtitle.length===0) {
      setErrors((prevState) => ({ ...prevState, jtitle: 'A job title is required' }));
      isValid = false;
    }else{setErrors((prevState) => ({ ...prevState, jtitle: '' }))}

    if (!hTarget) {
      setErrors((prevState) => ({ ...prevState, hTarget: 'You need to set a hiring target' }));
      isValid = false;
    }else{setErrors((prevState) => ({ ...prevState, hTarget: '' }))}
    
    if (!rBudget) {
      setErrors((prevState) => ({ ...prevState, rBudget: 'You need to give a rough budget' }));
      isValid = false;
    }else{setErrors((prevState) => ({ ...prevState, rBudget: '' }))}

    if (!country) {
      setErrors((prevState) => ({ ...prevState, country: 'Country is required' }));
      isValid = false;
    }else{setErrors((prevState) => ({ ...prevState, country: '' }))}

    if (!region) {
      setErrors((prevState) => ({ ...prevState, region: 'Region is required' }));
      isValid = false;
    }else{setErrors((prevState) => ({ ...prevState, region: '' }))}
    if (!city) {
      setErrors((prevState) => ({ ...prevState, city: 'City is required' }));
      isValid = false;
    }else{setErrors((prevState) => ({ ...prevState, city: '' }))}
    
    if (!department) {
      setErrors((prevState) => ({ ...prevState, department: 'Department field is required' }));
      isValid = false;
    }else{setErrors((prevState) => ({ ...prevState, department: '' }))}

    if (manager === '') {
      setErrors((prevState) => ({ ...prevState, manager: 'You need to select a manager' }));
      isValid = false;
    }else{setErrors((prevState) => ({ ...prevState, manager: '' }))}

    if (recruiter === '') {
      setErrors((prevState) => ({ ...prevState, recruiter: 'You need to select a recruiter' }));
      isValid = false;
    }else{setErrors((prevState) => ({ ...prevState, recruiter: '' }))}

    if (!fbdate) {
      setErrors((prevState) => ({ ...prevState, fbdate: 'Fill by date is required' }));
      isValid = false;
    }else{setErrors((prevState) => ({ ...prevState, fbdate: '' }))}
    
    if (skillSearch) {
    if (skillsM.length === 0) {
      setErrors((prevState) => ({ ...prevState, skillsM: 'Must enter Must Have skills' }));
      isValid = false;
    }else{setErrors((prevState) => ({ ...prevState, skillsM: '' }))}
    if (skillsI.length === 0) {
      setErrors((prevState) => ({ ...prevState, skillsI: 'Must enter Important Have skills' }));
      isValid = false;
    }else{setErrors((prevState) => ({ ...prevState, skillsI: '' }))}
    }else{setErrors((prevState) => ({ ...prevState, fbdate: '' }))}

    if (isValid) {
      if(skillSearch){
        const updatedJson = {
          "must": skillsM.length>0?skillsM:[], 
          "important": skillsI.length>0?skillsI:[],
          "nice": skillsO.length>0?skillsO:[], 
        }
        setJson(updatedJson)
      }
      // If all fields are valid, submit the form or perform any additional actions
      setWait(true)
      sendApp()
    }
  };
  const sendApp = async() =>{
    

      try { 
        // Send the file to the server
        const res = await axios.post(`${apiURL}/jobs`, {
        "company":userInfo.company,
        "title": jtitle,
        "department":parseInt(department),
        "hManager":parseInt(manager),
        "recruiter":parseInt(recruiter),
        "jobDescription": ' ',
        "jobDescSum": ' ',
        "hTarget": parseInt(hTarget),
        "active": 1,
        "rBudget": parseInt(rBudget),
        "country": country,
        "region": region,
        "city": city,
        "fbdate":  fbdate,
        "json": json,
        "rounds": roundList,
        "isExternal": isExternal,
       }, {withCredentials:true});
       if(res.status===200){
      setTimeout(() => {
        navigate(`../post_desc/${res.data.id}`)
      }, 2000);
    }

    
    }catch (error) {
    }

  }
 
  const handleCountryChange = (value) => {
    setCountry(value);
  };
  
  const handleRegionChange = (value) => {
    setRegion(value);
  };
  return (
      <>
      {wait?
        <Flex height={'95dvh'} justifyContent={'center'} alignItems={'center'} >
        <CircularProgress isIndeterminate color='#00A3C4' size={100} />
      </Flex>
      :
      
      <Box
        borderWidth="1px"
        background={'#fff'}
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        maxWidth={800}
        p={6}
        m="10px auto"
        as="form">

        {/* Start Main form code */}

        <Heading w="100%" my={5} textAlign={'center'} fontWeight="normal">
        New Job Post
      </Heading>
      <Flex flexDir={"column"} rowGap={5}>
      <Flex>
        <FormControl mr="5%">
          <FormLabel htmlFor="job-title" fontWeight={'normal'}>
            Job Title<span>*</span>
          </FormLabel>
          <Input isRequired id="job-title" placeholder="Enter the title for this position" value={jtitle} onChange={(e)=>setJtitle(e.target.value)} />
          {errors.jtitle && <Text color="red">{errors.jtitle}</Text>}
        </FormControl>
      </Flex>

      <Flex>
        <FormControl mt="2%" display={'flex'} alignItems={'center'}>
          <FormLabel htmlFor="hTarget" fontWeight={'normal'}>
            Hiring Target<span>*</span>:
          </FormLabel>
          <Input isRequired id="hTarget" type="number" placeholder='ex: 2' textAlign={'center'} maxWidth={'70px'} value={hTarget} onChange={(e)=>setHTarget(e.target.value)} />
          {errors.hTarget && <Text color="red">{errors.hTarget}</Text>}
        </FormControl>
        
        <FormControl mt="2%" display={'flex'} alignItems={'center'}>
          <FormLabel htmlFor="rBudget" fontWeight={'normal'}>
            Rough Budget<span>*</span>:
          </FormLabel>
          <Input isRequired id="rBudget" type="number" placeholder='85000$' textAlign={'center'} maxWidth={'100px'} value={rBudget} onChange={(e)=>setRBudget(e.target.value)} />
          {errors.rBudget && <Text color="red">{errors.rBudget}</Text>}
        </FormControl>
        
      </Flex>

      <Flex flexWrap={'wrap'}>
      <FormControl as={GridItem} colSpan={[6, 3]}>
        <FormLabel
          htmlFor="country"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: 'gray.50',
          }}>
          Country / Region<span>*</span>
        </FormLabel>
        {/* eslint-disable-next-line react/prop-types */}
        <CountryDropdown
        value={country}
        onChange={handleCountryChange}
        classes={'chakra-input css-r8f0h4'}
        
        />
        {errors.country && <Text color="red">{errors.country}</Text>}
      </FormControl>

      <FormControl as={GridItem} colSpan={[6, 6, null, 2]}>
        <FormLabel
          htmlFor="city"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: 'gray.50',
          }}
          mt="2%">
          State / Province / Region<span>*</span>
        </FormLabel>
        <RegionDropdown
        // style={{minWidth:'300px', boxShadow:'0 1px 2px 0 rgba(0, 0, 0, 0.05)', outline: '2px solid transparent'}}
        classes='chakra-input css-r8f0h4'
        country={country}
        value={region}
        onChange={handleRegionChange}
        disableWhenEmpty
        />
        {errors.region && <Text color="red">{errors.region}</Text>}
      </FormControl>

      <FormControl as={GridItem} colSpan={[6, 3, null, 2]}>
        <FormLabel
          htmlFor="state"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: 'gray.50',
          }}
          mt="2%">
          City<span>*</span>
        </FormLabel>
        <Input
        isRequired
        type="text"
        name="state"
        id="state"
        autoComplete="state"
        focusBorderColor="brand.400"
        shadow="sm"
        size="sm"
        w="full"
        rounded="md"
        width={'300px'}
        placeholder='Enter City'
        onChange={(e)=>setCity(e.target.value)}
        />
        {errors.city && <Text color="red">{errors.city}</Text>}
      </FormControl>
      </Flex>

      
      <FormControl mt="2%">
        <FormLabel htmlFor="email" fontWeight={'normal'}>
          Which department is this position for ? <span>*</span>
        </FormLabel>
        <Select id="department" maxWidth={'500px'} defaultValue={department} onChange={(e)=>setDepartment(e.target.value)} isRequired>
          <option value="" disabled hidden>--</option>
          {depData.map((dep)=>(
            <option key={dep.id} value={dep.id}>
            {dep.depName}
          </option>
          ))}
        </Select>
        {errors.department && <Text color="red">{errors.department}</Text>}
      </FormControl>
      
      
      <FormControl mt="2%">
        <FormLabel htmlFor="manager" fontWeight={'normal'}>
          Hiring manager<span>*</span>
        </FormLabel>
        <Select id="manager" maxWidth={'200px'} defaultValue={manager} onChange={(e)=>setManager(e.target.value)} isRequired>
          <option value="" disabled hidden>--</option>
          {team.map((hman)=>(
            <option key={hman.id} value={hman.id}>
            {`${hman?.fname} ${hman.lname}`}
          </option>
          ))}
        </Select>
        {errors.manager && <Text color="red">{errors.manager}</Text>}
      </FormControl>

      <FormControl mr="5%">
          <FormLabel htmlFor="recruiter" fontWeight={'normal'}>
            Recruiter<span>*</span>
          </FormLabel>
          {/* <Input isDisabled id="recruiter" width={'300px'} placeholder="" value={`${userInfo?.fname} ${userInfo?.lname}`} /> */}
          <Select id="recruiter" maxWidth={'200px'} defaultValue={recruiter} onChange={(e)=>setRecruiter(e.target.value)} isRequired>
          <option value="" disabled hidden>--</option>
          {team.map((recruit)=>(
            <option key={recruit.id} value={recruit.id}>
            {`${recruit?.fname} ${recruit.lname}`}
          </option>
          ))}
        </Select>
        {errors.recruiter && <Text color="red">{errors.recruiter}</Text>}
        </FormControl>
        <FormControl mt="2%">
        <FormLabel htmlFor="isExternal" fontWeight={'normal'}>
          Is This an External hiring <span>*</span>
        </FormLabel>
        <Checkbox name='external' isChecked={isExternal} onChange={(e)=> setIsExternal(e.target.checked)} />
      </FormControl>
      <FormControl mr="5%">
          <FormLabel htmlFor="recruiter" fontWeight={'normal'}>
            Interviewing rounds<span>*</span>
          </FormLabel>
          <Flex gap={5} flexWrap={'wrap'}>
            <Input  className="round-numb" width={'200px'} placeholder="Round name" value={round} onChange={(e)=>setRound(e.target.value)} />
            <Input  className="round-inter" width={'300px'} placeholder="Interviewer name" onChange={(e)=>setInter(e.target.value)} value={inter} />
          </Flex>
          <Button colorScheme='facebook' my={3} onClick={()=>addRound(round, inter,setRound,setInter)}>Add Round</Button>
        </FormControl>
        <Flex columnGap={2} rowGap={5} flexWrap={'wrap'}>
            {roundList['round'].map(([rname, rInt], index)=>{
              return(
          <Flex key={index} alignItems={'center'} background={'#eee'} borderLeft={'4px solid #ccc'} padding={5} gap={2}>
              <Text fontWeight={'bold'}>{rname}: {rInt}</Text>
              {index > 1 ?<Button padding={0} background={'red.400'} _hover={{background:"red.500"}} _active={{background:"red.800"}}
              onClick={()=>removeRound(rname,setRoundList,index)}
              >
              <Icon as={FiMinusCircle} color={'#fff'} />
              </Button>
              :
              null}
          </Flex>
              )
            })}
      </Flex>

      <FormControl mt="2%">
        <FormLabel htmlFor="startDate" fontWeight={'normal'}>
          Fill this position by <span>*</span>
        </FormLabel>
        <Input id='startDate' type='date' width={'200px'} min={formattedDate} isRequired value={fbdate} onChange={(e)=>{setFbDate(e.target.value)}}/>
        {errors.fbdate && <Text color="red">{errors.fbdate}</Text>}
      </FormControl>

      {skillSearch?
      <Flex flexDir={'column'}>
      <Flex flexDir={{base:"column", md:"row"}}>
        <FormControl mt="2%" >
          <FormLabel htmlFor="startDate" fontWeight={'normal'}>
          Must Have Skills<span>*</span>
          </FormLabel>
          {errors.skillsM && <Text color="red">{errors.skillsM}</Text>}
          <Flex>
          <Input width={'15ch'} value={addSkillM} onChange={(e)=>setAddSkillM(e.target.value)} onKeyDown={(e)=>e.key==='Enter'?addnewSkill(addSkillM,skillsM,setAddSkillM,verifySkills):null}></Input>
          <Button colorScheme='facebook' onClick={()=>addnewSkill(addSkillM,skillsM,setAddSkillM, verifySkills)}>Add</Button>
          </Flex>
          <Flex minH={'100px'} w={'200px'}  mt={10} mb={5} borderRadius={10} flexWrap={'wrap'} gap={2} >
          {skillsM.map((skill)=>{
            return(
              <Text onClick={()=>removeSkill(skillsM,skill,setSkillsM, verifySkills)} key={skill} height={'4ch'} className='skillBubble mustSkill tooltip'>
                {skill}
                <span className="tooltiptext">remove</span>
              </Text>
            )
          })}
          </Flex>
        </FormControl>
        <FormControl mt="2%">
          <FormLabel htmlFor="startDate" fontWeight={'normal'}>
          Important Skills<span>*</span>
          </FormLabel>
          {errors.skillsI && <Text color="red">{errors.skillsI}</Text>}
          <Flex>
          <Input width={'15ch'} value={addSkillI} onChange={(e)=>setAddSkillI(e.target.value)} onKeyDown={(e)=>e.key==='Enter'?addnewSkill(addSkillI,skillsI,setAddSkillI,verifySkills):null}></Input>
          <Button colorScheme='facebook' onClick={()=>addnewSkill(addSkillI,skillsI,setAddSkillI, verifySkills)}>Add</Button>
          </Flex>
          <Flex minH={'100px'} w={'200px'}  mt={10} mb={5} borderRadius={10} flexWrap={'wrap'} gap={2} >
          {skillsI.map((skill)=>{
            return(
              <Text onClick={()=>removeSkill(skillsI,skill,setSkillsI, verifySkills)} key={skill} height={'4ch'} className='skillBubble mustSkill tooltip'>
                {skill}
                <span className="tooltiptext">remove</span>
              </Text>
            )
          })}
          </Flex>
        </FormControl>
        <FormControl mt="2%">
          <FormLabel htmlFor="startDate" fontWeight={'normal'}>
          Nice To Have Skills<span>*</span>
          </FormLabel>
          <Flex>
          <Input width={'15ch'} value={addSkillO} onChange={(e)=>setAddSkillO(e.target.value)} onKeyDown={(e)=>e.key==='Enter'?addnewSkill(addSkillO,skillsO,setAddSkillO,verifySkills):null}></Input>
          <Button colorScheme='facebook' onClick={()=>addnewSkill(addSkillO,skillsO,setAddSkillO,verifySkills)}>Add</Button>
          </Flex>
          <Flex minH={'100px'} w={'200px'}  mt={10} mb={5} borderRadius={10} flexWrap={'wrap'} gap={2} >
          {skillsO.map((skill)=>{
            return(
              <Text onClick={()=>removeSkill(skillsO,skill,setSkillsO, verifySkills)} key={skill} height={'4ch'} className='skillBubble mustSkill tooltip'>
                {skill}
                <span className="tooltiptext">remove</span>
              </Text>
            )
          })}
          </Flex>
        </FormControl>
      </Flex>
        <Button colorScheme={'red'} 
        isDisabled={skillsM.length===0 && skillsI.length===0 && skillsO.length===0?true:false} 
        onClick={()=>resetSkill()}>Reset skills</Button>
      </Flex>
      
      :null}
        

        {/* End Main form code */}

      <Flex justifyContent={'center'} my={10} >
        <Button
          w="7rem"
          className='thirdBackground'
          variant="solid"
          onClick={()=>handleSubmit()}
        >
          Submit
        </Button>
        </Flex>
        </Flex>
      </Box>}
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <OneButtonDialog
          btnText={"Close"}
          mainText={warningText}
          func1={onClose}
        />
      </AlertDialog>
    </>
  );
}