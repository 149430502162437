import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Container,
  Divider,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import {
  FiDollarSign,
  FiClock,
  FiActivity,
  FiUsers,
  FiInbox,
} from "react-icons/fi";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure, // This is the correct import location for useDisclosure
} from "@chakra-ui/react";
import { useRef } from "react";
import { departments } from "../../data/Departments";
import { highRoles } from "../../data/Constant";
import DropDownMenu from "../menu/DropDownMenu";

function JobDetails() {
  const { userInfo, apiURL } = UserAuth();
  const [infoLoad, setInfoLoad] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [jobDetail, setJobDetail] = useState();
  const [dep, setDep] = useState();
  const [aReceived, setAReceived] = useState(0);
  const [appInt, setAppInt] = useState(0);
  const [dateL, setDateL] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [btnText, setBtnText] = useState("");
  const [alertFunc, setAlertFunc] = useState(null);
  const cancelRef = useRef(null);
  const [errorText, setErrorText] = useState("no errors detected");
  const [skills, setSkills] = useState({});

  const getJob = async () => {
    await axios
      .get(`${apiURL}/jobs/${params.post}/${userInfo?.company}`, {withCredentials:true})
      .then((res) => {
        const job = res.data;
        if (res.data === null) {
          navigate("/jobs");
          return;
        }
        if (
          highRoles.includes(userInfo?.role) ||
          job.hManager === userInfo.id ||
          job.recruiter === userInfo.id
        ) {
          fetchData(res);
        } else {
          navigate("/jobs");
        }
      })
      .catch((error) => {
        navigate("/jobs");
      });
  };

  const fetchData = async (response) => {
    response = response.data;
    setInfoLoad(true);
    try {
      setDep(departments[response.department - 1].depName);
      const appCount = await axios.get(`${apiURL}/applicants/count?`, {
        params: {
          jobId: params.post,
        },
        withCredentials: true
      });
      const appInt = await axios.get(`${apiURL}/applicants/countInt?`, {
        params: {
          jobId: params.post,
        },
        withCredentials: true
      });

      setAppInt(appInt.data.count);
      setJobDetail(response);
      setSkills(response.json);
      const check = Date.parse(response?.fbdate);
      const today = Date.parse(new Date());
      if (check < today) {
        setDateL(0);
      } else {
        setDateL(Math.ceil((check - today) / 86400000));
      }

      setAReceived(appCount.data.count);
      if (response?.aReceived !== appCount.data.count) {
        axios.patch(`${apiURL}/job/updateapp/${params.post}`, {
          aReceived: appCount.data.count,
        }, {withCredentials:true});
      }
    } catch (error) {
      //   navigate("/jobs");
    }
    setInfoLoad(false);
  };
  useEffect(() => {
    if (userInfo?.id) {
      getJob();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);
  const handleClose = () => {
    setErrorText("Are you sure you want to close this post?");
    setBtnText("Close");
    setAlertFunc(0);
    onOpen();
  };
  useEffect(() => {
    if (appInt !== jobDetail?.interviewing) {
      axios
        .patch(`${apiURL}/jobs/${params.post}`, {
          interviewing: appInt,
        }, {withCredentials:true})
        .catch((error) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInt]);

  const handleConfirmClose = async () => {
    await axios.patch(`${apiURL}/jobs/${params.post}`, {
      active: 0,
    }, {withCredentials:true});
    onClose();
    window.location.reload();
  };
  const handleConfirmReopen = async () => {
    await axios.patch(`${apiURL}/jobs/${params.post}`, {
      active: 1,
    }, {withCredentials:true});
    onClose();
    window.location.reload();
  };
  const date = new Date(jobDetail?.addedDate);
  const formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;

  const customScrollbarStyles = {
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#00A3C4",
      borderRadius: "5px",
    },
  };

  const buildSkills = (skills) => {
    return Object.keys(skills).map((skillCategory) => {
      return (
        <Flex flexDir={"row"} flexWrap={"wrap"} gap={2} key={skillCategory}>
          {skills[skillCategory].map((skill) => {
            return (
              <Text className="skillBubble" key={skill}>
                {skill}
              </Text>
            );
          })}
        </Flex>
      );
    });
  };
  return (
    <Container
      as={"section"}
      position={"relative"}
      margin={0}
      width={"100%"}
      maxW={"100%"}
      h={"100%"}
    >
      <Heading as={"h2"}>Job Post Details</Heading>
      <Box as="section" mt={5}>
        <Flex justifyContent={"end"} mb={5}>
          <DropDownMenu
            item1={{
              type: "link",
              title: "Edit description",
              url: `/jobs/post_desc/${params.post}`,
            }}
            item2={{ type: "button", title: "Close post", action: handleClose }}
          />
        </Flex>
        <Flex
          rowGap={5}
          columnGap={5}
          mb={10}
          flexWrap={"wrap"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {jobDetail?.isExternal ? null : (
            <Flex
              className="card"
              w={"250px"}
              height={"80px"}
              alignItems={"center"}
              gap={6}
              px={5}
            >
              <Flex
                background={"#ccecf3"}
                h={"60px"}
                w={"60px"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={"100%"}
              >
                <FiDollarSign size={"40px"} color="#00A3C4" />
              </Flex>
              <Flex flexDir={"column"}>
                <Text>Budget</Text>
                {infoLoad ? (
                  <CircularProgress isIndeterminate color="#00A3C4" size={5} />
                ) : (
                  <Text fontWeight={"bold"} fontSize={"20px"}>
                    {jobDetail?.rBudget}$
                  </Text>
                )}
              </Flex>
            </Flex>
          )}
          <Flex
            className="card"
            w={"250px"}
            height={"80px"}
            alignItems={"center"}
            gap={6}
            px={5}
          >
            <Flex
              background={"#ccecf3"}
              h={"60px"}
              w={"60px"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"100%"}
            >
              <FiClock size={"40px"} color="#00A3C4" />
            </Flex>
            <Flex flexDir={"column"}>
              <Text>Time Left</Text>
              {infoLoad ? (
                <CircularProgress isIndeterminate color="#00A3C4" size={5} />
              ) : (
                <Text
                  fontWeight={"bold"}
                  fontSize={"20px"}
                  color={dateL === 0 ? "red.400" : "#000"}
                >
                  {dateL} days
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex
            className="card"
            w={"250px"}
            height={"80px"}
            alignItems={"center"}
            gap={6}
            px={5}
          >
            <Flex
              background={"#ccecf3"}
              h={"60px"}
              w={"60px"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"100%"}
            >
              <FiUsers size={"40px"} color="#00A3C4" />
            </Flex>
            <Flex flexDir={"column"}>
              <Text>Total Applicants</Text>
              {infoLoad ? (
                <CircularProgress isIndeterminate color="#00A3C4" size={5} />
              ) : (
                <Text fontWeight={"bold"} fontSize={"20px"}>
                  {aReceived}
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex
          my={5}
          gap={10}
          flexWrap={"wrap"}
          width={"90%"}
          justify={"center"}
        >
          <Box
            minH={"230px"}
            maxH={"230px"}
            w={"500px"}
            className="card"
            px={10}
            py={10}
          >
            <Heading as={"h3"} fontSize={"24px"}>
              {jobDetail?.title}
            </Heading>
            <br />
            <Flex gap={2}>
              <Text>
                <b>Location</b> -{" "}
              </Text>
              {infoLoad ? (
                <CircularProgress isIndeterminate color="#00A3C4" size={5} />
              ) : (
                <Text>
                  {jobDetail?.city}, {jobDetail?.country}
                </Text>
              )}
            </Flex>

            <Flex gap={2}>
              <Text>
                <b>Department</b> -
              </Text>
              {infoLoad ? (
                <CircularProgress isIndeterminate color="#00A3C4" size={5} />
              ) : (
                <Text>{dep}</Text>
              )}
            </Flex>
            {/* <Text><b>Recruiter</b> - {jobDetail?.recruiter}</Text> */}
            <Flex gap={2}>
              <Text>
                <b>Posted Date</b> -
              </Text>
              {infoLoad ? (
                <CircularProgress isIndeterminate color="#00A3C4" size={5} />
              ) : (
                <Text>{formattedDate}</Text>
              )}
            </Flex>
          </Box>
          <Flex
            minH={"180px"}
            maxH={{ base: "auto" }}
            w={"600px"}
            className="card"
            px={10}
            py={10}
            justifyContent={"space-around"}
            alignItems={"center"}
            position={"relative"}
            flexWrap={{ base: "wrap", md: "nowrap" }}
          >
            <Flex flexDir={"column"} alignItems={"center"} gap={2}>
              <Text fontWeight={"bold"}>Rejected</Text>
              <CircularProgress
                max={infoLoad ? 0 : aReceived}
                value={infoLoad ? 0 : jobDetail?.rejected}
                thickness={"10px"}
                size={"100px"}
                color="red.500"
              >
                <CircularProgressLabel fontWeight={"bold"}>
                  {infoLoad ? "--" : jobDetail?.rejected}
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
            <Flex flexDir={"column"} alignItems={"center"} gap={2}>
              <Text fontWeight={"bold"}>Interviewing</Text>
              <CircularProgress
                max={infoLoad ? 0 : aReceived}
                value={infoLoad ? 0 : appInt}
                thickness={"10px"}
                size={"100px"}
                color="#00A3C4"
              >
                <CircularProgressLabel fontWeight={"bold"}>
                  {infoLoad ? "--" : appInt}
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
            <Flex flexDir={"column"} alignItems={"center"} gap={2}>
              <Text fontWeight={"bold"}>Waiting</Text>
              <CircularProgress
                max={infoLoad ? 0 : aReceived}
                value={
                  infoLoad
                    ? 0
                    : aReceived -
                      (appInt + jobDetail?.hired + jobDetail?.rejected)
                }
                thickness={"10px"}
                size={"100px"}
                color="orange.400"
              >
                <CircularProgressLabel fontWeight={"bold"}>
                  {infoLoad
                    ? "--"
                    : aReceived -
                      (appInt + jobDetail?.hired + jobDetail?.rejected)}
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
            <Flex flexDir={"column"} alignItems={"center"} gap={2}>
              <Text fontWeight={"bold"}>
                {jobDetail?.isExternal ? "Screened" : "Hired"}
              </Text>
              <CircularProgress
                max={infoLoad ? 0 : aReceived}
                value={infoLoad ? 0 : jobDetail?.hired}
                thickness={"10px"}
                size={"100px"}
                color={"green"}
              >
                <CircularProgressLabel fontWeight={"bold"}>
                  {infoLoad ? "--" : jobDetail?.hired}
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
          </Flex>
          <Box
            className="card"
            minH={"180px"}
            w={"600px"}
            gridRowStart={1}
            gridRowEnd={3}
            gridColumnStart={2}
            gridColumnEnd={4}
            whiteSpace={"pre-line"}
            px={6}
            py={5}
            h={"400px"}
            overflowY={"scroll"}
            __css={customScrollbarStyles}
          >
            <Heading as="h3" fontSize={"24px"} mb={5}>
              Job Description
            </Heading>
            {infoLoad ? (
              <Flex width={"100%"} justifyContent={"center"}>
                <CircularProgress isIndeterminate color="#00A3C4" size={100} />
              </Flex>
            ) : (
              <Text
                dangerouslySetInnerHTML={{ __html: jobDetail?.jobDescription }}
              ></Text>
            )}
          </Box>
          <Box
            className="card"
            minH={"180px"}
            w={"600px"}
            gridRowStart={1}
            gridRowEnd={3}
            gridColumnStart={2}
            gridColumnEnd={4}
            whiteSpace={"pre-line"}
            px={6}
            py={5}
            h={"400px"}
            overflowY={"scroll"}
            __css={customScrollbarStyles}
          >
            <Heading as="h3" fontSize={"24px"} mb={5}>
              Job summary
            </Heading>
            {infoLoad ? (
              <Flex width={"100%"} justifyContent={"center"}>
                <CircularProgress isIndeterminate color="#00A3C4" size={100} />
              </Flex>
            ) : (
              <Text
                dangerouslySetInnerHTML={{
                  __html:
                    jobDetail?.jobDescSum === "none"
                      ? "no summary available yet"
                      : jobDetail?.jobDescSum,
                }}
              ></Text>
            )}
          </Box>
          <Box className="card" w={"500px"} minH={"300px"} p={5}>
            <Heading as={"h4"} fontSize={"20px"}>
              {" "}
              Searching for
            </Heading>
            <Box className="skillContainer">{buildSkills(skills)}</Box>
          </Box>
          <Flex
            className="card"
            h={"180px"}
            maxWidth={"100%"}
            mb={5}
            gridColumnStart={1}
            gridColumnEnd={4}
            alignItems={"center"}
            px={5}
            justifyContent={"space-evenly"}
          >
            <Flex
              flexDir={"column"}
              textAlign={"center"}
              w={"220px"}
              h={"150px"}
              justifyContent={"center"}
              gap={5}
            >
              <Heading as={"h4"} fontSize={"20px"}>
                All Rounds
              </Heading>
              <Flex gap={1} alignItems={"center"} justifyContent={"center"} flexWrap={"wrap"}>
                <FiInbox size={"20px"} />
                <Text textDecor={"line-through"}>Coming soon</Text>
              </Flex>
            </Flex>
            <Divider width={"0px"} h={"150px"} border={"1px solid #ccc"} />
            <Flex
              flexDir={"column"}
              textAlign={"center"}
              w={"220px"}
              h={"150px"}
              justifyContent={"center"}
              gap={5}
            >
              <Heading as={"h4"} fontSize={"20px"}>
                External Post
              </Heading>
              <Flex gap={1} alignItems={"center"} justifyContent={"center"} flexWrap={"wrap"}>
                <FiActivity size={"20px"} />
                {/* <Link to='../external_post'>Monitor External Post</Link> */}
                <Link to={"./external_post"}>Track your post</Link>
              </Flex>
            </Flex>
            {jobDetail?.active === 1 ? (
              <>
                <Divider width={"0px"} h={"150px"} border={"1px solid #ccc"} />
                <Flex
                  flexDir={"column"}
                  textAlign={"center"}
                  w={"220px"}
                  h={"150px"}
                  justifyContent={"center"}
                  gap={5}
                >
                  <Heading as={"h4"} fontSize={"20px"}>
                    Applicants
                  </Heading>
                  <Flex gap={1} alignItems={"center"} justifyContent={"center"} flexWrap={"wrap"}>
                    <FiUsers size={"20px"} />
                    <Link to={`../${jobDetail?.id}/applicants`} state={{pastLocation: `${location.pathname}`}} >
                      See All Applicants
                    </Link>
                  </Flex>
                </Flex>
              </>
            ) : null}
          </Flex>
        </Flex>
      </Box>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader className="thirdColor">Warning</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{errorText}.</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                alertFunc ? handleConfirmReopen() : handleConfirmClose();
              }}
              ml={3}
            >
              {btnText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Container>
  );
}

export default JobDetails;
