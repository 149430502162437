import {
  Flex,Box,FormControl,FormLabel,Input,FormErrorMessage,Button, Stack, CircularProgress
} from "@chakra-ui/react";

function AccountLinking({companyKeyError,companyKey,setCompanyKey,role,roleError,setRole,handleAccountLinking, isChecking}) {
  return (
    <Flex flexDir={"column"} gap={5} flexWrap={"wrap"} justifyContent={"center"}>
                <Box>
                  <FormControl id="companyName" isRequired isInvalid={companyKeyError}>
                    <FormLabel>Company Key</FormLabel>
                    <Input
                      type="text"
                      placeholder="Company Key"
                      value={companyKey}
                      onChange={(e) => setCompanyKey(e.target.value)}
                    />
                    <FormErrorMessage>{companyKeyError}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="role" isRequired isInvalid={roleError}>
                    <FormLabel>Role</FormLabel>
                    <Input
                      type="text"
                      placeholder="Your Role Key"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    />
                    <FormErrorMessage>{roleError}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Stack spacing={10} pt={2}>
                <Button loadingText="Submitting" size="lg" className="thirdBackground"
                  bg={"blue.400"} color={"white"} _hover={{bg: "blue.500"}}
                  onClick={()=>handleAccountLinking()}>
                  {isChecking?
                  <CircularProgress isIndeterminate size={10} color="#000"/>
                  :
                  "Join my team"}
                </Button>
              </Stack>
              </Flex>
  )
}

export default AccountLinking