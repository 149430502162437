import React, { useEffect, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import {
  Box,
  Flex,
  Heading,
  Textarea,
  Input,
  CloseButton,
  Grid,
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";

import { FiEdit2 } from "react-icons/fi";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";

function NoteBook(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cards, setCards] = useState([]);

  const [opacityInside, setOpacityInside] = useState("50%");
  const [displayIt, setDisplayIt] = useState("none");
  const [insideText, setInsideText] = useState("Take notes here...");
  const [insideTitle, setInsideTitle] = useState("Title...");
  const [noteTitle, setNoteTitle] = useState(``);
  const [noteText, setNoteText] = useState(``);
  const [notes, setNotes] = useState([]);
  const [newTitle, setNewTitle] = useState(``);
  const [newText, setNewText] = useState(``);
  const [editingNote, setEditingNote] = useState(null);
  const { userInfo, apiURL } = UserAuth();
  const me = userInfo?.id;
  useEffect(() => {
    if (props.isIframe) {
      axios
        .get(`${apiURL}/applicants-notes?appId=${props.info.applicant}`, {withCredentials:true})
        .then((response) => {
          setNotes(
            response.data.map((note) => ({
              id: note.id,
              title: note.subject,
              notes: note.note,
              authorId: note.authorId,
            }))
          );
        })
        .catch((error) => {});
    } else {
      axios
        .get(`${apiURL}/notes?userId=${me}`, {withCredentials:true})
        .then((response) => {
          setNotes(
            response.data.map((note) => ({
              id: note.id,
              title: note.title,
              notes: note.notes,
            }))
          );
        })
        .catch((error) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);
  const editnote = (currentNote) => {
    onOpen();
    modalChange(currentNote);
  };

  const modalChange = (currentNote) => {
    setNewTitle(`${notes[currentNote].title}`);
    setNewText(`${notes[currentNote].notes}`);
    setEditingNote(currentNote);
  };

  // edit my notes
  const onNoteEditSave = () => {
    onClose();
    setNotes((prevNotes) => [
      ...prevNotes.slice(0, editingNote),
      {
        id: notes[editingNote].id,
        title: isEmpty(newTitle) ? notes[editingNote].title : `${newTitle}`,
        notes: isEmpty(newText) ? notes[editingNote].notes : `${newText}`,
      },
      ...prevNotes.slice(editingNote + 1),
    ]);
    const noteId = notes[editingNote].id; // replace with the ID of the note you want to update
    const updatedNote = {
      title: isEmpty(newTitle) ? notes[editingNote].title : `${newTitle}`, // replace with the new title
      notes: isEmpty(newText) ? notes[editingNote].notes : `${newText}`, // replace with the new note
    };
    axios
      .patch(`${apiURL}/notes/${noteId}`, updatedNote, {withCredentials:true})
      .then((response) => {})
      .catch((error) => {});
    setNewTitle(``);
    setNewText(``);
  };

  // edit applicant notes
  const onANoteEditSave = () => {
    onClose();
    setNotes((prevNotes) => [
      ...prevNotes.slice(0, editingNote),
      {
        id: notes[editingNote].id,
        title: isEmpty(newTitle) ? notes[editingNote].title : `${newTitle}`,
        notes: isEmpty(newText) ? notes[editingNote].notes : `${newText}`,
        authorId: notes[editingNote].authorId,
      },
      ...prevNotes.slice(editingNote + 1),
    ]);
    const noteId = notes[editingNote].id; // replace with the ID of the note you want to update
    const updatedNote = {
      subject: isEmpty(newTitle) ? notes[editingNote].title : `${newTitle}`, // replace with the new title
      note: isEmpty(newText) ? notes[editingNote].notes : `${newText}`, // replace with the new note
    };
    axios
      .patch(`${apiURL}/applicants-notes/${noteId}`, updatedNote, {withCredentials:true})
      .then((response) => {})
      .catch((error) => {});
    setNewTitle(``);
    setNewText(``);
  };

  // delete my notes
  const onNoteEditDelete = () => {
    setNotes((prevNotes) => prevNotes.filter((note, i) => i !== editingNote));
    axios
      .delete(`${apiURL}/notes/${notes[editingNote].id}`, {withCredentials:true})
      .then((response) => {})
      .catch((error) => {});
    onClose();
  };

  // delete applicant notes
  const onANoteEditDelete = () => {
    setNotes((prevNotes) => prevNotes.filter((note, i) => i !== editingNote));
    axios
      .delete(`${apiURL}/applicants-notes/${notes[editingNote].id}`, {withCredentials:true})
      .then((response) => {})
      .catch((error) => {});
    onClose();
  };

  useEffect(() => {
    const cardList = notes.map((note, i) => {
      console.log(note,i)
      return (
        <Card height={"max-content"} className="card" key={i} minW={"250px"} maxW={"250px"}>
          {!props.isIframe ||
          (props.isIframe && props?.info?.me === parseInt(note?.authorId)) ? (
            <Button
              margin={"auto"}
              w={50}
              h={50}
              borderRadius={"100%"}
              position={"absolute"}
              right={"-20px"}
              top={"-20px"}
              onClick={() => editnote(i)}
            >
              <FiEdit2 />
            </Button>
          ) : null}
          <CardHeader>
            <Text color={"#999"} fontSize={"14px"}>
              {props.isIframe
                ? userInfo.id === parseInt(note.authorId)
                  ? "from: me"
                  : " from: Team Member"
                : null}
            </Text>
            <Heading size="md">{note.title}</Heading>
          </CardHeader>
          <CardBody maxHeight={"400px"} height={"auto"} overflow={"auto"}>
            <Text>{`${notes[i].notes}`}</Text>
          </CardBody>
          <CardFooter></CardFooter>
        </Card>
      );
    });
    setCards(cardList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  const onTextAreaClick = () => {
    setDisplayIt("Flex");
    setInsideText(null);
    setOpacityInside("100%");
  };
  const onTextAreaBlur = () => {
    setDisplayIt("none");
    setInsideText("Take notes here...");
    setOpacityInside("50%");
    setInsideTitle("Title...");
    setNoteTitle(``);
    setNoteText(``);
  };

  // submit normal note
  const handleNoteSubmit = (e) => {
    e.preventDefault();
    if (noteText !== null && noteText !== ``) {
      axios
        .post(`${apiURL}/notes`, {
          title: `${noteTitle}`,
          notes: `${noteText}`,
          userId: me,
        }, {withCredentials:true})
        .then((response) => {
          setNotes((prevNotes) => [
            {
              id: response.data.id,
              title: `${noteTitle}`,
              notes: `${noteText}`,
            },
            ...prevNotes,
          ]);
        })
        .catch((error) => {});
    }

    onTextAreaBlur();
  };
  // submit applicants notes
  const handleANoteSubmit = (e) => {
    e.preventDefault();
    if (noteText !== null && noteText !== ``) {
      axios
        .post(`${apiURL}/applicants-notes`, {
          authorId: `${props.info.me}`,
          applicantId: `${props.info.applicant}`,
          shared: 0,
          subject: `${noteTitle}`,
          note: `${noteText}`,
        }, {withCredentials:true})
        .then((response) => {
          setNotes((prevNotes) => [
            {
              id: response.data.id,
              title: `${noteTitle}`,
              notes: `${noteText}`,
              authorId: `${userInfo.id}`,
            },
            ...prevNotes,
          ]);
        })
        .catch((error) => {});
    }

    onTextAreaBlur();
  };
  const handleTitleChange = (event) => {
    setNoteTitle(event.target.value);
  };
  const handleTextChange = (event) => {
    setNoteText(`${event.target.innerText}`);
  };
  if (props.isIframe) {
    return (
      <Container as={"section"} margin={0} width={"100%"} maxW={"100%"}>
        <Flex justifyContent={"center"}>
          <Grid
            gap={6}
            className="card"
            padding={"10px"}
            templateColumns={"2fr 0.1fr"}
            maxW={"auto"}
            onFocusCapture={onTextAreaClick}
          >
            <Flex padding={"0 5px"} flexDir={"column"} gap={6}>
              <Input
                fontWeight={"bold"}
                placeholder={insideTitle}
                border={"none"}
                display={displayIt}
                value={noteTitle}
                onChange={handleTitleChange}
              />
              <Box
                borderY={"0px solid"}
                borderRadius={10}
                boxShadow={"0 0px 5px #00000055"}
                p={"10px"}
                outline={"none"}
                width={{base:"100%", md:"400px"}}
                minHeight={"20px"}
                height={"-webkit-fit-content"}
                overflow={"hidden"}
                maxH={"500"}
                overflowY={"auto"}
                contentEditable
                opacity={opacityInside}
                onClick={onTextAreaClick}
                scrollBehavior={"smooth"}
                value={noteText}
                onInput={handleTextChange}
                suppressContentEditableWarning={true}
              >
                {insideText}
              </Box>

              {/* <Divider display={displayIt} width={'100%'} border={'none'} height={'1px'} boxShadow={'0 5px 2px #000'}/> */}
            </Flex>
            <CloseButton
              size={"lg"}
              stroke={"blackAlpha.900"}
              onClick={onTextAreaBlur}
              display={displayIt}
            />
            <Flex
              display={displayIt}
              justifyContent={"right"}
              gridColumnStart={1}
              gridColumnEnd={3}
            >
              <Button
                onClick={handleANoteSubmit}
                background={"blue.500"}
                color={"#fff"}
              >
                Add Note
              </Button>
            </Flex>
          </Grid>
        </Flex>
        <SimpleGrid
          spacing={7}
          templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
          mt={"50px"}
          p={5}
        >
          {cards}
        </SimpleGrid>
        <>
          <Modal
            isCentered
            onClose={onClose}
            isOpen={isOpen}
            motionPreset="slideInBottom"
            blockScrollOnMount={true}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Input
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                />
              </ModalHeader>
              <ModalBody>
                <Textarea
                  value={newText}
                  onChange={(e) => setNewText(e.target.value)}
                />
              </ModalBody>
              <ModalFooter display={"flex"} justifyContent={"space-evenly"}>
                <Button
                  variant="solid"
                  color={"white"}
                  background={"red.500"}
                  onClick={onANoteEditDelete}
                >
                  Delete
                </Button>
                <Button
                  variant="outline"
                  colorScheme="blackAlpha"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button colorScheme="blue" mr={3} onClick={onANoteEditSave}>
                  Save
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      </Container>
    );
  }

  return (
    <Container as={"section"} margin={0} width={"100%"} maxW={"100%"}>
      <Heading as={"h2"}>Notes</Heading>
      <Flex justifyContent={"center"} mt={10}>
        <Grid
          gap={6}
          className="card"
          padding={"10px"}
          templateColumns={"2fr 0.1fr"}
          maxW={"auto"}
          width={{base: "90dvw", md:"auto"}}
          onFocusCapture={onTextAreaClick}
        >
          <Flex padding={"0 5px"} flexDir={"column"} gap={6}>
            <Input
              fontWeight={"bold"}
              placeholder={insideTitle}
              border={"none"}
              display={displayIt}
              value={noteTitle}
              onChange={handleTitleChange}
            />
            <Box
              borderY={"0px solid"}
              borderRadius={10}
              boxShadow={"0 0px 5px #00000055"}
              p={"10px"}
              wordBreak={"break-all"}
              outline={"none"}
              width={{ md: "600px" }}
              maxWidth={{ xs: "300px", md: "600px" }}
              minHeight={"20px"}
              height={"-webkit-fit-content"}
              overflow={"hidden"}
              maxH={"500"}
              overflowY={"auto"}
              contentEditable
              opacity={opacityInside}
              onClick={onTextAreaClick}
              scrollBehavior={"smooth"}
              value={noteText}
              onInput={handleTextChange}
              suppressContentEditableWarning={true}
            >
              {insideText}
            </Box>

            {/* <Divider display={displayIt} width={'100%'} border={'none'} height={'1px'} boxShadow={'0 5px 2px #000'}/> */}
          </Flex>
          <CloseButton
            size={"lg"}
            stroke={"blackAlpha.900"}
            onClick={onTextAreaBlur}
            display={displayIt}
          />
          <Flex
            display={displayIt}
            justifyContent={"right"}
            gridColumnStart={1}
            gridColumnEnd={3}
          >
            <Button
              onClick={handleNoteSubmit}
              background={"blue.500"}
              color={"#fff"}
            >
              Add Note
            </Button>
          </Flex>
        </Grid>
      </Flex>
      <SimpleGrid
        spacing={7}
        templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
        mt={"50px"}
        mb={5}
        justifyItems={{ base: "center", md:"unset" }}
      >
        {cards}
      </SimpleGrid>
      <>
        <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          motionPreset="slideInBottom"
          blockScrollOnMount={true}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Input
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
              />
            </ModalHeader>
            <ModalBody>
              <Textarea
                value={newText}
                onChange={(e) => setNewText(e.target.value)}
              />
            </ModalBody>
            <ModalFooter display={"flex"} justifyContent={"space-evenly"}>
              <Button
                variant="solid"
                color={"white"}
                background={"red.500"}
                onClick={onNoteEditDelete}
              >
                Delete
              </Button>
              <Button
                variant="outline"
                colorScheme="blackAlpha"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button colorScheme="blue" mr={3} onClick={onNoteEditSave}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </Container>
  );
}

export default NoteBook;
