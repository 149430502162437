export const plan = {
    1: 'Basic',
    2: 'Freelancer',
    3: 'Startup',
    4: 'Business',
    5: 'Enterprise',
}
export const roles = {
    "aS39y22vD3": 'Owner',
    "eXjCFqipRq": 'Admin',
    "qsq2ZTXvqB": 'HR',
    "NIOVM6VVG0": 'Recruiter',
    "ya3G1sdOIh": 'Manager',
    "CFIa2OR9qI": 'User',
}
export const highRoles = [
    "aS39y22vD3",
    "eXjCFqipRq",
    "qsq2ZTXvqB"
]

export const companySize = {
    1: "Solo",
    2: "Small",
    3: "Medium",
    4: "Enterprise",
}