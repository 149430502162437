import { Box, Container, Flex, Text, Heading, Divider, 
    Tabs, TabList, TabPanels, Tab, TabPanel, Avatar
} from '@chakra-ui/react'
import React from 'react'
import { UserAuth } from '../../context/AuthContext'
import UserProfile from './UserProfile'
import CompanyProfile from './CompanyProfile'
import {  } from '@chakra-ui/react'

function Profile() {
    const {userInfo} = UserAuth()
    
  return (
    <Container as={'section'} margin={0} width={'100%'} maxW={'100%'}>
        <Heading as={'h2'} fontSize={{sm:'25px', md:'32px'}}>My Board</Heading>
        <Flex className='card' w={{md:'400px'}} minH={'300px'} flexDir={'column'} justifyContent={'center'} my={10} py={5}>
            <Flex justifyContent={'center'} alignItems={'center'}>
                <Box
                    // border={'3px solid #000'}
                    boxShadow={'0px 0px 5px #00000099'}
                    h={'150px'}
                    w={'150px'}
                    borderRadius={'100%'}
                    >
                    <Avatar 
                    size={'md'} 
                    height={'100%'}
                    width={'100%'}
                    objectFit={'cover'}
                    />
                </Box>
            </Flex>
            <Box textAlign={'center'} fontSize={'20px'} fontWeight={'bold'}>
                <Text>{userInfo?.fname} {userInfo?.mname} {userInfo?.lname}</Text>
            </Box>

        </Flex>
        <Tabs variant='soft-rounded' colorScheme='green' mt={10}>
            <TabList justifyContent={'center'}>
            <Tab>Profile</Tab>
            {userInfo?.role === 'eXjCFqipRq'||userInfo?.role === 'aS39y22vD3'  ?<Tab>Company Info</Tab>:null}
            </TabList>
            <Divider my={5} border={'1px solid #000'}/>
            <TabPanels>
                <TabPanel>
                    <UserProfile />
                </TabPanel>

                {userInfo?.role === 'eXjCFqipRq'||userInfo?.role === 'aS39y22vD3'  ?
                <TabPanel>
                    <CompanyProfile />
                </TabPanel>
                : null}
            </TabPanels>
        </Tabs>
    </Container>
  )
}

export default Profile