import React, { useEffect } from "react";
import {
  Container,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  Image,
  Text,
  FormErrorMessage,
  AlertDialog,
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import { isNameValid } from "../../functions/verificationsRegex";
import { UserAuth } from "../../context/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import OneButtonDialog from "../Dialog/OneButtonDialog";

function ProfileCreation() {
  
  const {logout, userInfo, apiURL} = UserAuth()
  const {onOpen, onClose, isOpen} = useDisclosure()
  const cancelRef = useRef(null)
  const [fName, setFName] = useState("");
  const [mName, setMName] = useState("");
  const [lName, setLName] = useState("");
  const [fNameError, setFNameError] = useState("");
  const [mNameError, setMNameError] = useState("");
  const [lNameError, setLNameError] = useState("");
  const [errorText, setErrorText] = useState("")
  const navigate = useNavigate()



  const email = sessionStorage.getItem("signupEmail")
  const uid = sessionStorage.getItem("signupUid")

  
  useEffect(()=>{
    if(userInfo && userInfo.company && userInfo.role){
      navigate('/activate')
    }

    if(!email || !uid){
      setErrorText(()=>"Something went wrong please restart the process. If this issue persist contact our support team")
      onOpen()
    }else{
         axios.get(`${apiURL}/users/findOne?email=${email}`).then((res)=>{
          if(res.data !== null){
            if(res.data.company === null || res.data.role === null){
              navigate('/setaccount', {state:{userId:res.data.id, email:email}})
            }else{
              navigate('/activate')
            }
          }
        })
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const closePopUp = () =>{
    onClose()
    logout()
  }
  const handleSubmit = () => {
    let verified = true;
    setFNameError();
    setMNameError();
    setLNameError();

    if (!fName || !isNameValid(fName)) {
      verified = false;
      setFNameError("Provide a correct first name");
    }
    if (mName.length > 0 && !isNameValid(mName)) {
      verified = false;
      setMNameError("Provide a correct middle name");
    }
    if (!lName || !isNameValid(lName)) {
      verified = false;
      setLNameError("Provide a correct last name");
    }

    if (verified) {
      async function fetchData() {
        try {
          await axios.post(`${apiURL}/users`,{
            "fname": fName,
            "mname": mName,
            "lname": lName,
            "email": email,
            "uid": uid,
            "active": false,
            "verified": false,
          }).then((res)=>{
            navigate('/setaccount', {state:{userId:res.data.id}})
          })
        } catch (error) {}
      }
      fetchData();
    }
  };
  return (
    <Container as={"section"} margin={0} width={"100%"} maxW={"100%"}>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
        flexDir={"column"}
        rowGap={5}
      >
        <Stack align={"center"}>
          <Image
            src="https://uploads-ssl.webflow.com/64f372bf5cb2cbb8370c00fd/6500f8e0204206c9e8e4d598_minilogo.png"
            height={50}
          />
          <Heading fontSize={"4xl"} textAlign={"center"}>
            Complete your profile
          </Heading>
        </Stack>
        <Stack spacing={8} mx={"auto"} maxW={"xl"}>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
            minW={{ base: "90dvw", md: "400px" }}
          >
            <Stack spacing={4}>
              <Flex
                flexDir={"column"}
                gap={5}
                flexWrap={"wrap"}
                justifyContent={"center"}
              >
                <Box>
                  <FormControl id="firstName" isRequired isInvalid={fNameError}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="text"
                      placeholder="First name"
                      value={fName}
                      onChange={(e) => setFName(e.target.value)}
                    />
                    <FormErrorMessage>{fNameError}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="middleName" isInvalid={mNameError}>
                    <FormLabel>Middle Name</FormLabel>
                    <Input
                      type="text"
                      placeholder="Middle name"
                      value={mName}
                      onChange={(e) => setMName(e.target.value)}
                    />
                    <FormErrorMessage>{mNameError}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="lastName" isRequired isInvalid={lNameError}>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="text"
                      placeholder="Last name"
                      value={lName}
                      onChange={(e) => setLName(e.target.value)}
                    />
                    <FormErrorMessage>{lNameError}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Flex>
              <Stack spacing={10} pt={2}>
                <Button
                  loadingText="Submitting"
                  size="lg"
                  className="thirdBackground"
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  onClick={() => handleSubmit()}
                >
                  Create my profile
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      <Flex gap={2} flexDir={"row"} flexWrap={"wrap"} justifyContent={"center"}>
          <Text>Back to the </Text>
          <Button onClick={()=> logout()} variant={'link'} className='thirdColor' textDecorationLine={"underline"}>main page</Button>
        </Flex>
      </Flex>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        onCloseComplete={closePopUp}
      >
        <OneButtonDialog btnText={"Try Again"} mainText={errorText} func1={closePopUp} />
      </AlertDialog>
    </Container>
  );
}

export default ProfileCreation;
