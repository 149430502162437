import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Container,
  Text,
  Divider,
} from "@chakra-ui/layout";
import {
  Progress,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  ButtonGroup,
  Button,
} from "@chakra-ui/react";
import axios from "axios";
import { toInteger } from "lodash";
import { UserAuth } from "../../context/AuthContext";

function AppDetails(props) {
  const [profile] = useState(props.info);
  const {apiURL} = UserAuth()

  const customScrollbarStyles = {
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#008099",
      borderRadius: "5px",
    },
  };

  const res = props.info.json;
  const pass = async () => {
    await axios
      .patch(`${apiURL}/applicant_preScreen/${profile.id}`, {
        preScreen: true,
      }, {withCredentials:true})
      .then((res) => {
        props.setPs(true);
        props.jobFit();
      });
  };

  const foundMCount =
    Object.keys(res).length !== 0 ? res.found?.must.length : 0;
  const foundICount =
    Object.keys(res).length !== 0 ? res.found?.important.length : 0;
  const foundNCount =
    Object.keys(res).length !== 0 ? res.found?.nice.length : 0;

  const missingCount =
    Object.keys(res).length !== 0
      ? res.missing?.must.length + res.missing?.important.length
      : 0;
  const missingNCount =
    Object.keys(res).length !== 0 ? res.missing?.nice.length : 0;

  const mayCount =
    Object.keys(res).length !== 0 ? res.found?.maybe?.length : null;
  const maybeSkills = res.found?.maybe?.length !== 0 ? res.found?.maybe : [];

  const total =
    props?.jobJson.important.length +
    props?.jobJson.must.length +
    props?.jobJson.nice.length;
  // const totals =
  //   props?.jobJson.important.length +
  //   props?.jobJson.must.length
  // const totalscore = foundCount + missingCount;
  const totalN = foundNCount + missingNCount;
  const maybeSkillsPerc =
    mayCount !== null ? toInteger((mayCount * 100) / total) : 0;
  const foundMCountPerc = toInteger(
    (foundMCount * 100) / props?.jobJson.must.length
  );
  const foundICountPerc = toInteger(
    (foundICount * 100) / props?.jobJson.important.length
  );
  const foundNCountPerc = toInteger((foundNCount * 100) / totalN);
  const missingCountPerc = toInteger((missingCount * 100) / total);
  const missingNCountPerc = toInteger((missingNCount * 100) / totalN);
  // const score = toInteger(((foundCountPerc)+foundNCountPerc)+(mayCount!==null ? maybeSkillsPerc/1.5 : 0))

  const mafound = Object.keys(res).length !== 0 ? res.found : {};
  const mifound = Object.keys(res).length !== 0 ? res.missing : {};

  const buildSkills = (skills) => {
    return Object.keys(skills).map((skillCategory) => {
      return (
        <Flex flexDir={"row"} flexWrap={"wrap"} gap={2} key={skillCategory}>
          {skills[skillCategory].map((skill) => {
            return (
              <Text className="skillBubble" key={skill}>
                {skill}
              </Text>
            );
          })}
        </Flex>
      );
    });
  };

  if (Object.keys(res).length > 0) {
    return (
      <Container as={"section"} margin={0} width={"100%"} maxW={"100%"}>
        <Flex
          width="95%"
          height="fit-content"
          flexDir="column"
          gap={10}
          mt={10}
        >
          <Flex mt={10} flexWrap={"wrap"} justifyContent={"center"} gap={10}>
            <Box className="card" w={"500px"} h={"300px"} overflowY={"scroll"} p={5} __css={customScrollbarStyles}>
              <Heading as={"h4"} fontSize={"20px"}>
                {" "}
                Searching for
              </Heading>
              <Box className="skillContainer">{buildSkills(props.jobJson)}</Box>
            </Box>
            <Flex
              flexDir={"column"}
              className="card"
              w={"800px"}
              minH={"300px"}
              p={5}
            >
              <Heading as={"h4"} fontSize={"20px"}>
                {" "}
                Candidate Skills
              </Heading>
              <Text>
                The Matched and missing calculation do not include nice to have
                skills
              </Text>
              <Flex
                className="skill-group"
                flexDir={"column"}
                width={"80%"}
                my={5}
              >
                <Text fontWeight={"bold"}>Must Have Skills Found</Text>
                <Progress
                  backgroundColor={"#ddd"}
                  size="md"
                  width={"100%"}
                  value={foundMCountPerc}
                />
                <span className="valueSpan">{foundMCountPerc}%</span>
              </Flex>
              <Flex
                className="skill-group"
                flexDir={"column"}
                width={"80%"}
                my={5}
              >
                <Text fontWeight={"bold"}>Important Skills Found</Text>
                <Progress
                  backgroundColor={"#ddd"}
                  size="md"
                  width={"100%"}
                  value={foundICountPerc}
                />
                <span className="valueSpan">{foundICountPerc}%</span>
              </Flex>
              <Flex
                className="skill-group"
                flexDir={"column"}
                width={"80%"}
                my={5}
              >
                <Text fontWeight={"bold"}>Missing Skills</Text>
                <Progress
                  colorScheme="red"
                  backgroundColor={"#ddd"}
                  size="md"
                  width={"100%"}
                  value={missingCountPerc}
                />
                <span className="valueSpan">{missingCountPerc}%</span>
              </Flex>
              <Flex
                className="skill-group"
                flexDir={"column"}
                width={"80%"}
                my={5}
              >
                <Text fontWeight={"bold"}>Need Verification</Text>
                <Progress
                  colorScheme="orange"
                  backgroundColor={"#ddd"}
                  size="md"
                  width={"100%"}
                  value={maybeSkillsPerc}
                />
                <span className="valueSpan">{maybeSkillsPerc}%</span>
              </Flex>
              <Flex
                gap={5}
                flexWrap={"wrap"}
                border={"1px solid #000"}
                borderRadius={10}
                position={"relative"}
                px={3}
              >
                <Flex
                  className="skill-group"
                  flexDir={"column"}
                  width={"40%"}
                  my={5}
                >
                  <Text fontWeight={"bold"}>Found Nice To Have</Text>
                  <Progress
                    colorScheme="green"
                    backgroundColor={"#ddd"}
                    size="md"
                    width={"100%"}
                    value={foundNCountPerc}
                  />
                  <span className="valueSpan">{foundNCountPerc}%</span>
                </Flex>
                <Flex
                  className="skill-group"
                  flexDir={"column"}
                  width={"40%"}
                  my={5}
                >
                  <Text fontWeight={"bold"}>Missing nice to have Skills</Text>
                  <Progress
                    colorScheme="red"
                    backgroundColor={"#ddd"}
                    size="md"
                    width={"100%"}
                    value={missingNCountPerc}
                  />
                  <span className="valueSpan">{missingNCountPerc}%</span>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Box className="card" minH={"300px"} p={5} mt={5} mb={10}>
            <Tabs variant="soft-rounded" colorScheme="green">
              <TabList justifyContent={"space-evenly"}>
                <Tab>Matched</Tab>
                <Tab>Missing</Tab>
                <Tab>Need to verify</Tab>
              </TabList>
              <Divider opacity={0.5} border={"1px"} my={2} color={"#000"} />
              <TabPanels>
                <TabPanel className="matchedContainer">
                  <Flex gap={5} alignItems={"center"}>
                    <Text textAlign={"center"}>Must Have</Text>
                    <Box className="skillContainer">
                      {mafound.must.map((skill) => {
                        return (
                          <Text
                            key={skill}
                            className="skillBubble skillBubbleMatched mustBubble"
                          >
                            {skill}
                          </Text>
                        );
                      })}
                    </Box>
                  </Flex>
                  <Flex gap={5} alignItems={"center"}>
                    <Text textAlign={"center"}>Important</Text>
                    <Box className="skillContainer">
                      {mafound.important.map((skill) => {
                        return (
                          <Text
                            key={skill}
                            className="skillBubble skillBubbleMatched importantBubble"
                          >
                            {skill}
                          </Text>
                        );
                      })}
                    </Box>
                  </Flex>
                  <Flex gap={5} alignItems={"center"}>
                    <Text textAlign={"center"}>Nice to Have</Text>
                    <Box className="skillContainer">
                      {mafound.nice.map((skill) => {
                        return (
                          <Text
                            key={skill}
                            className="skillBubble skillBubbleMatched niceBubble"
                          >
                            {skill}
                          </Text>
                        );
                      })}
                    </Box>
                  </Flex>
                </TabPanel>
                <TabPanel className="missingContainer">
                  <Flex gap={5} alignItems={"center"}>
                    <Text textAlign={"center"}>Must Have</Text>
                    <Box className="skillContainer">
                      {mifound.must.map((skill) => {
                        return (
                          <Text
                            key={skill}
                            className="skillBubble skillBubbleMissing "
                          >
                            {skill}
                          </Text>
                        );
                      })}
                    </Box>
                  </Flex>
                  <Flex gap={5} alignItems={"center"}>
                    <Text textAlign={"center"}>Important</Text>
                    <Box className="skillContainer">
                      {mifound.important.map((skill) => {
                        return (
                          <Text
                            key={skill}
                            className="skillBubble skillBubbleMissing importantBubble"
                          >
                            {skill}
                          </Text>
                        );
                      })}
                    </Box>
                  </Flex>
                  <Flex gap={5} alignItems={"center"}>
                    <Text textAlign={"center"}>Nice To Have</Text>
                    <Box className="skillContainer">
                      {mifound.nice.map((skill) => {
                        return (
                          <Text
                            key={skill}
                            className="skillBubble skillBubbleMissing niceBubble"
                          >
                            {skill}
                          </Text>
                        );
                      })}
                    </Box>
                  </Flex>
                </TabPanel>
                <TabPanel className="missingContainer">
                  <Flex gap={5} alignItems={"center"}>
                    <Box className="skillContainer">
                      {maybeSkills?.map((skill) => {
                        return (
                          <Flex
                            gap={2}
                            key={skill}
                            className="skillBubble skillBubbleMaybe"
                          >
                            <Text>{skill[0]}</Text>
                            <Divider orientation="vertical" />
                            <Text>{skill[1]}</Text>
                          </Flex>
                        );
                      })}
                    </Box>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>

        {props.info.preScreen || props.info.status >= 10 ? null : (
          <Box>
            <Heading as={"h3"} fontSize={24}>
              Decision
            </Heading>
            <Divider opacity={0.5} border={"1px"} my={2} color={"#000"} />
            <ButtonGroup
              display={"flex"}
              justifyContent={"center"}
              gap={20}
              flexWrap={"wrap"}
              my={5}
            >
              <Button colorScheme="red" onClick={() => props.disqualify()}>
                Disqualify
              </Button>
              <Button colorScheme="twitter" onClick={() => pass()}>
                Start Screening
              </Button>
            </ButtonGroup>
          </Box>
        )}
      </Container>
    );
  } else {
    return (
      <Container as={"section"} margin={0} width={"100%"} maxW={"100%"}>
        <Box>
          <Heading as={"h3"} fontSize={24}>
            Decision
          </Heading>
          <Divider opacity={0.5} border={"1px"} my={2} color={"#000"} />
          <ButtonGroup
            display={"flex"}
            justifyContent={"center"}
            gap={20}
            flexWrap={"wrap"}
            my={5}
          >
            <Button colorScheme="red" onClick={() => props.disqualify()}>
              Disqualify
            </Button>
            <Button colorScheme="twitter" onClick={() => pass()}>
              Start Screening
            </Button>
          </ButtonGroup>
        </Box>
      </Container>
    );
  }
}

export default AppDetails;
