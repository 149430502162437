import React, { useEffect, useState, useRef } from 'react'
import { UserAuth } from '../../context/AuthContext'
import axios from 'axios'
import { AlertDialog, Box, Button, Container, Flex, Image, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import OneButtonDialog from '../Dialog/OneButtonDialog'
import { Link, useNavigate } from 'react-router-dom'
import { FiUser, FiPlusCircle, FiAlertCircle  } from "react-icons/fi";

function Activate() {
    const {apiURL, userInfo, logout, setUserInfo} = UserAuth()
    const navigate = useNavigate()
    const [errorText, setErrorText] = useState()
    const {onOpen, isOpen, onClose} = useDisclosure()
    const email = localStorage.getItem("userEmail")
    const plan = localStorage.getItem('plan')
    const cancelRef = useRef(null)
    useEffect(()=>{
        if(!userInfo && !email){
            setErrorText(()=>"Something went wrong please restart the process. If this issue persist contact our support team")
            onOpen()
        }
        if(!userInfo && email){
            axios.get(`${apiURL}/users/findOne?email=${email}`).then((res)=>{
                if(!res || res.data === null ){
                    setErrorText(()=>"Something went wrong please restart the process. If this issue persist contact our support team")
                    onOpen()
                }else{
                    setUserInfo(res.data)
                }
                axios.get(`${apiURL}/companies/${res.data.company}`).then((res)=>{
                    if (plan !== res.data.plan){
                        localStorage.setItem("plan", res.data.plan)
                        navigate('/')
                        // logout()
                    }
                    // if(res.data)
                })
            })
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const closePopUp = () =>{
        onClose()
        logout()
    }
    
  return (
    <Container m={0} p={0}>
            <Flex justifyContent={'space-evenly'} alignItems={'center'} py={2} background={'#fff'} width={'100dvw'} height={'10dvh'} >
                <Box>
                <Tooltip label="Please purchase a license to have full access. You can contact us at: support@emplihire.com" aria-label='Please purchase a license to have full access. You can contact us at: support@emplihire.com'>
                    <Button variant={'unstyled'}><FiAlertCircle size={40}/></Button>
                </Tooltip>
                </Box>
                <Image src='https://uploads-ssl.webflow.com/64f372bf5cb2cbb8370c00fd/6500f8e0204206c9e8e4d598_minilogo.png' height={50}/>
                <Button onClick={()=>logout()}>Logout</Button>
            </Flex>
            <Flex justifyContent={'center'} alignItems={'center'} width={'100dvw'} height={'90dvh'} gap={10} flexWrap={'wrap'}>
                <Link to={'/profile'}>
                    <Button className='card' display={'flex'} flexDir={'column'} width={'270px'} height={'320px'} rowGap={5}>
                        <FiUser fontSize={'130'} color='#008099' />
                        <Text fontSize={'22px'}>View my Profile</Text>
                    </Button>
                </Link>
                <Link to={'/purchase'}>
                    <Button className='card' display={'flex'} flexDir={'column'} width={'270px'} height={'320px'} rowGap={5}>
                        <FiPlusCircle fontSize={'130'} color='#008099' />
                        <Text fontSize={'22px'}>Add a license</Text>
                    </Button>
                </Link>
            </Flex>
        <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered
            onCloseComplete={closePopUp}
        >
            <OneButtonDialog alertHeader={"Warning"} mainText={errorText} btnText={"Try again"} func1={onClose}/>
        </AlertDialog>
    </Container>
  )
}

export default Activate