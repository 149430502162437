/* eslint-disable eqeqeq */
import { Box, Container, Flex, Heading } from "@chakra-ui/layout";
import { CloseButton, CircularProgress } from "@chakra-ui/react";
import React from "react";
import AppProfile from "../../components/jobboard/AppProfile.jsx";
import { useState, useEffect } from "react";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext.js";
import { useParams } from "react-router";
import Applications from "../../components/jobboard/Applications.jsx";
import { highRoles } from "../../data/Constant.js";
import { useNavigate } from "react-router-dom/dist";

function ApplicationsView(
  view,
  setView,
  setCard,
  applicants,
  isloading,
  setIsLoading,
  lrounds,
  rounds,
  job
) {
  return (
    <Applications
      view={view}
      setView={setView}
      setCard={setCard}
      applicants={applicants}
      isloading={isloading}
      setIsLoading={setIsLoading}
      lrounds={lrounds}
      rounds={rounds}
      job={job}
    />
  );
}

function ApplicantView(
  view,
  setView,
  card,
  applicants,
  reload,
  setReload,
  skills,
  rounds,
  thisJob,
  avgScore
) {
  const tcolor = "#00A3C4";

  const changeViews = () => {
    if (reload) window.location.reload();
    else setView(0);
  };
  const reloadFunc = () => {
    setReload(true);
  };
  return (
    <Flex flexDir={{base:"column-reverse", md:"row"}} justifyContent="center" gap={5}>
      <Flex flexDir={"column"} width="100%" height="fit-content" alignContent={"center"}>
        <AppProfile
          jobJson={skills}
          info={applicants[card]}
          reload={reloadFunc}
          rounds={rounds}
          job={thisJob}
          avgScore={avgScore}
        />
      </Flex>
      <CloseButton
        border={`3px solid ${tcolor}`}
        borderRadius={50}
        size={"lg"}
        color={tcolor}
        onClick={() => changeViews()}
        alignSelf={{base:"end", md:"unset"}}
      />
    </Flex>
  );
}

function Application() {
  const [view, setView] = useState(0);
  const [card, setCard] = useState();
  const [skills, setSkills] = useState({});
  const [rounds, setRounds] = useState({});
  const [lrounds, setLRounds] = useState({});
  const [applicants, setApplicants] = useState([]);
  const { userInfo, apiURL } = UserAuth();
  const params = useParams();
  const [reload, setReload] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [thisJob, setThisJob] = useState({});
  const [valid, setValid] = useState(false);
  const [avgScore, setAvgScore] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);

    if (userInfo?.id) {
      getJob();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);
  const getJob = async () => {
    await axios
      .get(`${apiURL}/jobs/${params.job}/${userInfo?.company}`, {withCredentials:true})
      .then((res) => {
        const job = res.data;
        if (res.data === null) {
          navigate("/jobs");
          return;
        }

        if (
          highRoles.includes(userInfo?.role) ||
          job.hManager === userInfo.id ||
          job.recruiter === userInfo.id
        ) {
          fetchData(res);
        } else {
          navigate("/jobs");
        }
      })
      .catch(() => {
        navigate("/jobs");
      });
  };
  const fetchData = async (job) => {
    try {
      const response = await axios.get(
        `${apiURL}/applicants?jobId=${params.job}`, {withCredentials:true}
      );
      const fetchAvgScore = await axios.get(`${apiURL}/applicants/avgScore?`, {
        params: {
          jobId: params.job,
        },
        withCredentials: true
      });

      const data = response.data;
      setAvgScore(fetchAvgScore.data);
      setApplicants(data);
      setThisJob(job.data);
      setSkills(job.data.json);
      setRounds(job.data.rounds.round);
      const lastRound = job.data.rounds.round[job.data.rounds.round.length - 1];
      //   const lastRound = Object.keys(job.data.rounds[job.data.rounds.length-1])
      setLRounds(lastRound);
      setValid(true);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container as={"section"} margin={0} width={"100%"} maxW={"100%"}>
      <Heading as={"h2"}>Applicants</Heading>
      <Box my={10}>
        {valid ? (
          view === 0 ? (
            ApplicationsView(
              view,
              setView,
              setCard,
              applicants,
              isloading,
              setIsLoading,
              lrounds,
              rounds,
              thisJob
            )
          ) : (
            ApplicantView(
              view,
              setView,
              card,
              applicants,
              reload,
              setReload,
              skills,
              rounds,
              thisJob,
              avgScore
            )
          )
        ) : (
          <Flex width={"100%"} justifyContent={"center"}>
            <CircularProgress isIndeterminate color="#00A3C4" size={100} />
          </Flex>
        )}
      </Box>
    </Container>
  );
}

export default Application;
