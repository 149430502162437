import { Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate()
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(-1)
    }, 1000);
    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Flex width={"100dvw"} height={"100dvh"} justifyContent={"center"} alignItems={"center"}>
      <Text color={"#000"} fontSize={"xxx-large"} fontWeight={"bold"}>404 page not found...</Text>
    </Flex>
  );
}

export default NotFound;
