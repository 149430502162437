import React from 'react';
import { AgChartsReact } from 'ag-charts-react';
import { Flex } from '@chakra-ui/react';

function StatsCard(props) {
  const {statsData, statsNoData} = props
  const data = [...statsData]

  const options = {
    title: {
      text: 'Recent Hiring Stats',
    },
    data,
    series: [
      {
        type: 'bar',
        xKey: 'data',
        yKey: 'applicants',
        yName: 'Total Applicants',
        strokeWidth: 0,
      },
      {
        type: 'bar',
        xKey: 'data',
        yKey: 'interviewing',
        yName: 'Interviewing',
        strokeWidth: 0,
      },
      {
        type: 'bar',
        xKey: 'data',
        yKey: 'rejected',
        yName: 'Rejected',
        strokeWidth: 0,
      },
      {
        type: 'bar',
        xKey: 'data',
        yKey: 'hired',
        yName: 'Hired',
        strokeWidth: 0,
      },
    ],
  };
  return statsData?.length > 0 ? (
    <AgChartsReact className=".ag-theme-default" options={options} />
    ) : (
      <Flex justifyContent={'center'} align={'center'} height={'100%'}>
      {statsNoData}
      </Flex>
  )
}

export default StatsCard