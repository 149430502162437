import { Icon, Container, Flex, Text, Image, Grid, GridItem, IconButton } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom';
import {
    FiBook,
    FiCpu,
    FiGrid,
    FiBarChart2,
    FiFolder,
    FiUser,
    FiSettings,
    FiLogOut 
  } from 'react-icons/fi';
import { UserAuth } from '../../context/AuthContext';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
  } from '@chakra-ui/react'
  import { FiMenu } from "react-icons/fi";



function MobileMenu() {
    const {companyInfo, logout} = UserAuth()
    const LinkItems= [
      { name: 'Dashboard', icon: FiGrid, path: '/', isActive: true, isIncluded:[2,3] },
      { name: 'Screener', icon: FiCpu, path: '/screen', isActive: true, isIncluded:[1,2,3] },
      { name: 'Skill Check', icon: FiBarChart2, path: '/skill', isActive: true, isIncluded:[1,2,3] },
      { name: 'Notes', icon: FiBook, path: '/notes', isActive: true, isIncluded:[1,2,3]},
      { name: 'Job Board', icon: FiFolder, path: '/jobs', isActive: true, isIncluded:[2,3]},
    ];
    const global= [
        { name: 'Profile', icon: FiUser, path: '/profile'},
        { name: 'Settings', icon: FiSettings, path: '/'},
    ];
    const linkbuttons = (elements) =>  {
        return elements.map((item)=>{
            return(
                item.isIncluded.includes(companyInfo?.plan)?
                    <Link key={item.name} to={item.isActive?item.path:'/'} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
                        <MenuItem _hover={{ bg: '#008099', color: 'white', fontWeight: 'bold'}}>
                                <Flex align="center" px="4" py={'2'} my="4" borderRadius="lg" role="group"
                                    cursor="pointer" 
                                    gap={4}
                                    >
                                    <Icon fontSize="16" _groupHover={{ color: 'white',}} as={item.icon}/>
                                    <Text display={{md:'block'}}>{item.name}</Text>
                                </Flex>
                        </MenuItem>
                    </Link>
                :
                null
                
            )
        })
    }
    const globalButton = (elements) =>  {
        return elements.map((item)=>{
            return(
                    <Link key={item.name} to={item.path} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
                        <MenuItem _hover={{ bg: '#008099', color: 'white', fontWeight: 'bold'}}>
                                <Flex align="center" px="4" py={'2'} my="4" borderRadius="lg" role="group"
                                    cursor="pointer" 
                                    gap={4}
                                    >
                                    <Icon fontSize="16" _groupHover={{ color: 'white',}} as={item.icon}/>
                                    <Text display={{md:'block'}}>{item.name}</Text>
                                </Flex>
                        </MenuItem>
                    </Link>
            )
        })
    }
  return (
        <Container p={0} display={"flex"} justifyContent={"center"} background={'white'} height={'10dvh'} w={'100dvw'} maxW={"100dvw"}  className='sidebar'>
            <Grid templateColumns={"repeat(3, 1fr)"} justifyItems={'center'} alignItems={'center'} width={"100%"}>
                <GridItem  colStart={2}>
                    <Link to={'/'}>
                        <Image src='https://uploads-ssl.webflow.com/64f372bf5cb2cbb8370c00fd/6500f8e0204206c9e8e4d598_minilogo.png' height={50}/>
                    </Link>
                </GridItem>
                <GridItem justifySelf={"end"} maxH={"100%"} height={'100%'}>
                <Menu>
                    <MenuButton borderRadius={0} background={"none"} as={IconButton} icon={<FiMenu size={40} />} width={"100px"} height={'100%'} />
                    <MenuList>
                        {linkbuttons(LinkItems)}
                        {globalButton(global)}
                        <MenuItem _hover={{ bg: '#008099', color: 'white', fontWeight: 'bold'}} onClick={()=> logout()}>
                                <Flex align="center" px="4" py={'2'} my="4" borderRadius="lg" role="group"
                                    cursor="pointer" 
                                    gap={4}
                                    >
                                    <Icon fontSize="16" _groupHover={{ color: 'white',}} as={FiLogOut}/>
                                    <Text display={{md:'block'}}>Logout</Text>
                                </Flex>
                        </MenuItem>
                    </MenuList>
                </Menu>
                </GridItem>

            </Grid>
        </Container>
  )
}

export default MobileMenu