import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { FaUserTie } from "react-icons/fa";

function AppCard(props) {
  const { arr, i, group, changeView } = props;
  const colorScheme = {
    hired: {
      color: "#fff",
      background: "#38A169",
    },
    waiting: {
      color: "#000",
      background: "#ddd",
    },
    interviewing: {
      color: "#fff",
      background: "#008099",
    },
    rejected: {
      color: "#fff",
      background: "#E53E3E",
    },
    rescinded : {
      color: "#fff",
      background: "#545483",
    },
  };
  return (
    <Flex
      alignItems={"center"}
      minH={"50px"}
      cursor="pointer"
      onClick={() => {
        changeView(i)
      }}
      key={i}
      className="folder"
      background={ colorScheme[arr[i]?.status === 0 ? "waiting" : group].background }
      minWidth={{base:"250px", sm:"300px"}}
      borderRadius={5}
      padding="0"
      textAlign="center"
      fontSize="15px"
      py={2}
      boxShadow="0 2px 2px #000"
      color={ colorScheme[arr[i]?.status === 0 ? "waiting" : group].color }
      _active={{ boxShadow: "0 1px 1px #000" }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        padding={"5px"}
      >
          <FaUserTie color={ colorScheme[arr[i]?.status === 0 ? "waiting" : group].color } style={{ margin: "auto" }} size={20} width={"10%"} />
          <Text wordBreak="break-word" width={"60%"} maxWidth={"20ch"} whiteSpace={"nowrap"} textOverflow={"ellipsis"} overflow={"hidden"}>
            {arr[i]?.fname} {arr[i]?.mname} {arr[i]?.lname}
          </Text>
          <Text width={"30%"}>Score: {arr[i]?.status === 0 ? "--" : arr[i]?.score}</Text>
      </Flex>
    </Flex>
  );
}

export default AppCard;
